import { BsChevronExpand, BsCircle } from "react-icons/bs"
import Select, { components, SingleValue } from "react-select"
import config from "../lib/config"
import { useStore } from "../lib/store"
import { IconOption, SelectOpt, selectStyles } from "./Select"
import "./NetworkSwitcher.sass"

export const networkOptions: { label: string; value: string }[] = Object.keys(config.chains).map((key) => ({
  label: String(config.chains[key].name),
  value: String(key),
}))

const customSelectStyles = {
  ...selectStyles,

  control: (base: any) => ({
    ...base,
    border: "1px solid #efefef",
    borderRadius: 8,
    padding: 7,

    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
  }),
}

export function NetworkSwitcher() {
  const chain = useStore((state) => state.chain)
  const selected = networkOptions.find((opt: SelectOpt) => opt.value === chain)
  const onNetworkChange = (e: any) => {
    useStore.setState({ chain: e.value })
  }

  return (
    <div className="network-switcher">
      <Select
        key={networkOptions.length}
        className="react-select"
        options={networkOptions}
        isSearchable={false}
        components={{
          ValueContainer: (props) => {
            const val = props.getValue()
            const chain = config.chains[val[0].value]
            return (
              <components.ValueContainer {...props}>
                {chain.logo ? <img src={chain.logo} alt={chain.name} /> : <BsCircle size={24} />}
                {props.children}
              </components.ValueContainer>
            )
          },
          Option: (props) => {
            const val = props.getValue()
            const chain = config.chains[props.data.value]
            const icon = () =>
              chain.logo ? <img src={chain.logo} alt={chain.name} /> : <BsCircle size={24} />
            return <IconOption {...props} icon={icon} />
          },
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (
            <div className="drop-icon">
              <BsChevronExpand />
            </div>
          ),
        }}
        defaultValue={selected}
        onChange={onNetworkChange}
        styles={customSelectStyles}
      />
    </div>
  )
}
