import { Button } from "./Button"
import "./Modal.sass"
import clsx from "clsx"
import { IoIosClose } from "react-icons/io"
import BaseModal from "react-modal"

BaseModal.defaultStyles = {
  ...BaseModal.defaultStyles,
  content: {
    ...BaseModal.defaultStyles.content,
    overflow: "initial",
  },
}

const modalStyle = {
  content: {
    position: undefined,
    minHeight: 240,
    boxShadow: "0 0 12px rgba(0, 0, 0, .1)",
    padding: 0,
  },
  overlay: {
    zIndex: 3,
    background: "rgba(0, 0, 0, .4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

interface ModalProps {
  onRequestClose?: () => void
  isOpen: boolean
  title?: string
  footer?: any
  children?: any
  className?: any
}

export function Modal(props: ModalProps) {
  const { className, footer, ...rest } = props
  return (
    <BaseModal style={modalStyle} {...rest}>
      <div className={clsx("modal", className)}>
        <div className="header">
          <div>{props.title}</div>
          {props.onRequestClose ? (
            <Button className="close-button" onClick={props.onRequestClose}>
              <IoIosClose size={36} />
            </Button>
          ) : null}
        </div>
        <div className="content">{props.children}</div>
        {footer}
      </div>
    </BaseModal>
  )
}

export function ModalFooter(props: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="footer">{props.children}</div>
}
