import "./WorkspaceSettings.sass"
import { Text } from "./components/Text"
import { useAuth } from "./hooks/useAuth"
import { Header } from "./components/Header"
import { TextInput } from "./components/TextInput"
import { useEffect, useState } from "react"
import { Button } from "./components/Button"
import { useMutation, useQuery } from "@tanstack/react-query"
import { IUser, IWorkspace } from "./types"
import { AxiosError } from "axios"
import { fetcher } from "./lib/fetcher"
import { useStore } from "./lib/store"

export function WorkspaceSettings() {
  const auth = useAuth()

  const [name, setName] = useState("")

  useEffect(() => {
    setName(auth.workspace?.name || "")
  }, [auth.workspace?.name])

  const { refetch } = useQuery({
    queryFn: async () => fetcher("get", "/session/"),
    queryKey: ["session"],
    enabled: false,
    onSuccess: (authUser: IUser) => {
      useStore.setState({ authUser })
    },
  })

  const { isLoading, mutate } = useMutation<any, AxiosError, Partial<IWorkspace>>({
    mutationFn: (attrs: Partial<IWorkspace>) => fetcher("patch", `/workspaces/${auth.workspace?.id}`, attrs),
    onSuccess: () => refetch(),
  })

  return (
    <div className="page workspace-settings">
      <div className="pane">
        <Header>
          <Text subtitle>Manage your workspace</Text>
        </Header>
        <div className="data-content">
          <div className="row">
            <Text>Workspace Name</Text>
          </div>
          <div className="row spaced">
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />

            <Button
              variant="success"
              disabled={name === auth.workspace?.name}
              onClick={() => mutate({ name })}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
