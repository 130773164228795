import "./PendingTransactions.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import clsx from "clsx"
import { ethers } from "ethers"
import { useState } from "react"
import { BiCaretDown, BiCaretUp, BiRightArrowAlt } from "react-icons/bi"
import { IoIosClose } from "react-icons/io"
import { Link, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { CountdownSpinner } from "./components/CountdownSpinner"
import { Empty } from "./components/Empty"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import config from "./lib/config"
import { fetcher, retry } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { setTitle } from "./lib/utils"
import { IReviewableTransaction } from "./types"

export const usePendingTransactions = (params: Readonly<Partial<{ workspaceId: string }>>) => {
  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [params.workspaceId, "pending-transactions"],
    queryFn: async (): Promise<IReviewableTransaction[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/transactions/pending`),

    retry,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 3000,
    onSuccess: (data: IReviewableTransaction[]) => {
      if (data.length > 0) {
        setTitle(`[${data.length}] - Dashboard`)
        return
      }
      setTitle("Dashboard")
    },
  })
  return { data, isLoading, refetch }
}

export function PendingTransactions() {
  const params = useParams<{ workspaceId: string }>()
  const [expanded, setExpanded] = useState<boolean>(false)

  const chainName = useStore((s: Store) => s.chain)
  const chain = config.chains[chainName]

  const { data, isLoading, refetch } = usePendingTransactions(params)

  const {
    mutate: reviewTransactionMutation,
    isLoading: isLoadingConfirm,
    error: confirmError,
  } = useMutation<any, AxiosError, { id: string; approve: boolean }>({
    mutationFn: async (attrs: any) =>
      fetcher("post", `/workspaces/${params.workspaceId}/transactions/review`, attrs),
    onSuccess: () => refetch(),
    onError: console.error,
  })

  const prevewPendingTransaction = ({ transaction }: { transaction: IReviewableTransaction }) => {
    useStore.setState({ reviewTransaction: transaction })
  }

  const confirmPendingTransaction = (v: IReviewableTransaction, approve: boolean) => {
    if (approve && v.method === "eth_sign") {
      if (
        !window.confirm(
          "WARNING! This is a raw eth_sign transaction. We cannot decode the actual value or display the changes. Are you absolutely sure you trust the origin and want to approve this?"
        )
      ) {
        return reviewTransactionMutation({
          id: v.id,
          approve: false,
        })
      }
    }
    reviewTransactionMutation({
      id: v.id,
      approve,
    })
  }

  return (
    <div className={clsx("pending-transactions", { expanded })}>
      {expanded && (
        <>
          {isLoading && (
            <Empty>
              <Spinner />
            </Empty>
          )}
          {data.length === 0 && <Empty>Awaiting transactions</Empty>}
          <div className={clsx("transactions")}>
            {data.length > 0 && (
              <div className="transaction">
                <Text bold>Wallet</Text>
                <Text bold>Value</Text>
                <Text bold>Method</Text>
                <Text bold>To</Text>
                <Text bold>Expires</Text>
                <div className="buttons">
                  <Text bold>Action</Text>
                </div>
              </div>
            )}
            {data.map((v: IReviewableTransaction) => {
              return (
                <div className="transaction" key={v.id}>
                  <Text overflow bold>
                    <Link to={`wallets/${v.wallet.id}`}>{v.wallet.name}</Link>
                  </Text>
                  <Text>
                    {v.tx_data.Value ? ethers.formatEther(String(v.tx_data.Value)).toString() : "(none)"} eth{" "}
                  </Text>
                  <Text overflow title={v.method}>
                    {v.method}
                  </Text>
                  <Text overflow>
                    {v.tx_data.To ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${chain.blockExplorer}/address/${v.tx_data.To}`}
                      >
                        {v.tx_data.To}
                      </a>
                    ) : (
                      "(none)"
                    )}
                  </Text>
                  <div className="countdown">
                    <CountdownSpinner countdown={60} expires={new Date(v.expires)} />
                  </div>
                  <div className="buttons">
                    <Button
                      variant="error"
                      size="small"
                      disabled={isLoadingConfirm}
                      onClick={() => confirmPendingTransaction(v, false)}
                    >
                      Deny <IoIosClose size={22} />
                    </Button>
                    <Button
                      variant="success"
                      size="small"
                      disabled={isLoadingConfirm}
                      onClick={() => prevewPendingTransaction({ transaction: v })}
                    >
                      Review <BiRightArrowAlt size={22} />
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      <div className={clsx("bar", { alert: data.length > 0 })} onClick={() => setExpanded(true)}>
        <Text>Transaction Approval</Text>
        {data.length > 0 && <div className="tx-number">{data.length}</div>}
        <Button
          variant={data.length > 0 ? "warning" : undefined}
          onClick={(e) => {
            e.stopPropagation()
            setExpanded(!expanded)
          }}
        >
          {expanded ? "Hide" : "View"} {expanded ? <BiCaretDown /> : <BiCaretUp />}
        </Button>
      </div>
    </div>
  )
}
