import "./Billing.sass"
import { Link, Route, Routes, useParams } from "react-router-dom"
import { Header } from "./components/Header"
import { Text } from "./components/Text"
import { Card } from "./components/Card"
import { Modal, ModalFooter } from "./components/Modal"
import { FaCheckCircle } from "react-icons/fa"
import clsx from "clsx"
import { Button } from "./components/Button"
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import {
  StripeElementsOptions,
  StripePaymentElementOptions,
  StripePaymentElementChangeEvent,
  loadStripe,
} from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import config from "./lib/config"
import { useMutation, useQuery } from "@tanstack/react-query"
import { fetcher } from "./lib/fetcher"
import { Spinner } from "./components/Spinner"
import { Empty } from "./components/Empty"
import { BillingPlanName, IBillingStatus } from "./types"
import { TextInput } from "./components/TextInput"

const isEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

const stripePromise = loadStripe(config.stripePublicKey)

// 49 in usd is 4900 in stripe
const usdToStripeValue = (usd: number) => Math.round(usd * 100)

const plans: { [k in BillingPlanName]: { name: string; amount: number } } = {
  trial_01: {
    name: "Trial",
    amount: 0,
  },
  team_01: {
    name: "Team",
    amount: 49,
  },
  business_01: {
    name: "Business",
    amount: 249,
  },
  enterprise_01: {
    name: "Enterprise",
    amount: -1,
  },
}

export function Billing() {
  const params = useParams<{ workspaceId: string }>()

  const [selectedPlan, setSelectedPlan] = useState<BillingPlanName | null>(null)
  const [showStripeModal, setShowStripeModal] = useState(false)

  const {
    data: billingStatus,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: (): Promise<IBillingStatus> => fetcher("get", `workspaces/${params.workspaceId}/billing/status`),
    queryKey: [params.workspaceId, "billing", "status"],
    refetchInterval: 4000,
  })

  const currentPlan = (billingStatus?.billing_plan || "trial_01") as BillingPlanName

  const start = (plan: BillingPlanName) => {
    setShowStripeModal(true)
    setSelectedPlan(plan)
  }

  const cancel = () => {
    setShowStripeModal(false)
    setSelectedPlan(currentPlan || null)
  }

  const elementsOptions: StripeElementsOptions = {
    mode: "subscription",
    currency: "usd",
    amount: usdToStripeValue(plans[selectedPlan || currentPlan]?.amount),
  }

  return (
    <div className="page workspace-settings">
      <Routes>
        <Route
          index
          element={
            <>
              <div className="pane">
                <Header>
                  <Text subtitle>Manage your workspace plan</Text>
                </Header>

                <div className="content">
                  <div className="billing-cards">
                    {/*<Card className={clsx("billing-card")}>
              <div className="card-header">
                <Text size="large">Trial</Text>
              </div>
              <div className="card-header">
                <Text>Free with limitations</Text>
              </div>
              <div className="card-body">
                <div className="item">
                  <div className="name">
                    <FaCheckCircle /> 1 Owner
                  </div>
                </div>
                <div className="item">
                  <div className="name">
                    <FaCheckCircle /> 1 Wallet
                  </div>
                </div>
                <div className="item">
                  <div className="name">
                    <FaCheckCircle /> Default Admin approval Role
                  </div>
                </div>
                <div className="item">
                  <div className="name">
                    <FaCheckCircle /> Testnet RPC Access
                  </div>
                </div>
                <div className="item">
                  <div className="name">
                    <FaCheckCircle /> 1 Testnet Alert
                  </div>
                </div>
                <div className="item">
                  <div className="name">
                    <FaCheckCircle />1 Testnet API Key
                  </div>
                </div>
                <div className="item centered">
                  <Button round disabled variant="success">
                    {currentPlan === "trial_01" ? "Current Plan" : "Choose Plan"}
                  </Button>
                </div>
              </div>
            </Card>
            */}

                    <Card className={clsx("billing-card", { active: currentPlan === "team_01" })}>
                      <div className="card-header">
                        <Text size="large">Team</Text>
                      </div>
                      <Text className="row">Launch Pricing:</Text>
                      <div className="card-header">
                        $49/m for the first 5 members. $9/m per additional member.
                      </div>

                      <div className="card-body">
                        <Text size="large">Everything in Trial plus:</Text>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 5 Team Members
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 10 Wallets
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Alerts (delayed 60 seconds)
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Mainnet RPC Access
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Up to 5 Custom Roles / Permissions
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 1000 Transactions per month
                          </div>
                        </div>
                        <div className="item centered">
                          <Button
                            round
                            variant="success"
                            disabled={currentPlan === "team_01"}
                            onClick={() => start("team_01")}
                          >
                            {currentPlan === "team_01" ? "Current Plan" : "Select Plan"}
                          </Button>
                        </div>
                      </div>
                    </Card>

                    <Card className={clsx("billing-card", { active: currentPlan === "business_01" })}>
                      <div className="card-header">
                        <Text size="large">Business</Text>
                      </div>
                      <Text className="row">Launch Pricing:</Text>
                      <div className="card-header">$249/m</div>

                      <div className="card-body">
                        <Text size="large">Everything in Team plus:</Text>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 10 Team Members. $19/m per additional member.
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 50 Wallets
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Wallet Alerts
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 50 Custom Roles / Permissions
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 5,000 Transactions per month
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Full RPC Access
                          </div>
                        </div>
                        <div className="item centered">
                          <Button
                            round
                            variant="success"
                            disabled={currentPlan === "business_01"}
                            onClick={() => start("business_01")}
                          >
                            {currentPlan === "business_01" ? "Current Plan" : "Select Plan"}
                          </Button>
                        </div>
                      </div>
                    </Card>

                    <Card className={clsx("billing-card", { active: currentPlan === "enterprise_01" })}>
                      <div className="card-header">
                        <Text size="large">Enterprise / On-Prem</Text>
                      </div>
                      <div className="card-header">
                        <Text>Custom Pricing</Text>
                      </div>

                      <div className="card-body">
                        <Text size="large">Everything in Business plus:</Text>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Compliance Packages
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> On-Prem Deployment options
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> 99.99% Uptime SLA
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Unlimited Transactions
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Dedicated Support
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Compilance Packages (Coming Soon)
                          </div>
                        </div>
                        <div className="item">
                          <div className="name">
                            <FaCheckCircle /> Insurance Packages (Coming Soon)
                          </div>
                        </div>
                        <div className="item centered">
                          <Link to="mailto:contact@sybl.dev">
                            <Button round variant="success">
                              Chat with our team
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </div>

                  {currentPlan !== "trial_01" ? (
                    <>
                      <div className="content">
                        <Header>
                          <Text>View and manage your current subscription</Text>
                        </Header>
                      </div>
                      <div className="content">
                        <Link to={config.stripePortalUrl} target="_blank">
                          <Button variant="success">Manage Billing</Button>
                        </Link>
                      </div>
                      {billingStatus?.billing_email ? (
                        <div className="content">
                          <div className="row">
                            <Text subtitle>Billing Email</Text>
                          </div>
                          <div className="row">{billingStatus?.billing_email}</div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/finish"
          element={
            elementsOptions.amount ? (
              <Elements stripe={stripePromise} options={elementsOptions}>
                <Finish />
              </Elements>
            ) : (
              <Empty>
                <Spinner />
              </Empty>
            )
          }
        />
      </Routes>

      {elementsOptions.amount ? (
        <Elements options={elementsOptions} stripe={stripePromise}>
          {selectedPlan && billingStatus ? (
            <PaymentModal
              closeModal={cancel}
              isOpen={showStripeModal}
              selectedPlan={selectedPlan}
              currentPlan={currentPlan}
              billingStatus={billingStatus}
            />
          ) : null}
        </Elements>
      ) : null}
    </div>
  )
}

function Finish() {
  const stripe = useStripe()
  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret")

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setIsLoading(false)
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Subscription Complete")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  return (
    <div className="pane">
      <Header>
        <Text subtitle>Finalizing</Text>
      </Header>

      <div className="content">
        {isLoading ? (
          <Empty>
            <Spinner />
          </Empty>
        ) : (
          <Empty>
            {message}
            <div className="row">
              <Empty>
                <Button onClick={() => (window.location.href = "/")}>Go to Dashboard</Button>
              </Empty>
            </div>
          </Empty>
        )}
      </div>
    </div>
  )
}

function PaymentModal({
  closeModal,
  isOpen,
  selectedPlan,
  currentPlan,
  billingStatus,
}: {
  closeModal: () => void
  isOpen: boolean
  selectedPlan: BillingPlanName
  currentPlan: BillingPlanName
  billingStatus: IBillingStatus
}) {
  // stripe items
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState("")
  const [isInitializing, setIsInitializing] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [email, setEmail] = useState("")

  const params = useParams<{ workspaceId: string }>()

  const { isLoading: isLoadingIntent, mutate } = useMutation(
    async (): Promise<{ client_secret: string; type: string }> =>
      fetcher("post", `/workspaces/${params.workspaceId}/billing/intent`, { plan_id: selectedPlan, email }),
    {
      onSuccess: async ({ client_secret, type }) => {
        // handle upgrade
        if (type === "" && client_secret === "") {
          window.location.reload()
          return
        }
        if (!stripe || !elements) {
          setMessage("Error initializing payment")
          setIsLoading(false)
          return
        }
        const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment

        const { error } = await confirmIntent({
          elements,
          clientSecret: client_secret,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.href}/finish`,
          },
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message)
        } else {
          setMessage("An unexpected error occurred.")
        }

        setIsLoading(false)
      },
      onError: (error) => {
        setMessage(error?.message || String(error))
        setIsLoading(false)
      },
    }
  )

  const submit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)

    const { error: intentError } = await elements.submit()
    if (intentError) {
      setMessage(intentError?.message || String(intentError))
      setIsLoading(false)
      return
    }

    mutate()
  }

  const handleOnChange = (event: StripePaymentElementChangeEvent) => {
    setIsInitializing(false)
    setIsComplete(event.complete)
  }

  const canContinue =
    billingStatus.billing_status === "active"
      ? !isLoading
      : !isLoading && stripe && elements && isComplete && isEmail(email)

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "accordion",
  }

  const action = selectedPlan === "business_01" && currentPlan === "team_01" ? "Upgrade" : "Downgrade"

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} title="Setup Billing" className="modal-stripe">
      <>
        <div className="columns">
          <div className="column details">
            <Text className="name">{plans[selectedPlan].name}</Text>
            <Text className="price">${plans[selectedPlan].amount}/m</Text>
          </div>
          <div className="column">
            {billingStatus.billing_status === "active" ? (
              <div className="upgrade">
                <div className="row">
                  <h2>{action} your current plan</h2>
                </div>
                <div className="row">
                  <Button onClick={submit} disabled={!canContinue} variant="success">
                    {action}
                  </Button>
                </div>

                <div className="row">Your existing payment method will be used.</div>

                <div className="row">
                  If you need to update your payment method please click here:
                  <div className="row">
                    <Link to={config.stripePortalUrl} target="_blank">
                      <Button>Manage Billing</Button>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <PaymentElement
                  onChange={handleOnChange}
                  id="payment-element"
                  options={paymentElementOptions}
                />

                <div className="row">
                  <Text subtitle>billing email</Text>
                </div>
                <div className="row">
                  <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="billing@example.com"
                  />
                </div>

                {message && <div className="error">{message}</div>}

                {stripe && elements && !isInitializing && (
                  <ModalFooter>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={submit} disabled={!canContinue} variant="success">
                      Subscribe
                    </Button>
                  </ModalFooter>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}
