import { useParams } from "react-router-dom"
import { Store, useStore } from "../lib/store"
import { currentWorkspace, isAdmin, isOwner, isOwnerOrAdmin } from "../lib/roles"
import { IAuth } from "../types"

export const useAuth = (): IAuth => {
  const params = useParams<{ workspaceId: string }>()
  const authUser = useStore((s: Store) => s.authUser)
  const workspace = currentWorkspace(authUser, params)

  return {
    authUser,
    workspace,
    isOwner: isOwner(workspace?.role),
    isAdmin: isAdmin(workspace?.role),
    isOwnerOrAdmin: isOwnerOrAdmin(workspace?.role),
  }
}
