import "./Alerts.sass"
import { useQuery } from "@tanstack/react-query"
import { IoAddOutline } from "react-icons/io5"
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Header } from "./components/Header"
import { Text } from "./components/Text"
import { fetcher, retry } from "./lib/fetcher"
import { IWalletAlert } from "./types"
import { Empty } from "./components/Empty"
import { Alert, getVariant } from "./Alert"
import { AlertEditor } from "./components/AlertEditor"
import { BsChevronRight } from "react-icons/bs"
import { AiFillBell } from "react-icons/ai"
import { Tag } from "./components/Tag"
import { useAuth } from "./hooks/useAuth"

export function Alerts() {
  const params = useParams<{ workspaceId: string }>()
  const navigate = useNavigate()
  const auth = useAuth()

  const { data = [], isLoading } = useQuery({
    queryFn: (): Promise<IWalletAlert[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/wallets/alerts/`),
    retry,
    queryKey: [params.workspaceId, "wallets", "alerts"],
  })

  return (
    <div className="page alerts">
      <Routes>
        <Route
          index
          element={
            <>
              <div className="pane">
                <Header>
                  <Text subtitle>Receive email alerts for your wallets</Text>
                  {auth.isOwnerOrAdmin && (
                    <Button variant="success" round onClick={() => navigate("new")}>
                      Create Alert <IoAddOutline size={20} />
                    </Button>
                  )}
                </Header>
                <div className="rows">
                  <div className="row">
                    <Text subtitle>Name</Text>
                    <Text subtitle>Wallet</Text>
                    <Text subtitle>Notifications</Text>
                    <Text subtitle>Status</Text>
                    <div />
                  </div>
                  {data.map((v: IWalletAlert) => {
                    return (
                      <Link key={v.id} className="row" to={v.id}>
                        <Text bold className="name">
                          {v.name}
                        </Text>
                        <Text bold className="wallet">
                          {v.wallet.name}
                        </Text>
                        <Tag>{v.destinations.map((v) => v.email).join(", ")}</Tag>
                        <Text bold className="status" variant={getVariant(v.status)}>
                          {String(v.status)}
                        </Text>
                        <div>
                          <Button plain>
                            Details
                            <BsChevronRight />{" "}
                          </Button>
                        </div>
                      </Link>
                    )
                  })}
                  {!isLoading && data.length === 0 ? (
                    <Empty>
                      <div>No alerts yet</div>
                      {auth.isOwnerOrAdmin && (
                        <Button
                          round
                          variant="success"
                          style={{ marginTop: 16 }}
                          onClick={() => navigate("new")}
                        >
                          Create Alert <AiFillBell size={20} />
                        </Button>
                      )}
                    </Empty>
                  ) : null}
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/new"
          element={
            <div className="pane">
              <Header>
                <Text subtitle>Create Email Alert</Text>
              </Header>
              <AlertEditor />
            </div>
          }
        />
        <Route path="/:alertId" element={<Alert />} />
      </Routes>
    </div>
  )
}
