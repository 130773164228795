import "./Permissions.sass"
import { useQuery } from "@tanstack/react-query"
import { BsChevronRight, BsShieldFillCheck } from "react-icons/bs"
import { IoAddOutline } from "react-icons/io5"
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Header } from "./components/Header"
import { RoleEditor } from "./components/RoleEditor"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { Role } from "./Role"
import { IWalletRole } from "./types"
import { useAuth } from "./hooks/useAuth"
import { Spinner } from "./components/Spinner"
import { Tag } from "./components/Tag"
import { RoleEditorV2 } from "./components/RoleEditorV2"

function Edit() {
  const params = useParams<{ workspaceId: string; roleId: string }>()
  const { data, isLoading } = useQuery({
    queryFn: async (): Promise<IWalletRole> =>
      fetcher("get", `/workspaces/${params.workspaceId}/roles/${params.roleId}`),
    queryKey: [params.workspaceId, "roles", params.roleId],
  })
  return (
    <div className="pane">
      <Header>
        <Text subtitle>Edit Role</Text>
      </Header>
      {isLoading ? <Spinner /> : <RoleEditorV2 edit={data} />}
    </div>
  )
}

export function Permissions() {
  const params = useParams<{ workspaceId: string }>()
  const navigate = useNavigate()
  const auth = useAuth()

  const { data, isLoading } = useQuery({
    queryFn: async (): Promise<IWalletRole[]> => fetcher("get", `/workspaces/${params.workspaceId}/roles/`),
    queryKey: [params.workspaceId, "roles"],
  })

  return (
    <div className="page permissions">
      <Routes>
        <Route
          index
          element={
            <>
              <div className="pane">
                <Header>
                  <Text subtitle>Roles for wallet access</Text>

                  {auth.isOwnerOrAdmin && (
                    <Button round uppercase variant="success" onClick={() => navigate("new")}>
                      create role
                      <IoAddOutline size={20} />
                    </Button>
                  )}
                </Header>
                <div className="rows">
                  <div className="row">
                    <Text subtitle>Name</Text>
                  </div>
                  {data?.map((v: IWalletRole) => {
                    return (
                      <Link key={v.id} to={v.id} className="row">
                        <div className="name">
                          <Text bold>{v.name}</Text>
                          {v.official ? (
                            <Tag>
                              Default
                              <BsShieldFillCheck size={14} style={{ marginLeft: 8 }} />
                            </Tag>
                          ) : (
                            <div />
                          )}
                        </div>
                        <Button plain>
                          Details
                          <BsChevronRight />{" "}
                        </Button>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/new"
          element={
            <div className="pane">
              <Header>
                <Text subtitle>Create Role</Text>
              </Header>
              <RoleEditorV2 />
            </div>
          }
        />
        <Route
          path="/newv2"
          element={
            <div className="pane">
              <Header>
                <Text subtitle>Create Role</Text>
              </Header>
              <RoleEditorV2 />
            </div>
          }
        />
        <Route path="/edit/:roleId" element={<Edit />} />
        <Route path="/:roleId" element={<Role />} />
      </Routes>
    </div>
  )
}
