import "./App.sass"
import { QueryClientProvider, useQuery } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { AxiosError } from "axios"
import { useEffect } from "react"
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Spinner } from "./components/Spinner"
import { Dashboard } from "./Dashboard"
import { Invite } from "./Invite"
import { fetcher, retry } from "./lib/fetcher"
import { queryClient, Store, useStore } from "./lib/store"
import { Login } from "./Login"
import { MFA } from "./MFA"
import { Onboarding } from "./Onboarding"
import { IUser } from "./types"
import { Text } from "./components/Text"
import { Profile } from "./Profile"

function DashRedirect() {
  const store: Store = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (store.authUser?.workspaces.length) {
      return navigate(`/dashboard/workspaces/${store.authUser.workspaces[0].id}`, { replace: true })
    }
    // return navigate("/onboarding/setup", { replace: true })
    return navigate("/onboarding/waitlist", { replace: true })
  })
  return null
}

function NotFound() {
  return (
    <div className="page pane not-found">
      <Text size="xlarge">404 Not Found</Text>
    </div>
  )
}

function Base() {
  const location = useLocation()
  const navigate = useNavigate()

  const { error, isInitialLoading } = useQuery({
    queryFn: async () => fetcher("get", "/session/"),
    queryKey: ["session"],
    retry,
    refetchOnWindowFocus: true,
    onSuccess: (authUser: IUser) => {
      useStore.setState({ authUser })
      if (location.pathname.includes("/mfa")) return
      // MFA
      // user has mfa but has not verified mfa
      if (authUser.session_level === 0 && authUser.has_mfa) {
        return navigate("/mfa/login", { replace: true })
      }
      const inviteId = window.localStorage.getItem("inviteId")
      if (inviteId) {
        return navigate("/invite/redeem", { replace: true })
      }
      // user does not have mfa
      // TODO: enable full mfa mode:
      // if (authUser.session_level === 0 && !authUser.has_mfa) {
      //   return navigate("/mfa/register", { replace: true })
      // }
      if (location.pathname.includes("/dashboard")) return
      if (location.pathname.includes("/profile")) return
      if (location.pathname.includes("/invite")) return
      if (location.pathname.includes("/onboarding")) return
      if (authUser.workspaces.length) {
        return navigate(`/dashboard/workspaces/${authUser.workspaces[0].id}`, { replace: true })
      }
      // navigate("/onboarding/setup", { replace: true })
      navigate("/onboarding/waitlist", { replace: true })
    },
    onError: (error: AxiosError) => {
      if (error.response?.status !== 401) {
        // TODO: handle maintenance message
      }
      useStore.getState().reset()
      if (location.pathname.includes("/invite")) {
        const urlParams = new URLSearchParams(window.location.search)
        const inviteId = urlParams.get("inviteId")
        if (inviteId) {
          window.localStorage.setItem("inviteId", inviteId)
        }
      }
      navigate("/login", { replace: true })
    },
  })

  return (
    <>
      {isInitialLoading ? (
        <div className="page app">
          <Spinner />
        </div>
      ) : null}
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/dashboard/" element={<DashRedirect />} />
        <Route path="/dashboard/workspaces/:workspaceId/*" element={<Dashboard />} />
        <Route path="/onboarding/*" element={<Onboarding />} />
        <Route path="/invite/redeem" element={<Invite />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/mfa/*" element={<MFA />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Base />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  )
}
