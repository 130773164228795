import "./Login.sass"
import { useState } from "react"
import { FcGoogle } from "react-icons/fc"
import config from "./lib/config"
import { setTitle } from "./lib/utils"
import { FaGithub } from "react-icons/fa"
import { Text } from "./components/Text"

export function Login() {
  setTitle("Login")
  const [loading, setLoading] = useState<boolean>(false)
  const loginGithub = () => {
    setLoading(true)
    window.location.href = `${config.apiEndpoint}/auth/github`
  }
  const loginGoogle = () => {
    setLoading(true)
    window.location.href = `${config.apiEndpoint}/auth/google`
  }
  return (
    <div className="page login">
      <div className="login-box">
        <div className="row">
          <div className="logo">SYBL</div>
        </div>
        <div className="row info">Sign in</div>
        {import.meta.env.DEV ? (
          <button className="button login" onClick={loginGithub} disabled={loading}>
            <FaGithub /> Continue with Github
          </button>
        ) : null}
        <div className="buttons">
          <button className="button login" onClick={loginGoogle} disabled={loading}>
            <FcGoogle /> Continue with Google
          </button>
        </div>
      </div>
      <div className="row">
        <Text size="small">
          By signing up you agree to our{" "}
          <a href="https://sybl.dev/terms-of-use.pdf" target="_blank" rel="noreferrer">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href="https://sybl.dev/privacy-policy.pdf" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </Text>
      </div>
    </div>
  )
}
