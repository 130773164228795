import { Link, useParams } from "react-router-dom"
import { Button } from "./Button"
import { Text } from "./Text"
import "./UpgradePlan.sass"

export interface UpgradePlanProps {
  title?: string
}
export function UpgradePlan(props: UpgradePlanProps) {
  return (
    <div className="upgrade-plan">
      <div className="upgrade-plan-header">
        <Text size="large">{props.title || "Upgrade Plan"}</Text>
      </div>
      <div className="upgrade-plan-body">
        <UpgradePlanButton />
      </div>
    </div>
  )
}
export function UpgradePlanButton() {
  const params = useParams<{ workspaceId: string }>()
  return (
    <Link to={`/dashboard/workspaces/${params.workspaceId}/billing`}>
      <Button round variant="success">
        Upgrade Plan
      </Button>
    </Link>
  )
}
