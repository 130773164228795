import "./Select.sass"
import { components, ControlProps, ValueContainerProps } from "react-select"

export interface SelectOpt {
  value: string
  label: string
  icon?: React.ReactNode
}

export const selectStyles = {
  option: (base: any, { isSelected }: { isSelected: boolean }) => {
    return {
      ...base,
      backgroundColor: isSelected ? "#0563F3" : base.backgroundColor,
      padding: 16,
      zIndex: 4,
      display: "flex",
      alignItems: "center",
    }
  },
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
  }),
  control: (base: any) => ({
    ...base,
    border: "1px solid #efefef",
    cursor: "pointer",
    outline: "none",
    padding: 8,
    // boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
}

export const IconOption = (props: any) => {
  return (
    <components.Option {...props} className="option">
      {props.icon && <span style={{ marginRight: 8 }}>{props.icon()}</span>}
      {props.data.label}
    </components.Option>
  )
}
export const ImageOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.image && <img style={{ marginRight: 8, width: 30 }} src={props.image} alt="" />}
        {props.data.label}
      </div>
    </components.Option>
  )
}

export const ImagePriceOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.data.image && <img style={{ marginRight: 8, width: 30 }} src={props.data.image} alt="" />}
        {props.data.label}
        {props.data.price && (
          <span style={{ marginLeft: "auto" }}>
            {props.data.price.balance} (${props.data.price.value})
          </span>
        )}
      </div>
    </components.Option>
  )
}
