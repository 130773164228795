import * as ethers from "ethers"
import { BaseREST, ITransaction, typedDataSignMethods } from "../types"

interface TxParams {
  to?: string
  amount?: string
  data?: string
}

const BASE_TITLE = "SYBL"
export const setTitle = (t: string) => {
  document.title = `${t} | ${BASE_TITLE}`
}

const ERC20_TRANSFER = new ethers.Interface(["function transfer(address to, uint amount) returns (bool)"])

const parseABI = (data?: string): TxParams => {
  if (!data) return {}
  const fn = data?.slice(2, 10)
  switch (fn) {
    // function transfer(address to, uint amount) returns (bool)
    case "a9059cbb":
      const res = ERC20_TRANSFER.decodeFunctionData("transfer", data)
      return { to: res.getValue("to"), amount: res.getValue("amount") }
  }
  return { data }
}

const _parseTxData = (data?: string | null): TxParams => {
  if (!data) return {}
  const decoded = window.atob(data)
  try {
    if (ethers.isHexString(decoded)) {
      const res = ethers.toUtf8String(decoded)
      return parseABI(res)
    }
    return parseABI(decoded)
  } catch (e) {
    return parseABI(decoded)
  }
}

export const parseTxDatav2 = (tx: ITransaction): TxParams => {
  if (typedDataSignMethods.includes(tx.method)) {
    const jsn = _parseTxData(tx.tx_data.Data)
    if (typeof jsn === "string") return jsn
    if (!jsn?.data) return jsn
    return { data: JSON.stringify(JSON.parse(jsn.data), null, 2) }
  }
  return _parseTxData(tx.tx_data.Data)
}

export const sortByDate = <T extends BaseREST>(arr?: T[], direction = "desc"): T[] => {
  return (arr || []).sort((a: T, b: T) => {
    if (direction === "desc") {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    }
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  })
}

export const formatFloat = (v: string, precision: number = 2) => {
  if (!v) return ""
  return parseFloat(v)
    .toFixed(precision)
    .replace(/[.,]0+$/, "")
}

export const formatAddress = (address: string) => {
  try {
    return ethers.getAddress(address)
  } catch (e) {
    return address
  }
}
