import "./Card.sass"
import clsx from "clsx"
import React from "react"
import { Variant } from "../types"

interface CardProps {
  variant?: Variant
  plain?: boolean
}
export function Card({
  className,
  variant,
  plain,
  children,
}: React.HtmlHTMLAttributes<HTMLDivElement> & CardProps) {
  return <div className={clsx("card", variant, className, { plain })}>{children}</div>
}
