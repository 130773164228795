import "./Profile.sass"
import { Button } from "./components/Button"
import { Text } from "./components/Text"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./hooks/useAuth"
import { Header } from "./components/Header"
import { useQuery } from "@tanstack/react-query"
import { fetcher, retry } from "./lib/fetcher"
import { IAuthDevice } from "./types"
import { IoAddOutline } from "react-icons/io5"
import { Timeago } from "./lib/timeago"
import { Empty } from "./components/Empty"

export function Profile() {
  const navigate = useNavigate()
  const auth = useAuth()

  const addKey = () => {
    if (auth.authUser?.has_mfa) {
      return navigate("/mfa/login?add=true")
    }
    navigate("/mfa/register")
  }
  const removeKey = (v: IAuthDevice) => {
    window.localStorage.setItem("mfa_device_id", v.id)
    return navigate("/mfa/login?remove=true")
  }

  const { data = [], isLoading } = useQuery({
    queryFn: async (): Promise<IAuthDevice[]> => fetcher("get", "/mfa/devices"),
    queryKey: ["mfa", "devices"],
    retry,
  })

  return (
    <div className="page profile">
      <div className="pane">
        <Header>
          <Text subtitle>Manage your personal account</Text>
        </Header>
        <div className="data-content">
          <Header>
            <Text subtitle>Security Keys</Text>
            <Button variant="success" round onClick={addKey}>
              Add Security Key <IoAddOutline size={20} />
            </Button>
          </Header>
          <div className="rows">
            <div className="row">
              <div className="name">Name</div>
              <div className="date">Created At</div>
              <div className="manage">Manage</div>
            </div>
            {data.map((v: IAuthDevice) => {
              return (
                <div key={v.id} className="row">
                  <div className="name">{v.name}</div>
                  <div className="date">{Timeago(new Date(v.created_at))}</div>
                  <div>
                    <Button onClick={() => removeKey(v)}>Delete</Button>
                  </div>
                </div>
              )
            })}
            {data.length === 0 && !isLoading ? (
              <Empty>
                <Button variant="success" round onClick={addKey}>
                  Add Security Key <IoAddOutline size={20} />
                </Button>
              </Empty>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
