export type Variant = "success" | "error" | "warning" | "accent" | "blue"

export type BillingPlanName = "trial_01" | "team_01" | "business_01" | "enterprise_01"
export const PlanFree = "trial_01"
export const PlanTeam = "team_01"
export const PlanEnterprise = "enterprise_01"
// user account workspace roles
export type OwnerRole = "owner"
export type AdminRole = "admin"
export type UserRole = "user"

export const OwnerRole = "owner"
export const AdminRole = "admin"
export const UserRole = "user"

const ADMIN_DENIED = "ADMIN_DENIED"
const ADMIN_TIMEDOUT = "ADMIN_TIMEDOUT"
export const errors: { [key: string]: string } = {
  ADMIN_DENIED,
  ADMIN_TIMEDOUT,
}

export const typedDataSignMethods = [
  "eth_signTypedData",
  "eth_signTypedData_v1",
  "eth_signTypedData_v3",
  "eth_signTypedData_v4",
]

export const signingMethods = ["eth_sign", "personal_sign", ...typedDataSignMethods]

export type WorkspaceRole = OwnerRole | AdminRole | UserRole

export interface BaseREST {
  id: string
  created_at: string
  updated_at: string
}

export interface IAuth {
  authUser?: IUser
  workspace?: IWorkspace
  isOwner: boolean
  isAdmin: boolean
  isOwnerOrAdmin: boolean
}

export interface IUser extends BaseREST {
  email: string
  name?: string
  workspaces: IWorkspace[]
  role?: IRole
  session_level: number
  has_mfa: boolean
}

export interface IWallet extends BaseREST {
  workspace_id: string
  vault_id: string
  name: string
  imported: boolean
  accounts: IAccount[]
  tokens: IToken[]
  roles?: IWalletRole[]
}

export interface IWorkspace extends BaseREST {
  name: string
  role: IRole
  feature_flags: {
    import_accounts?: boolean
  }
  billing_plan: {
    name: BillingPlanName
    wallets: number
    admins: number
    users: number
    roles: number
    alerts: {
      mainnet: number
      testnet: number
    }
    api_keys: {
      mainnet: number
      testnet: number
    }
  }
}

export interface IInvite extends BaseREST {
  email: string
  role_id: string
  expires_at: string
  role: IRole
  workspace_id: string
}

export interface IWalletRolePerm extends BaseREST {
  id: string
  role_id: string
  type: string
  label: string
  value: string
  addresses: string[]
  approvers: string[]
  bytecode: string[]
  methods: string[]
  tokens: string[]
  values: string[]
  created_at: string
}

export interface IWalletRole extends BaseREST {
  name: string
  official: boolean
  permissions: IWalletRolePerm[]
  users: IWalletRoleUser[]
  api_keys: IWalletRoleApiKey[]
}

export interface IWalletRoleUser extends BaseREST {
  user: IUser
  wallet: IWallet
}

export interface IWalletRoleApiKey extends BaseREST {
  api_key: IApiKey
  wallet: IWallet
}

export interface IWalletTransaction extends BaseREST {
  wallet_id: string
  user_id: string
  api_key_id: string
  role_id: string
  status: string
  internal_error: string
  network_error: string
  approvers: IWalletTransactionApprover[]
  tx_data: ITXData
  expires: string
  method: string
  result: { id?: string | number; result: string }
}

export interface IWalletTransactionApprover {
  user_id: string
  approved: boolean
  created_at: string
}

export interface IRole extends BaseREST {
  role: WorkspaceRole
}
export interface IAccount {
  address: string
  chain: string
  path: string
}

export interface IApiKey extends BaseREST {
  wallet_id: string
  prefix: string
  name: string
  is_mainnet: boolean
  secret?: string
  last_used: string
  roles: IWalletRole[]
  wallet?: IWallet
}

export interface IToken extends BaseREST {
  type: string
  address: string
  chain_id: number
  name: string
  symbol: string
  decimals: number
  enabled: boolean
  official?: boolean
  logo_url: string
  coingecko_id: string
}

// this is in lowersnakecase as it is returned from alchemy
export interface IWorkspaceWalletNFT {
  name: string
  tokenId: string
  image: {
    thumbnailUrl: string
  }
  contract: {
    address: string
    name: string
    symbol: string
    tokenType: string
    isSpam: boolean
  }
}

export interface IWorkspaceWalletNFTs {
  wallet_id: string
  tokens: IWorkspaceWalletNFT[]
}

export interface ITransaction {
  id: string
  wallet: IWallet
  expires: string | number
  method: string
  tx_data: ITXData
  revert_error?: string
}

export interface ITXData {
  AccessList: string | null
  ChainID: number | null
  Data: string | null
  Gas: number | null
  GasFeeCap: number | null
  GasTipCap: number | null
  Nonce: number | null
  To: string | null
  Value: number | null
}

export interface IWalletAlert extends BaseREST {
  workspace_id: string
  wallet_id: string
  name: string
  destinations: IWalletAlertItemDestination[]
  chain_id: number
  status: "ALARM" | "ACTIVE"
  wallet: IWallet
  items: IWalletAlertItem[]
}

export interface IWalletAlertItem extends BaseREST {
  wallet_alert_id: string
  type: string
  comparator: string
  value: string
}

export interface IWalletAlertItemDestination {
  email: string
}

export interface IReviewableTransaction extends ITransaction {
  role: IWalletRole
  user?: IUser
  api_key?: IApiKey
  approvers: IUser[]
}

export interface FiatPrice {
  usd: number
}

export interface FiatData {
  [key: string]: FiatPrice
}

export interface IBalancePair {
  [key: string]: {
    amount: number
    value: number
  }
}

export interface IAuthDevice extends BaseREST {
  user_id: string
  name: string
}

export interface IBillingStatus {
  billing_status: string
  billing_plan: string
  billing_email: string
}
