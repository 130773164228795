import { CodeDemo } from "./CodeDemo"
import "./Developer.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useState } from "react"
import { FaKey } from "react-icons/fa"
import { IoIosAdd } from "react-icons/io"
import { MdContentCopy } from "react-icons/md"
import { Link, useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import { Button } from "./components/Button"
import { Empty } from "./components/Empty"
import { Header } from "./components/Header"
import { Modal, ModalFooter } from "./components/Modal"
import { SelectOpt, selectStyles } from "./components/Select"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import config from "./lib/config"
import { fetcher, retry } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { Timeago } from "./lib/timeago"
import { IApiKey, IWallet, IWalletRole } from "./types"
import { useAuth } from "./hooks/useAuth"

const customSelectStyles = {
  ...selectStyles,

  control: (base: any) => ({
    ...base,
    border: "1px solid #C5C4C4",
    padding: 4,
  }),
}
const networkOptions = [
  {
    label: "All Mainnets (Ethereum, Polygon, etc)",
    value: true,
  },
  {
    label: "All Testnets (Goerli, Sepolia, etc)",
    value: false,
  },
]

export function Developer() {
  const [rpcCopied, setRPCCopied] = useState<boolean>(false)
  const params = useParams<{ workspaceId: string }>()
  const auth = useAuth()
  const chainName = useStore((s: Store) => s.chain)
  const chain = config.chains[chainName]

  const [apiKeySecretCopied, setApiKeySecretCopied] = useState<boolean>(false)
  const [apiKeyName, setApiKeyName] = useState<string>("")
  const [apiKeyIsMainnet, setApiKeyIsMainnet] = useState<boolean>(false)
  const [apiKeySecret, setApiKeySecret] = useState<string>("")
  const [selectedWallet, setSelectedWallet] = useState<IWallet | undefined>()
  const [selectedRole, setSelectedRole] = useState<IWalletRole | undefined>()
  const setApiKeyModal = (v: boolean) => useStore.setState({ apiKeyModalActive: v })
  const apiKeyModal = useStore((s: Store) => s.apiKeyModalActive)

  const { data: walletsData } = useQuery({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
    retry,
  })

  const walletOptions = walletsData?.map((p) => ({
    value: p.id,
    label: p.name,
  }))

  const { data, refetch: refetchApiKeys } = useQuery({
    queryFn: async (): Promise<IApiKey[]> => fetcher("get", `/workspaces/${params.workspaceId}/api_keys/`),
    queryKey: [params.workspaceId, "wallets", "api_keys"],
    onError: (error: AxiosError) => {
      console.log(error)
      if (error.response?.status) {
      }
    },
  })

  const { data: roles, isLoading: isloadingRoles } = useQuery({
    queryFn: async (): Promise<IWalletRole[]> => fetcher("get", `/workspaces/${params.workspaceId}/roles/`),
    queryKey: [params.workspaceId, "roles"],
  })

  const {
    mutate: createApiKeyMutation,
    isLoading: isLoadingCreateApiKey,
    error: createApiKeyError,
  } = useMutation<
    IApiKey,
    AxiosError,
    { name: string; is_mainnet: boolean; wallet_id: string; role_id: string }
  >({
    mutationFn: (attrs: { name: string; wallet_id: string; role_id: string }) =>
      fetcher("post", `workspaces/${params.workspaceId}/api_keys/`, attrs),
    onSuccess: (data: IApiKey) => {
      setApiKeySecret(data.secret!)
      setApiKeyName("")
      setSelectedRole(undefined)
      setSelectedWallet(undefined)
      refetchApiKeys()
    },
  })
  const { mutate: deleteApiKeyMutation } = useMutation<IApiKey, AxiosError, IApiKey>({
    mutationFn: (attrs: IApiKey) =>
      fetcher("delete", `workspaces/${params.workspaceId}/api_keys/${attrs.id}`),
    onSuccess: () => refetchApiKeys(),
    onError: () => refetchApiKeys(),
  })

  const createApiKey = () => {
    if (!selectedWallet || !selectedRole) return
    createApiKeyMutation({
      name: apiKeyName,
      is_mainnet: apiKeyIsMainnet,
      wallet_id: selectedWallet.id,
      role_id: selectedRole.id,
    })
  }

  const apiKeyRoles = roles?.filter((r: IWalletRole) => {
    return !r.permissions.find((p) => p.type === "approval")
  })

  const checkValidAPIKeyRole = (option: SingleValue<SelectOpt>) => {
    if (!apiKeyRoles) return false
    if (!option) return false
    return apiKeyRoles?.find((r: IWalletRole) => r.id === option.value)
  }

  const deleteApiKey = (a: IApiKey) => {
    if (window.confirm("Are you sure you want to delete this api key?")) {
      deleteApiKeyMutation(a)
    }
  }

  const copyApiKeySecret = () => {
    if (!apiKeySecret) return
    setApiKeySecretCopied(true)
    navigator.clipboard.writeText(apiKeySecret)
    setTimeout(() => {
      setApiKeySecretCopied(false)
    }, 2000)
  }

  const copyRPC = () => {
    setRPCCopied(true)
    navigator.clipboard.writeText(`${config.rpcEndpoint}?api_key=API_KEY&chain_id=${chain.chainId}`)
    setTimeout(() => {
      setRPCCopied(false)
    }, 2000)
  }

  const onWalletChange = (v: SingleValue<SelectOpt>) => {
    if (!v) return
    const wallet = walletsData?.find((p) => p.id === v.value)
    if (!wallet) return
    setSelectedWallet(wallet)
  }
  const onNetworkTypeChange = (v: SingleValue<{ label: string; value: boolean }>) => {
    if (!v) return
    setApiKeyIsMainnet(v.value)
  }

  return (
    <div className="page developer">
      <div className="pane">
        <Header>
          <Text subtitle>Ethereum RPC Access</Text>
        </Header>
        <div className="content">
          <div className="input-row">
            <div className="address">
              <TextInput
                readOnly
                defaultValue={`${config.rpcEndpoint}?api_key=API_KEY&chain_id=${chain.chainId}`}
              />
              <Button className="copy" onClick={copyRPC}>
                <MdContentCopy size={22} />
              </Button>
              {rpcCopied ? "Copied!" : null}
            </div>
            <Text>Use this RPC endpoint to connect to any of the suported chains.</Text>
          </div>
        </div>

        <Header>
          <Text bold>Network RPC API Keys</Text>

          {auth.isOwnerOrAdmin && (
            <Button flat uppercase round variant="success" onClick={() => setApiKeyModal(true)}>
              new api key
              <IoIosAdd size={18} />
            </Button>
          )}
        </Header>
        {data?.length === 0 ? (
          <Empty>
            <Text>API Keys provide access to your wallets</Text>
            <Button round variant="success" style={{ marginTop: 16 }} onClick={() => setApiKeyModal(true)}>
              Create API Key
              <FaKey />
            </Button>
          </Empty>
        ) : (
          <div className="rows">
            <div className="row">
              <Text className="column prefix" subtitle>
                Key ID
              </Text>
              <Text className="column name" subtitle>
                Name
              </Text>
              <Text className="column name" subtitle>
                Networks
              </Text>
              <Text className="column name" subtitle>
                Account
              </Text>
              <Text className="column name" subtitle>
                Roles Assigned
              </Text>
              <Text className="column timestamp" subtitle>
                Created
              </Text>
              <Text className="column timestamp" subtitle>
                Last Used
              </Text>
              <Text className="column" subtitle>
                {auth.isOwnerOrAdmin ? "Action" : ""}
              </Text>
            </div>

            {data?.map((v: IApiKey) => {
              return (
                <div key={v.id} className="row">
                  <Text className="column prefix shaded">{v.prefix.slice(0, 16)}...</Text>
                  <Text className="column name">{v.name}</Text>
                  <Text className="column name">{v.is_mainnet ? "Mainnets" : "Testnets"}</Text>
                  <Text className="column name">
                    <Link to={`../wallets/${v.wallet?.id}`}>
                      <Text bold>{v.wallet?.name}</Text>
                    </Link>
                  </Text>
                  <Text className="column name" variant={v.roles?.length ? "success" : "error"}>
                    {v.roles?.length
                      ? v.roles.map((r: IWalletRole) => (
                          <Link to={`../permissions/${r.id}`} key={r.id}>
                            <Text bold>{r.name} </Text>
                          </Link>
                        ))
                      : "None"}
                  </Text>
                  <Text className="column timestamp" title={v.created_at}>
                    {Timeago(new Date(v.created_at))}
                  </Text>
                  <Text className="column timestamp" title={v.created_at}>
                    {v.last_used ? Timeago(new Date(v.last_used)) : "Never"}
                  </Text>

                  <div className="column delete">
                    {auth.isOwnerOrAdmin && (
                      <Button size="small" uppercase onClick={() => deleteApiKey(v)}>
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <CodeDemo />
      </div>

      <Modal
        title="Create API Key"
        isOpen={apiKeyModal}
        onRequestClose={() => {
          setApiKeyModal(false)
          setApiKeySecret("")
          setSelectedWallet(undefined)
          setSelectedRole(undefined)
          setApiKeyName("")
        }}
        footer={
          <ModalFooter>
            {apiKeySecret ? (
              <>
                <div />
                <Button
                  onClick={() => {
                    setApiKeyModal(false)
                    setApiKeySecret("")
                  }}
                  disabled={isLoadingCreateApiKey}
                  variant="success"
                >
                  Confirm
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setApiKeyModal(false)
                    setApiKeyName("")
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={createApiKey}
                  disabled={isLoadingCreateApiKey || !apiKeyName || !selectedWallet || !selectedRole}
                  variant="success"
                >
                  Create
                </Button>
              </>
            )}
          </ModalFooter>
        }
      >
        {apiKeySecret ? (
          <div className="api-key-modal">
            <Text>This API Key will not be shown again. </Text>
            <Text bold>It should be kept secure.</Text>

            <div className="address">
              <TextInput readOnly defaultValue={apiKeySecret} />

              <Button onClick={copyApiKeySecret}>
                <MdContentCopy size={22} />
              </Button>
              {apiKeySecretCopied ? "Copied!" : null}
            </div>
          </div>
        ) : (
          <>
            <Text subtitle>Key Name</Text>
            <TextInput
              autoFocus
              value={apiKeyName}
              minLength={1}
              maxLength={64}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setApiKeyName(e.target.value)}
              placeholder="Dev Key #1337"
            />

            <div className="row">
              <Text subtitle>Assign to Account</Text>
              <Select
                className="react-select"
                menuPortalTarget={document.body}
                placeholder="Select Account"
                options={walletOptions}
                styles={customSelectStyles}
                onChange={onWalletChange}
              />
            </div>

            <div className="row">
              <Text subtitle>Network Access</Text>
              <Select
                className="react-select"
                menuPortalTarget={document.body}
                placeholder="Mainnets (Ethereum, Polygon) or Testnets (Goerli, Sepolia)"
                value={networkOptions.find((v) => v.value === apiKeyIsMainnet)}
                options={networkOptions}
                styles={customSelectStyles}
                onChange={onNetworkTypeChange}
              />
            </div>

            <div className="row">
              <Text subtitle>Select Role</Text>
              <Select
                className="react-select"
                menuPortalTarget={document.body}
                placeholder="Select Role"
                styles={customSelectStyles}
                onChange={(v: SingleValue<SelectOpt>) => {
                  setSelectedRole(roles?.find((u: IWalletRole) => u.id === v?.value))
                }}
                isOptionDisabled={(option) => !checkValidAPIKeyRole(option)}
                options={roles?.map((v: IWalletRole) => {
                  if (!checkValidAPIKeyRole({ value: v.id, label: v.name }))
                    return {
                      value: v.id,
                      label: `${v.name} (API Key roles can't have approvals)`,
                    }
                  return {
                    value: v.id,
                    label: v.name,
                  }
                })}
              />
              <div className="row">
                <Text>Note: API Keys can't be assigned to roles that have approvals set.</Text>
              </div>
            </div>
            <div className="row">
              {createApiKeyError ? (
                <Text warning>
                  <>
                    {createApiKeyError.response?.data
                      ? createApiKeyError.response?.data
                      : createApiKeyError.message}
                  </>
                </Text>
              ) : null}
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}
