import { Variant } from "../types"
import "./Button.sass"
import clsx from "clsx"
import { ButtonHTMLAttributes } from "react"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "small" | "default" | "large"
  flat?: boolean
  round?: boolean
  plain?: boolean
  ghost?: boolean
  uppercase?: boolean
  variant?: Variant
  disabled?: boolean
  ref?: any
}

export function Button(props: ButtonProps) {
  const { className, size, flat, plain, round, ghost, uppercase, variant, disabled, ...rest } = props
  return (
    <button
      className={clsx(
        "button",
        variant,
        size,
        { flat, plain, round, ghost, uppercase, disabled },
        props.className
      )}
      disabled={disabled}
      {...rest}
    >
      {props.children}
    </button>
  )
}
