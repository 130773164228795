import { useQuery } from "@tanstack/react-query"
import { Core } from "@walletconnect/core"
import { JsonRpcPayload, JsonRpcResult } from "@walletconnect/jsonrpc-types"
import { SessionTypes, SignClientTypes } from "@walletconnect/types"
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils"
import { IWeb3Wallet, Web3Wallet } from "@walletconnect/web3wallet"
import { AxiosError } from "axios"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { IoIosClose } from "react-icons/io"
import { Link, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Empty } from "./components/Empty"
import { Modal } from "./components/Modal"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import config from "./lib/config"
import { fetcher } from "./lib/fetcher"
import { useStore } from "./lib/store"
import { IWallet } from "./types"
import { getDefaultAccount } from "./Wallet"
import "./WalletConnectModal.sass"
import { ethers } from "ethers"

const WALLET_CONNECT_PUBLIC_ID = import.meta.env.DEV
  ? "461abb56ef5fecf32409304b2fbb9c0f"
  : "3832811dc1c49ab57cf4623b7cc00b4c"

const METHODS = [
  "eth_sendTransaction",
  "eth_signTransaction",
  "eth_sign",
  "personal_sign",
  "eth_signTypedData",
]
const EVENTS = ["chainChanged", "accountsChanged", "connect", "disconnect"]

const core = new Core({
  logger: config.env === "development" ? "debug" : "error",
  projectId: WALLET_CONNECT_PUBLIC_ID,
})

export function WalletConnectModal(props: any) {
  const params = useParams<{ workspaceId: string }>()
  const { workspaceId } = params
  const [walletConnectURI, setWalletConnectURI] = useState<string>("")
  const [proposal, setProposal] = useState<SignClientTypes.EventArguments["session_proposal"]>()
  const [web3wallet, setWeb3Wallet] = useState<IWeb3Wallet>()

  const [errorMsg, setErrorMsg] = useState<string>("")
  const peers = useStore((state) => state.walletConnectPeers)
  const chain = useStore((state) => state.chain)
  const chainId = config.chains[chain].chainId

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedWallet, setSelectedWallet] = useState<IWallet>()

  const {
    data: wallets = [],
    isLoading,
    isInitialLoading,
  } = useQuery<IWallet[], AxiosError>({
    queryKey: [params.workspaceId, "wallets"],
    queryFn: async () => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
  })

  const init = async () => {
    const wc = await Web3Wallet.init({
      core,
      metadata: {
        name: "SYBL",
        description: "SYBL Platform",
        url: "https://sybl.dev",
        icons: ["https://sybl.dev/favicon.png"],
      },
    })
    setWeb3Wallet(wc)
    const sessions: Record<string, SessionTypes.Struct> = wc.getActiveSessions()
    useStore.setState({ walletConnectPeers: Object.keys(sessions).map((k) => sessions[k]) })

    wc.on("session_proposal", async (proposal) => {
      setLoading(false)
      setProposal(proposal)
    })

    wc.on("session_request", async (event: SignClientTypes.EventArguments["session_request"]) => {
      const { topic, params, id } = event
      const { request } = params

      const peers = useStore.getState().walletConnectPeers

      const peer = peers.find((v) => v.topic === topic)
      if (!peer) {
        setErrorMsg("WalletConnect Peer not found")
        return
      }
      const peerWallet = peer.namespaces.eip155?.accounts?.map((v) => v.split(":")[2])[0].toLowerCase()

      const chainId = peer.namespaces.eip155?.chains?.[0].replace("eip155:", "")

      const wallet = wallets.find((w) => w.accounts[0].address.toLowerCase() === peerWallet)

      if (!wallet) {
        setErrorMsg("WalletConnect Wallet not found")
        return
      }

      let payload: JsonRpcPayload = {
        ...request,
        id: event.id,
        jsonrpc: "2.0",
      }
      if (request.params[0]?.maxFeePerGas === null) {
        const url = `${config.apiEndpoint}/workspaces/${workspaceId}/wallets/${wallet.id}/rpc/${chainId}?kind=preview`
        const provider = new ethers.JsonRpcProvider(url)

        const signer = await provider.getSigner()
        const preview = await signer.populateTransaction(request.params[0])
        payload = {
          ...payload,
          params: [preview],
        }
      }

      const url = `/workspaces/${wallet.workspace_id}/wallets/${wallet.id}/rpc/${chainId}?kind=preview`
      const body = await fetcher<JsonRpcPayload, JsonRpcResult>("post", url, payload).catch((error) => {
        setErrorMsg(error.message)
        throw error
      })

      try {
        await wc.respondSessionRequest({ topic, response: body })
      } catch (e) {
        console.error(e)
      }
    })
    wc.on("session_delete", (request) => {
      useStore.setState((state) => ({
        walletConnectPeers: state.walletConnectPeers.filter((v) => v.topic !== request.topic),
      }))
    })
    // wc.on('auth_request', (request) => {})
  }

  useEffect(() => {
    if (isInitialLoading) return
    init().then().catch(console.error)
  }, [isInitialLoading])

  // useEffect(() => {
  // if (!web3wallet) return

  // Promise.all(
  //   peers.map(async (peer) => {
  //     return web3wallet.emitSessionEvent({
  //       topic: peer.topic,
  //       event: {
  //         name: "chainChanged",
  //         data: peer.namespaces.eip155?.accounts?.map((v) => v.split(":")[2]),
  //       },
  //       chainId: `eip155:${chainId}`,
  //     }).catch(console.error)
  //   })
  // ).finally()
  // }, [chainId])

  const approveSession = async () => {
    if (!proposal || !web3wallet) return
    setLoading(true)
    setErrorMsg("")

    const address = getDefaultAccount(selectedWallet)?.address
    const chains = Object.keys(config.chains).map((k: string) => `eip155:${config.chains[k].chainId}`)
    const accounts = chains.map((c: string) => `${c}:${address}`)
    try {
      const approvedNamespaces = buildApprovedNamespaces({
        proposal: proposal.params,
        supportedNamespaces: {
          eip155: {
            chains: chains,
            methods: METHODS,
            events: EVENTS,
            accounts,
          },
        },
      })

      web3wallet
        .approveSession({
          id: proposal.id,
          namespaces: approvedNamespaces,
        })
        .then((session: SessionTypes.Struct) => {
          useStore.setState({ walletConnectPeers: [...peers, session] })
          setLoading(false)
          setProposal(undefined)
          setErrorMsg("")
          setSelectedWallet(undefined)
          setWalletConnectURI("")
        })
        .catch((error) => {
          setErrorMsg(error.message)
          setLoading(false)
        })
    } catch (error: any) {
      console.error({ error })
      setErrorMsg(error.message || error)
      setLoading(false)
    }
  }

  const connectWallet = async () => {
    setLoading(true)
    await core.pairing
      .pair({ uri: walletConnectURI })
      // .then((res) => core.pairing.activate(res))
      .catch((error) => {
        console.log({ error })
        setErrorMsg(error.message)
      })
  }

  const disconnectPeer = async (peer: SessionTypes.Struct) => {
    await web3wallet?.disconnectSession({ topic: peer.topic, reason: getSdkError("USER_DISCONNECTED") })
    useStore.setState({ walletConnectPeers: peers.filter((p) => p.topic !== peer.topic) })
  }

  const rejectSession = async () => {
    if (!proposal || !web3wallet) {
      setProposal(undefined)
      setLoading(false)
      return
    }
    web3wallet
      ?.rejectSession({
        id: proposal?.id,
        reason: getSdkError("USER_REJECTED_METHODS"),
      })
      .then(() => null)
      .catch(() => null)
      .finally(() => {
        setLoading(false)
        setSelectedWallet(undefined)
        setProposal(undefined)
        setErrorMsg("")
        setWalletConnectURI("")
      })
  }

  return (
    <Modal
      title="Wallet Connect"
      isOpen={props.isOpen}
      className="wallet-connect"
      onRequestClose={() => {
        props.onRequestClose(false)
        setLoading(false)
        setSelectedWallet(undefined)
        setProposal(undefined)
        setErrorMsg("")
        setWalletConnectURI("")
      }}
    >
      <div className="left">
        {peers.length ? (
          <div className="v2-connections">
            {peers.map((peer: SessionTypes.Struct) => {
              const peerChains = peer.namespaces.eip155?.chains
                ?.map((v) =>
                  Object.keys(config.chains).find(
                    (c: string) => String(config.chains[c].chainId) === v.replace("eip155:", "")
                  )
                )
                .join(", ")

              const peerWallet = peer.namespaces.eip155?.accounts?.map((v) => v.split(":")[2]).join(", ")
              const wallet = wallets.find((w) => w.accounts[0].address === peerWallet)

              return (
                <div key={peer.topic} className="connection">
                  <div className="details">
                    <div className="name">
                      <Text bold>{peer.peer.metadata.name}</Text>
                    </div>
                    <Text size="small">
                      <a href={peer.peer.metadata.url} target="_blank" rel="noreferrer">
                        {peer.peer.metadata.url}
                      </a>
                    </Text>
                    <br />
                    <Text>{peer.peer.metadata.description}</Text>
                    <Text>
                      Chain: <Text bold>{peerChains || "None"}</Text>
                    </Text>
                    {wallet ? (
                      <Text>
                        Wallet:{" "}
                        <Link to={`/dashboard/workspaces/${params.workspaceId}/wallets/${wallet.id}`}>
                          <Text bold>{wallet.name}</Text>
                        </Link>
                      </Text>
                    ) : null}
                  </div>

                  <div className="disconnect">
                    <Button onClick={() => disconnectPeer(peer)} title="Disconnect">
                      <IoIosClose size={36} />
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <Empty className="walletconnect-logo">
            <img
              src="https://raw.githubusercontent.com/WalletConnect/walletconnect-assets/master/Logo/Gradient/Logo.png"
              alt="Walletconnect"
            />
          </Empty>
        )}
      </div>
      <div className="right">
        {loading ? (
          <Empty>
            <Spinner />
          </Empty>
        ) : (
          <>
            {proposal?.params?.id ? (
              <div className="v2-proposal">
                <Text size="large">Connecting with</Text>

                <div className="details">
                  <Text bold>{proposal.params.proposer.metadata.name}</Text>
                  <a href={proposal.params.proposer.metadata.url} target="_blank" rel="noreferrer">
                    {proposal.params.proposer.metadata.url}
                  </a>
                  <br />
                  <Text>{proposal.params.proposer.metadata.description}</Text>
                </div>
              </div>
            ) : null}

            {proposal ? (
              <>
                <Text bold size="large" className="title">
                  Select Wallet
                </Text>
                <div className="scroll-wrapper">
                  <div className="wallets">
                    {isLoading && (
                      <Empty>
                        <Spinner />
                      </Empty>
                    )}
                    {wallets?.map((wallet: IWallet) => {
                      const selected = selectedWallet?.id === wallet.id
                      return (
                        <label key={wallet.id} className={clsx("wallet", { selected })}>
                          <input type="radio" checked={selected} onChange={() => setSelectedWallet(wallet)} />
                          <div className="info">
                            <Text bold className="name">
                              {wallet.name}
                            </Text>
                            <Text overflow className="address">
                              {getDefaultAccount(wallet)?.address}
                            </Text>
                          </div>
                        </label>
                      )
                    })}
                  </div>
                </div>

                <div className="buttons">
                  <Button className="cancel" onClick={rejectSession}>
                    Cancel
                  </Button>
                  <Button
                    className="approve"
                    onClick={approveSession}
                    variant={!!selectedWallet ? "success" : undefined}
                    disabled={selectedWallet === undefined}
                  >
                    Approve
                  </Button>
                </div>
              </>
            ) : null}

            {!proposal ? (
              <div className="add-connection">
                <Text>Add new connection</Text>
                <TextInput
                  autoFocus
                  value={walletConnectURI}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWalletConnectURI(e.target.value)}
                  placeholder="WalletConnect URI wc:39210423da3234...."
                />
                <Button
                  className="approve"
                  onClick={connectWallet}
                  variant={!!selectedWallet ? "success" : undefined}
                  disabled={loading || walletConnectURI.length < 32}
                >
                  Connect
                </Button>
              </div>
            ) : null}

            {errorMsg && (
              <Text className="error" variant="error">
                {errorMsg}
              </Text>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}
