import * as Sentry from "@sentry/react"
import { Analytics } from "@vercel/analytics/react"

try {
  Sentry.init({
    dsn: "https://98407e140ee9c1cb56bd22aee1e18bb6@o4505854101094400.ingest.sentry.io/4506004541734912",
    enabled: !import.meta.env.DEV,
    integrations: [
      // new Sentry.BrowserTracing({
      //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [/^https:\/\/api\.sybl\.dev/],
      // }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
} catch (e) {}

import App from "./App"
import "./index.sass"
import buffer from "buffer"
import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"

window.Buffer = global.Buffer = buffer.Buffer

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
    <Analytics />
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
