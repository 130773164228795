import "./Tag.sass"
import clsx from "clsx"
import { Variant } from "../types"

interface TagProps {
  variant?: Variant
}

export function Tag({
  className,
  variant,
  children,
  ...props
}: TagProps & React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("tag", variant, className)} {...props}>
      {children}
    </div>
  )
}
