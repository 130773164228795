import "./Wallets.sass"
import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { BsChevronRight } from "react-icons/bs"
import { IoIosAdd } from "react-icons/io"
import { Link, useParams } from "react-router-dom"
import { Addr } from "./components/Addr"
import { Button } from "./components/Button"
import { Card } from "./components/Card"
import { Empty } from "./components/Empty"
import { Header } from "./components/Header"
import { Tag } from "./components/Tag"
import { Text } from "./components/Text"
import { useTokenFiatBalances, useWalletBalance } from "./hooks/useWalletBalance"
import config, { Chain } from "./lib/config"
import { fetcher, retry } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { formatFloat } from "./lib/utils"
import { IToken, IWallet, IWorkspaceWalletNFT, IWorkspaceWalletNFTs } from "./types"
import { useAuth } from "./hooks/useAuth"

export function WalletCard({
  wallet,
  tokens,
  nfts,
  chain,
}: {
  wallet: IWallet
  tokens: IToken[]
  nfts: IWorkspaceWalletNFT[]
  chain: Chain
}) {
  const { data: fiatData } = useTokenFiatBalances(tokens)

  const { data, isLoading: isLoadingWalletValue } = useWalletBalance(tokens, fiatData, wallet)

  return (
    <Link key={wallet.id} to={`wallets/${wallet.id}`}>
      <Card>
        <div className="card-header">
          <Text className="name" bold overflow>
            {wallet.name}
          </Text>
          <div className="tags">
            {wallet.imported ? (
              <Tag variant="warning">
                <Text subtitle size="small" title="This account was created externally and imported">
                  Imported
                </Text>
              </Tag>
            ) : null}
            <Tag>
              <Addr className="address">{wallet.accounts.length && wallet.accounts[0].address}</Addr>
            </Tag>
          </div>
        </div>
        <div className="card-body">
          <div className="data-row">
            <Text size="large" bold>
              {isLoadingWalletValue ? "Loading..." : `$${formatFloat(data.total, 2)}`}
            </Text>
            <div className="nfts">
              {nfts.slice(0, 5).map((nft) => (
                <div
                  key={nft.tokenId}
                  className="nft"
                  style={{
                    backgroundImage: `url(${nft.image.thumbnailUrl})`,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div />
          <Button plain>
            View
            <BsChevronRight />
          </Button>
        </div>
      </Card>
    </Link>
  )
}

export function Wallets() {
  const params = useParams<{ workspaceId: string }>()
  const auth = useAuth()
  const setNewWalletModal = (v: boolean) => useStore.setState({ walletModalActive: v })
  const newWalletModal = useStore((s: Store) => s.walletModalActive)
  const chainName = useStore((s: Store) => s.chain)
  const chain = config.chains[chainName]

  const CreateWalletButton = () => (
    <Button variant="success" round onClick={() => setNewWalletModal(!newWalletModal)}>
      Create Account <IoIosAdd size={18} />
    </Button>
  )

  const { data, isLoading } = useQuery({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
    retry,
  })

  // const { data: nfts = [] } = useQuery({
  //   queryFn: async (): Promise<IWorkspaceWalletNFTs[]> =>
  //     fetcher("get", `/workspaces/${params.workspaceId}/wallets/nfts`),
  //   queryKey: [params.workspaceId, "wallets", "nfts"],
  //   retry,
  // })
  const nfts = []

  const { data: tokens = [] } = useQuery<IToken[], AxiosError>({
    queryFn: (): Promise<IToken[]> => fetcher("get", `/workspaces/${params.workspaceId}/tokens/`),
    queryKey: ["tokens"],
  })

  return (
    <div className="pane">
      <Header>
        <div className="header-name"></div>

        <div className="header-buttons">{auth.isOwnerOrAdmin && <CreateWalletButton />}</div>
      </Header>
      <div className="data-content">
        {isLoading ? (
          <Empty>
            <div style={{ marginBottom: 32 }}>Loading...</div>
          </Empty>
        ) : null}

        {data?.length === 0 ? (
          <Empty>
            <div style={{ marginBottom: 32 }}>No accounts yet</div>
            {auth.isOwnerOrAdmin && <CreateWalletButton />}
          </Empty>
        ) : (
          <div className="wallets">
            {data?.map((v: IWallet) => {
              const nfs = nfts.find((n) => n.wallet_id === v.id)?.tokens || []
              return <WalletCard key={v.id} wallet={v} tokens={tokens} nfts={nfs} chain={chain} />
            })}
            <div className="add-button">{auth.isOwnerOrAdmin && <CreateWalletButton />}</div>
          </div>
        )}
      </div>
    </div>
  )
}
