import "./Divider.sass"

export function Divider(props: any) {
  return (
    <div className="divider">
      <div className="left" />
      {props.children && <div className="center">{props.children}</div>}
      <div className="right" />
    </div>
  )
}
