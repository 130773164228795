import "./Addr.sass"
import clsx from "clsx"
import * as ethers from "ethers"
import { Text } from "./Text"
import { useEffect, useState } from "react"
import { useStore } from "../lib/store"
import config from "../lib/config"

interface AddrProps extends React.HTMLAttributes<HTMLSpanElement> {
  resolveENS?: boolean
  fullDetails?: boolean
}

export const shortAddr = (addr: string, addPrefix = true) => {
  const address = ethers.getAddress(addr).replace("0x", "")
  const prefix = address.slice(0, 4)
  const suffix = address.slice(address?.length - 4, address?.length)
  return `${addPrefix ? "0x" : ""}${prefix}...${suffix}`
}

export function Addr({ children, className, resolveENS, fullDetails, ...props }: AddrProps) {
  const base = children?.toString()
  const [ens, setEns] = useState<string | null>(null)
  const chain = useStore((state) => state.chain)

  useEffect(() => {
    if (resolveENS) {
      const provider = config.chains[chain].provider
      if (!base) return
      provider
        ?.lookupAddress(base)
        .then((ens) => {
          setEns(ens)
        })
        .catch(() => null) // ignore errors
    }
  }, [resolveENS, chain])
  if (!base) return <div className={clsx("address-component", className)}>(None)</div>

  return (
    <Text className={clsx("address-component", className)} {...props}>
      {fullDetails ? "" : "0x"}
      <div className="inner" title={base}>
        {fullDetails ? base : shortAddr(base, false)} {ens ? <div className="ens">{ens}</div> : null}
      </div>
    </Text>
  )
}
