import "./CodeDemo.sass"
import { useState } from "react"
import { Light as SyntaxHighlighter } from "react-syntax-highlighter"
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript"
import dracula from "react-syntax-highlighter/dist/esm/styles/hljs/dracula"
import { Button } from "./components/Button"
import { Text } from "./components/Text"
import config from "./lib/config"
import { useStore } from "./lib/store"

SyntaxHighlighter.registerLanguage("javascript", js)

export function CodeDemo() {
  const [tab, setTab] = useState<number>(0)
  const chain = useStore((s) => s.chain)
  const chainId = config.chains[chain].chainId

  const tabs = ["Ethers.js", "Golang"]

  const ethers_demo = `
  const ethers = require("ethers");

  const apiKey = "<API_KEY_HERE>";

  const provider = new ethers.providers.JsonRpcProvider(
    "${config.rpcEndpoint}?api_key="+ apiKey + "&chain_id=${chainId}"
  );

  const signer = provider.getSigner();

  const data = {
    to: "0x<ADDRESS>",
    value: ethers.utils.parseEther("0.1").toHexString(),
    gasPrice: 136026713419,
  };

  const tx = await signer.sendTransaction(data);
  console.log(tx);

`

  const golang_demo = `
  package main

  import (
    "context"
    "fmt"

    "github.com/ethereum/go-ethereum/common/hexutil"
    rpc "github.com/ethereum/go-ethereum/rpc"
  )

  var apiKey = "<API_KEY_HERE>"

  var endpoint = "${config.rpcEndpoint}?api_key=" + apiKey + "&chain_id=${chainId}"

  func main() {

    rpcClient, err := rpc.DialContext(context.Background(), endpoint)
    if err != nil {
      panic(err)
    }

    var res1 []string
    err = rpcClient.Call(&res1, "eth_accounts")
    fmt.Println(err, res1)

    var res2 interface{}
    err = rpcClient.Call(&res2, "eth_sign", res1[0], hexutil.Encode([]byte("test")))
    fmt.Println(err, res2)

  }

`

  const examples = [ethers_demo, golang_demo]

  return (
    <div className="code-demo">
      <div className="content api-keys-example">
        <Text className="title" bold>
          Example Usage
        </Text>

        <div className="tabs">
          {tabs.map((v: string, i: number) => {
            return (
              <Button key={i} onClick={() => setTab(i)} variant={tab === i ? "success" : undefined}>
                {v}
              </Button>
            )
          })}
        </div>

        <SyntaxHighlighter language="javascript" style={dracula}>
          {examples[tab]}
        </SyntaxHighlighter>
      </div>
    </div>
  )
}
