import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { v4 as uuidV4 } from "uuid"
import {
  IUser,
  IWallet,
  IWalletAlert,
  IWalletAlertItem,
  IWalletAlertItemDestination,
  PlanFree,
} from "../types"
import { fetcher, parseAxiosError, retry } from "../lib/fetcher"
import { Text } from "./Text"
import { TextInput } from "./TextInput"
import { useState } from "react"
import { Button } from "./Button"
import Select, { MultiValue, SingleValue } from "react-select"
import CreatableSelect from "react-select/creatable"
import { SelectOpt, selectStyles } from "./Select"
import { IoAddOutline } from "react-icons/io5"
import { AiOutlineDelete } from "react-icons/ai"
import "./RoleEditor.sass"
import config from "../lib/config"
import { useAuth } from "../hooks/useAuth"
import { AxiosError } from "axios"

type ItemOption = Partial<IWalletAlertItem & { key: string; valid: boolean }>

const customSelectStyles = {
  ...selectStyles,

  control: (base: any) => ({
    ...base,
    border: "1px solid #C5C4C4",
    padding: 4,
  }),
}

const alertItemTypes = [
  {
    label: "Balance",
    value: "balance",
  },
]
export const alertItemComparators = [
  {
    label: "Greater than",
    value: "gt",
  },
  {
    label: "Less than",
    value: "lt",
  },
]

const defaultAlertItem = (): ItemOption => ({
  type: "",
  comparator: "",
  value: "",
  key: uuidV4(),
  valid: false,
})

const checkValid = (item: ItemOption): boolean => {
  return !!(item?.type && item?.comparator && item?.value)
}

export function AlertEditor() {
  const params = useParams()
  const navigate = useNavigate()
  const { workspace } = useAuth()
  const mainnetEnabled = workspace?.billing_plan.name !== PlanFree

  const [alertName, setAlertName] = useState<string>("")
  const [chainId, setChainId] = useState<string>(
    mainnetEnabled ? String(config.chains.ethereum.chainId) : String(config.chains.sepolia.chainId)
  )
  const [destinations, setDestinations] = useState<IWalletAlertItemDestination[]>([])
  const initialAlertItem = defaultAlertItem()
  const [items, setItems] = useState<ItemOption[]>([initialAlertItem])
  const [wallet, setWallet] = useState<IWallet | null>(null)
  const [activeItem, setActiveItem] = useState<ItemOption>(initialAlertItem)

  const chainOptions: { label: string; value: string; enabled: boolean }[] = [
    {
      label: `${config.chains.ethereum.name} ${mainnetEnabled ? "" : "(Upgrade to the Team plan)"}`,
      value: String(config.chains.ethereum.chainId),
      enabled: mainnetEnabled,
    },
    {
      label: `${config.chains.polygon.name} ${mainnetEnabled ? "" : "(Upgrade to the Team plan)"}`,
      value: String(config.chains.polygon.chainId),
      enabled: mainnetEnabled,
    },
    {
      label: `${config.chains.arbitrum.name} ${mainnetEnabled ? "" : "(Upgrade to the Team plan)"}`,
      value: String(config.chains.arbitrum.chainId),
      enabled: mainnetEnabled,
    },
    // testnets
    {
      label: config.chains.sepolia.name,
      value: String(config.chains.sepolia.chainId),
      enabled: true,
    },
    {
      label: config.chains.polygon_amoy.name,
      value: String(config.chains.polygon_amoy.chainId),
      enabled: true,
    },
    {
      label: config.chains.base_sepolia.name,
      value: String(config.chains.base_sepolia.chainId),
      enabled: true,
    },
  ]

  const {
    data: wallets = [],
    isLoading: isLoadingWallets,
    refetch: refetchWallets,
  } = useQuery({
    queryFn: (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    retry,
    queryKey: [params.workspaceId, "wallets"],
  })

  const { refetch } = useQuery({
    queryFn: (): Promise<IWalletAlert[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/wallets/alerts/`),
    retry,
    enabled: false,
    queryKey: [params.workspaceId, "wallets", "alerts"],
  })

  const {
    data: users = [],
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
    queryKey: [params.workspaceId, "users"],
  })

  const {
    mutate: saveAlert,
    error: createAlertError,
    isLoading,
  } = useMutation({
    mutationFn: (attrs: {
      name: string
      destinations: IWalletAlertItemDestination[]
      wallet_id: string
      chain_id: number
      items: Partial<IWalletAlertItem>[]
    }): Promise<IWalletAlert> => fetcher("post", `/workspaces/${params.workspaceId}/wallets/alerts/`, attrs),
    onSuccess: (data) => {
      refetch()
      // navigate(`/dashboard/workspaces/${params.workspaceId}/alerts/${data.id}`)
      navigate(`../${data.id}`)
    },
  })

  const onWalletChange = (e: SingleValue<SelectOpt>) => {
    if (!e) return
    const w = wallets.find((v) => v.id === e.value)
    if (!w) return
    setWallet(w)
  }
  const onChainChange = (e: SingleValue<SelectOpt>) => {
    if (!e) return
    setChainId(e.value)
  }

  const onRecipientChange = (e: MultiValue<SelectOpt>) => {
    setDestinations(e?.map((v) => ({ email: v.value })))
  }

  const parseEmailOption = (label: string) => {
    if (!label.match(/^\S+@\S+\.\S+$/)?.length) return

    setDestinations((prev) => [...prev, { email: label }])

    return {
      label,
      value: label,
    }
  }

  const deletePermission = (item: ItemOption) => {
    setItems(items.filter((p) => p.key !== item.key))
  }

  const addAlertItem = () => {
    setItems([...items, defaultAlertItem()])
  }

  const updateItem = (key: string, update: Partial<IWalletAlertItem>) => {
    setItems((prev) =>
      prev.map((v) => (v.key === key ? { ...v, ...update, valid: checkValid({ ...v, ...update }) } : v))
    )
  }

  const save = () => {
    if (!wallet) return
    saveAlert({ name: alertName, destinations, wallet_id: wallet?.id, chain_id: parseInt(chainId), items })
  }

  const recipientOptions = users?.map((v) => ({ label: `${v.name} (${v.email})`, value: v.email })) || []

  const isValid =
    alertName.length > 0 &&
    destinations.length > 0 &&
    wallet?.id &&
    items.length > 0 &&
    items.every((v) => v.valid)

  return (
    <div className="multi-editor">
      <div className="row">
        <Text subtitle>Alert Name</Text>
      </div>
      <div className="row">
        <TextInput
          placeholder="Alert Name (Low wallet balance alert)"
          value={alertName}
          onChange={(e) => setAlertName(e.target.value)}
        />
      </div>

      <div className="row">
        <Text subtitle>Wallet</Text>
      </div>
      <div className="row select">
        <Select
          className="react-select"
          placeholder="Wallet"
          defaultValue={null}
          value={wallet ? { label: wallet.name, value: wallet.id } : null}
          options={wallets?.map((v) => ({ label: v.name, value: v.id })) || []}
          menuPortalTarget={document.body}
          styles={customSelectStyles}
          onChange={onWalletChange}
          isSearchable
        />
      </div>

      <div className="row">
        <Text subtitle>Chain</Text>
      </div>
      <div className="row select">
        <Select
          className="react-select"
          placeholder="Chain"
          defaultValue={null}
          value={chainOptions.find((v) => v?.value === chainId)}
          options={chainOptions}
          menuPortalTarget={document.body}
          isOptionDisabled={(option) => !option.enabled}
          styles={customSelectStyles}
          onChange={onChainChange}
          isSearchable
        />
      </div>

      <div className="row">
        <Text subtitle>Recipients</Text>
      </div>
      <div className="row select">
        <CreatableSelect
          className="react-select"
          placeholder="Recipients"
          options={recipientOptions}
          styles={customSelectStyles}
          onCreateOption={parseEmailOption}
          value={destinations.map((v) => ({ label: v.email, value: v.email }))}
          onChange={onRecipientChange}
          isMulti
          isSearchable
        />
      </div>

      <div className="items">
        {items.map((item) => {
          const onAlertTypeChange = (e: SingleValue<SelectOpt>) => {
            if (!e || !item.key) return
            updateItem(item.key, { type: e.value })
          }

          const onAlertComparatorChange = (e: SingleValue<SelectOpt>) => {
            if (!e || !item.key) return
            updateItem(item.key, { comparator: e.value })
          }

          const alertType = alertItemTypes.find((v) => v.value === item.type)
          const alertComparator = alertItemComparators.find((v) => v.value === item.comparator)
          return (
            <div
              className="item"
              key={item.key}
              style={{
                zIndex: activeItem.key === item.key ? 1 : 0,
              }}
            >
              <div className="title">
                <Text subtitle>Trigger</Text>
                <Button
                  plain
                  className="circle-button small"
                  variant="error"
                  ghost
                  size="small"
                  onClick={() => deletePermission(item)}
                >
                  <AiOutlineDelete size={20} />
                </Button>
              </div>

              <div className="row select">
                <Select
                  className="react-select"
                  placeholder="Trigger Type"
                  defaultValue={alertType}
                  options={alertItemTypes}
                  menuPortalTarget={document.body}
                  styles={customSelectStyles}
                  onMenuOpen={() => setActiveItem(item)}
                  onChange={onAlertTypeChange}
                  isSearchable={false}
                />
              </div>
              {item.type ? (
                <div className="row select">
                  <Select
                    className="react-select"
                    placeholder="Condition"
                    defaultValue={alertComparator}
                    options={alertItemComparators}
                    menuPortalTarget={document.body}
                    styles={customSelectStyles}
                    onMenuOpen={() => setActiveItem(item)}
                    onChange={onAlertComparatorChange}
                    isSearchable={false}
                  />
                </div>
              ) : null}
              {item.comparator ? (
                <TextInput
                  placeholder="Value (in token. 1 ETH = 1, 1 MATIC = 1)"
                  value={item.value}
                  onChange={(e) => updateItem(item.key!, { value: e.target.value })}
                />
              ) : null}
            </div>
          )
        })}

        {/* <div>
          <Button
            title="Add alert item"
            className="circle-button"
            round
            variant="success"
            onClick={addAlertItem}
          >
            <IoAddOutline size={20} />
          </Button>
        </div> */}
      </div>

      <div className="editor-footer">
        <Text variant="error">
          {createAlertError ? String(parseAxiosError(createAlertError as AxiosError)) : null}
        </Text>
        <Button variant="success" disabled={!isValid || isLoading} round onClick={() => save()}>
          Save
        </Button>
      </div>
    </div>
  )
}
