import { AssignWalletModal } from "./AssignWalletModal"
import "./Wallet.sass"
import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { ethers } from "ethers"
import { useState } from "react"
import { BiRightArrowAlt } from "react-icons/bi"
import { BsChevronRight } from "react-icons/bs"
import { IoAddOutline, IoKeyOutline } from "react-icons/io5"
import { MdContentCopy, MdOutlineQrCodeScanner } from "react-icons/md"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Addr } from "./components/Addr"
import { Button } from "./components/Button"
import { Card } from "./components/Card"
import { Empty } from "./components/Empty"
import { Header } from "./components/Header"
import { Tag } from "./components/Tag"
import { Text } from "./components/Text"
import { Token } from "./components/Token"
import { useTokens } from "./hooks/useTokens"
import { useTokenFiatBalances, useWalletBalance } from "./hooks/useWalletBalance"
import config from "./lib/config"
import { fetcher } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { formatFloat } from "./lib/utils"
import {
  IAccount,
  IToken,
  IWallet,
  IWalletRole,
  IWalletRoleApiKey,
  IWalletRoleUser,
  IWalletTransaction,
  IWalletTransactionApprover,
} from "./types"
import { Timeago } from "./lib/timeago"
import { FiExternalLink, FiUser } from "react-icons/fi"
import { useAuth } from "./hooks/useAuth"
import { Modal } from "./components/Modal"

export const ROOT_DERIVATION_PATH = "m/44'/60'/0'/0"

export const getDefaultAccount = (v?: IWallet): IAccount | undefined => {
  if (!v) return
  // imported accounts do not have a derivation path
  return v?.accounts.find((v: IAccount) => v.path === undefined || v.path === `${ROOT_DERIVATION_PATH}/0`)
}

const REFETCH_INTERVAL = 30 * 1000 // 30 seconds

export interface WalletProps {}
export function Wallet(props: WalletProps) {
  const params = useParams<{ workspaceId: string; roleId: string; id: string }>()
  const navigate = useNavigate()
  const chainName = useStore((s: Store) => s.chain)
  const chain = config.chains[chainName]
  const auth = useAuth()
  const [addressCopied, setAddressCopied] = useState<boolean>(false)
  const [expandedTransactions, setExpandedTransactions] = useState<string[]>([])
  // const [showQrCode, setShowQrCode] = useState<boolean>(false)

  const toggleTransactionDetails = (tx: IWalletTransaction) => {
    if (expandedTransactions.includes(tx.id)) {
      return setExpandedTransactions(expandedTransactions.filter((v) => v !== tx.id))
    }
    setExpandedTransactions([...expandedTransactions, tx.id])
  }

  const {
    data: wallet,
    refetch: refetchWallet,
    isLoading,
    isError,
  } = useQuery({
    queryFn: (): Promise<IWallet> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/${params.id}`),
    queryKey: [params.workspaceId, "wallets", params.id],
    onError: (error: AxiosError) => {
      if (error.response?.status) {
        return navigate("/dashboard")
      }
    },
    refetchInterval: REFETCH_INTERVAL,
  })

  const {
    data: transactions = [],
    refetch: refetchTransactions,
    isLoading: isLoadingTransactions,
    isError: isErrorTransactions,
  } = useQuery({
    queryFn: (): Promise<IWalletTransaction[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/wallets/${params.id}/transactions`),
    queryKey: [params.workspaceId, "wallets", params.id, "transactions"],
    refetchInterval: REFETCH_INTERVAL,
  })

  const { data: tokens = [] } = useTokens()

  const { data: fiatData } = useTokenFiatBalances(tokens)

  const { data: walletValueData, isLoading: isLoadingWalletValue } = useWalletBalance(
    tokens,
    fiatData,
    wallet
  )

  const tokenPairs = tokens
    .map((token) => {
      const { value, balance } = walletValueData.tokens?.find((v) => v.id === token.id) || {
        value: "0",
        balance: "0",
      }
      return {
        token,
        value,
        balance,
      }
    })
    // always show eth and filter out the zero balance tokens
    .filter((v) => (v.token.chain_id === 1 && v.token.address === "") || v.balance !== "0")

  const rootEthAddress = getDefaultAccount(wallet)?.address

  const refetchAll = () => {
    refetchWallet()
    refetchTransactions()
  }

  const copyAddress = () => {
    if (!rootEthAddress) return
    setAddressCopied(true)
    navigator.clipboard.writeText(rootEthAddress)
    setTimeout(() => {
      setAddressCopied(false)
    }, 2000)
  }

  return (
    <div className="page wallet">
      <div className="pane">
        {isLoading ? (
          <Empty />
        ) : isError ? (
          "error"
        ) : !wallet?.id ? (
          <Empty>"bad request"</Empty>
        ) : (
          <div>
            <Header>
              <div className="header-name">
                <Text bold>{wallet.name}</Text>
                <Tag>
                  <Addr resolveENS onClick={copyAddress}>
                    {wallet.accounts[0].address}
                  </Addr>
                </Tag>

                {wallet.imported ? (
                  <Tag variant="warning">
                    <Text subtitle size="small" title="This account was created externally and imported">
                      Imported
                    </Text>
                  </Tag>
                ) : null}

                <Button className="addr-button" onClick={copyAddress}>
                  <MdContentCopy size={18} />
                </Button>

                {/*

                <Button className="addr-button" onClick={() => setShowQrCode(true)}>
                  <MdOutlineQrCodeScanner size={18} />
                </Button>
                */}
                {/* <a href={`${chain.blockExplorer}/address/${rootEthAddress}`} target="_blank" rel="noreferrer">
                  <Button className="addr-button" title="View on Etherscan.io">
                    <img
                      className="etherscan-logo"
                      alt="etherscan.io"
                      src="https://etherscan.io/images/brandassets/etherscan-logo-circle.png"
                    />
                  </Button>
                </a> */}

                {addressCopied ? "Copied!" : null}
              </div>

              <div className="header-buttons">
                <Button
                  uppercase
                  round
                  variant="success"
                  onClick={() => useStore.setState({ transactModalWallet: wallet })}
                >
                  Transact
                  <BiRightArrowAlt size={18} />
                </Button>
                <Button
                  uppercase
                  round
                  ghost
                  variant="success"
                  onClick={() => useStore.setState({ walletConnectModalActive: true })}
                >
                  wallet connect
                  <img src="/wallet-connect.svg" />
                </Button>
              </div>
            </Header>

            <div className="data-content">
              <div className="input-row">
                <div className="address">
                  <div className="card-body">
                    <div className="data-row">
                      <Text size="xlarge">
                        $ {isLoadingWalletValue ? "Loading..." : formatFloat(walletValueData.total || "")}
                      </Text>
                    </div>
                  </div>
                </div>
                <Text subtitle>Balances</Text>
                <div className="tokens">
                  {tokenPairs.map(
                    ({ token, value, balance }: { token: IToken; value: string; balance: string }) => {
                      return (
                        <Token
                          key={token.id}
                          address={wallet.accounts[0].address}
                          balance={balance}
                          value={value}
                          token={token}
                          showNetwork
                        />
                      )
                    }
                  )}

                  <div>
                    {auth.isOwnerOrAdmin ? (
                      <Button
                        size="small"
                        variant="secondary"
                        round
                        className="add-token"
                        onClick={() => navigate(`/dashboard/workspaces/${params.workspaceId}/tokens`)}
                      >
                        Manage Tokens
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <Header>
              <Text bold>Roles</Text>

              {auth.isOwnerOrAdmin ? (
                <Button
                  uppercase
                  round
                  variant="success"
                  onClick={() => useStore.setState({ assignWalletModalActive: true })}
                >
                  Assign User <IoAddOutline size={20} />
                </Button>
              ) : null}
            </Header>
            <div className="data-content roles">
              {wallet.roles?.length === 0 ? (
                <Empty>
                  <div>No roles assigned</div>
                  <br />

                  {auth.isOwnerOrAdmin ? (
                    <Button
                      uppercase
                      round
                      variant="success"
                      onClick={() => useStore.setState({ assignWalletModalActive: true })}
                    >
                      Assign User <IoAddOutline size={20} />
                    </Button>
                  ) : null}
                </Empty>
              ) : (
                wallet.roles?.map((v: IWalletRole) => (
                  <Link key={v.id} to={`../permissions/${v.id}`}>
                    <Card key={v.id}>
                      <div className="card-header">
                        <Text bold>{v.name}</Text>
                      </div>

                      {v.users.length || v.api_keys.length ? (
                        <Text subtitle size="small">
                          Assigned users/API keys:
                        </Text>
                      ) : null}
                      <div className="card-body">
                        {v.users.length ? (
                          <div className="column">
                            {v.users?.map((v: IWalletRoleUser) => (
                              <Text size="small" key={v.user.id}>
                                <FiUser size={12} />
                                {v.user.name}
                              </Text>
                            ))}
                          </div>
                        ) : null}
                        {v.api_keys.length ? (
                          <div className="column">
                            {v.api_keys.map((v: IWalletRoleApiKey) => (
                              <Text size="small" key={v.api_key.id}>
                                <IoKeyOutline size={12} />
                                {v.api_key.name}
                              </Text>
                            ))}
                          </div>
                        ) : null}
                      </div>
                      <div className="card-footer">
                        <div />
                        <Button plain>
                          View
                          <BsChevronRight />
                        </Button>
                      </div>
                    </Card>
                  </Link>
                ))
              )}
            </div>
            <Header>
              <Text bold>History</Text>
            </Header>
            <div className="transactions rows">
              {transactions.length ? (
                <>
                  <div className="transaction row">
                    <Text bold>Status</Text>
                    <Text bold>Chain</Text>
                    <Text bold>Method</Text>
                    <Text bold>To</Text>
                    <Text bold>Value</Text>
                    <Text bold>Date</Text>
                    <Text bold>Result</Text>
                  </div>
                  {transactions.map((v: IWalletTransaction) => {
                    const expanded = expandedTransactions.includes(v.id)
                    const txChain = Object.values(config.chains).find((c) => c.chainId === v.tx_data.ChainID)
                    return (
                      <div key={v.id} className="transaction">
                        <div className="transaction row">
                          <div style={{ display: "flex" }}>
                            <Tag variant={(v.status === "ERROR" && "error") || undefined}>{v.status}</Tag>
                          </div>
                          <Text>{txChain?.name || "(None)"}</Text>
                          <Text>{v.method}</Text>
                          <Text>
                            {v.tx_data.To !== null ? (
                              <a
                                href={`${chain.blockExplorer}/address/${v.tx_data.To}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div style={{ display: "flex" }}>
                                  <Addr>{v.tx_data.To}</Addr> <FiExternalLink />
                                </div>
                              </a>
                            ) : (
                              "(None)"
                            )}
                          </Text>
                          {v.tx_data.Value
                            ? ethers.formatEther(String(v.tx_data.Value)).toString()
                            : "(none)"}{" "}
                          eth <Text title={v.created_at}>{Timeago(new Date(v.created_at))}</Text>
                          {v.result?.result && v.method === "eth_sendTransaction" && txChain ? (
                            <a
                              href={`${txChain.blockExplorer}/tx/${v.result.result}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button size="small" plain>
                                View on Block Explorer <FiExternalLink />
                              </Button>
                            </a>
                          ) : (
                            <Text>(None)</Text>
                          )}
                          {/*
                      <div>
                        <Button size="small" onClick={() => toggleTransactionDetails(v)}>
                          {expanded ? "Close" : "Details"}
                        </Button>
                      </div>
                         */}
                        </div>
                        {expanded ? (
                          <div className="row details">
                            <div className="row">
                              Reviewed by: {v.approvers?.map((a: IWalletTransactionApprover) => a.user_id)}
                            </div>
                            {v.network_error ? <div>Network Error: {v.network_error}</div> : null}
                            {v.internal_error ? <div>Error: {v.internal_error}</div> : null}
                          </div>
                        ) : null}
                      </div>
                    )
                  })}
                </>
              ) : (
                <Empty>
                  <Text>No History yet</Text>
                </Empty>
              )}
            </div>
          </div>
        )}
      </div>
      <AssignWalletModal walletId={wallet?.id} key={`${wallet?.id}-awm`} onUpdate={refetchAll} />

      {/* <Modal
        isOpen={showQrCode}
        key={`${wallet?.id}-qrc`}
        className="qrcode-modal"
        onRequestClose={() => setShowQrCode(false)}
        title="Receive Crypto"
      ></Modal> */}
    </div>
  )
}
