import "./AssignRoleModal.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useState } from "react"
import { useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import { Button } from "./components/Button"
import { Empty } from "./components/Empty"
import { Modal, ModalFooter } from "./components/Modal"
import { SelectOpt } from "./components/Select"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { IUser, IWallet, IWalletRole } from "./types"

type assignRoleMutationAttrs = {
  user_id?: string
  wallet_id?: string
}

interface AssignWalletModalProps {
  walletId?: string
  onUpdate: () => void
}

export function AssignWalletModal(props: AssignWalletModalProps) {
  const params = useParams<{ workspaceId: string; walletId: string }>()

  const [selectedUser, setSelectedUser] = useState<IUser | undefined>()
  const [selectedRole, setSelectedRole] = useState<IWalletRole | undefined>()
  const [error, setError] = useState<Error | undefined>()

  const setRoleModal = (v: boolean) => useStore.setState({ assignWalletModalActive: v })
  const assignWalletModalActive = useStore((s: Store) => s.assignWalletModalActive)

  const {
    data: selectedWallet,
    isLoading: isLoadingSelectedWallet,
    refetch: refetchSelectedWallet,
  } = useQuery<IWallet, AxiosError>({
    queryKey: [params.workspaceId, "wallets", props.walletId],
    queryFn: async (): Promise<IWallet> =>
      fetcher("get", `/workspaces/${params.workspaceId}/wallets/${props.walletId}`),

    enabled: !!props?.walletId,
  })

  const {
    data: roles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useQuery<IWalletRole[], AxiosError>({
    queryKey: [params.workspaceId, "roles"],
    queryFn: async (): Promise<IWalletRole[]> => fetcher("get", `/workspaces/${params.workspaceId}/roles/`),
  })

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery<IUser[], AxiosError>({
    queryKey: [params.workspaceId, "users"],
    queryFn: async (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
  })

  const { mutate: assignRoleMutation, isLoading: isLoadingSave } = useMutation<
    IWalletRole,
    AxiosError,
    assignRoleMutationAttrs
  >({
    mutationFn: (attrs: assignRoleMutationAttrs) =>
      fetcher("post", `/workspaces/${params.workspaceId}/roles/${selectedRole?.id}/assign`, attrs),
    onSuccess: () => {
      closeModal()
      refetchAll()
      props.onUpdate()
    },
    onError: (err) => {
      if (String(err.response?.data).includes("already assigned")) {
        if (selectedUser !== undefined) {
          setError(
            new Error(`${selectedUser.name} is already assigned to the ${selectedWallet?.name} wallet`)
          )
          return
        }
      }
    },
  })

  if (isLoadingSelectedWallet) return <Spinner />
  if (!selectedWallet) return <Empty>Not Found</Empty>

  const refetchAll = () => {
    refetchSelectedWallet()
    refetchRoles()
    refetchUsers()
  }

  const saveRole = () => {
    assignRoleMutation({
      user_id: selectedUser?.id,
      wallet_id: props.walletId,
    })
  }

  const closeModal = () => {
    setSelectedUser(undefined)
    setError(undefined)
    setRoleModal(false)
  }

  return (
    <Modal
      isOpen={assignWalletModalActive}
      onRequestClose={closeModal}
      className="assign-role-modal"
      title="Assign Role"
      footer={
        <ModalFooter>
          <>
            <Button
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>

            <Button
              variant="success"
              disabled={
                !selectedUser ||
                !selectedRole ||
                isLoadingSelectedWallet ||
                isLoadingRoles ||
                isLoadingUsers ||
                isLoadingSave
              }
              onClick={() => {
                saveRole()
              }}
            >
              Assign
            </Button>
          </>
        </ModalFooter>
      }
    >
      <div className="row">
        <Text subtitle>Wallet: </Text>
        <Text bold>{selectedWallet.name}</Text>
      </div>
      <div className="row">
        <Text subtitle>Assign To:</Text>
      </div>

      <div className="content-box row select" style={{ zIndex: 3 }}>
        <Select
          className="react-select"
          placeholder="Select User"
          onChange={(v: SingleValue<SelectOpt>) => {
            setSelectedUser(users?.find((u: IUser) => u.id === v?.value))
          }}
          options={users?.map((v: IUser) => {
            return {
              value: v.id,
              label: `${v.name} (${v.email}) [${v.role?.role}]`,
            }
          })}
        />
      </div>

      <div className="row">
        <Text subtitle>Assign To Role:</Text>
      </div>

      <div className="content-box row select">
        <Select
          className="react-select"
          placeholder="Select Role"
          onChange={(v: SingleValue<SelectOpt>) => {
            setSelectedRole(roles?.find((u: IWalletRole) => u.id === v?.value))
          }}
          options={roles?.map((v: IWalletRole) => {
            return {
              value: v.id,
              label: v.name,
            }
          })}
        />
      </div>

      {selectedUser && selectedWallet && (
        <div className="content-box">
          <div className="row">
            This gives the selected <Text bold>user full use of this role</Text> on the{" "}
            <Text bold>{selectedWallet?.name}</Text> wallet.
          </div>
        </div>
      )}
      {error && <div className="content-box error-message">{error.message}</div>}
    </Modal>
  )
}
