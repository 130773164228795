import "./Spinner.sass"

export function Spinner() {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
