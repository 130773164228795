import clsx from "clsx"
import { formatFloat } from "../lib/utils"
import { IToken } from "../types"
import { FiExternalLink } from "react-icons/fi"
import "./Token.sass"
import config from "../lib/config"
import { Text } from "./Text"
import { Addr } from "./Addr"

interface TokenProps extends React.HTMLAttributes<HTMLDivElement> {
  token: IToken
  address?: string
  balance?: string
  value?: string
  showNetwork?: boolean
  showAddress?: boolean
}
export function Token({
  className,
  address,
  token,
  balance,
  value,
  showNetwork,
  showAddress,
  ...props
}: TokenProps) {
  // fetch the chain-native balance (eth on ethereum, sepolia on sepolia, etc)

  const chain = Object.values(config.chains).find((c) => c.chainId === token.chain_id)

  return (
    <div className={clsx("token-component", className)} key={token.id} {...props}>
      <div className="name">
        <div className="logo">{token.logo_url ? <img src={token.logo_url} alt={token.name} /> : null}</div>
        {token.name} ({token.symbol})
      </div>
      {showNetwork || showAddress ? (
        <div className="details">
          {showNetwork ? (
            <>
              {address ? (
                <a
                  className="explorer"
                  href={`${chain?.blockExplorer}/address/${address}`}
                  target="_blank"
                  title="View on explorer"
                  rel="noreferrer"
                >
                  <Text size="small">{chain?.name}</Text>
                  <FiExternalLink size={18} />
                </a>
              ) : (
                <Text size="small">{chain?.name}</Text>
              )}
            </>
          ) : null}
          {showAddress && token.address ? (
            <a
              className="explorer"
              href={`${chain?.blockExplorer}/address/${token.address}`}
              target="_blank"
              title={`View ${token.name} on explorer`}
              rel="noreferrer"
            >
              <Addr fullDetails>{token.address}</Addr>
            </a>
          ) : null}
        </div>
      ) : null}

      {balance ? (
        <div className="balance">
          <span className="amount" title={balance}>
            {formatFloat(balance, 3)}
          </span>
          <span className="value" title={`$${value}`}>
            {" "}
            (${value})
          </span>
        </div>
      ) : null}
    </div>
  )
}
