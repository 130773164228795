import { useQuery } from "@tanstack/react-query"
import { JsonRpcPayload, JsonRpcResult } from "@walletconnect/jsonrpc-types"
import axios, { AxiosError } from "axios"
import { ethers } from "ethers"
import { fetcher } from "../lib/fetcher"
import { FiatData, IToken, IWallet } from "../types"
import { formatFloat } from "../lib/utils"

const REFETCH_INTERVAL = 5 * 60 * 1000 // 5 minutes
const STALE_TIME = 60 * 1000 // 60 seconds

export const useTokenFiatBalances = (tokens: IToken[]) => {
  const tokenIds = tokens
    .filter((token) => !!token.coingecko_id)
    .map((token) => token.coingecko_id)
    .join(",")

  return useQuery<FiatData, AxiosError>({
    queryFn: () =>
      axios
        .get(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenIds}&vs_currencies=usd`)
        .then((res) => res.data),
    queryKey: ["token-price", tokenIds],
    refetchInterval: REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    enabled: !!tokens.length,
    staleTime: STALE_TIME,
  })
}

export const useWalletBalance = (tokens: IToken[], fiatData?: FiatData, wallet?: IWallet | null) => {
  const address = wallet?.accounts?.[0].address
  const url = `/workspaces/${wallet?.workspace_id}/wallets/${wallet?.id}/rpc`

  const { data, isLoading, error } = useQuery({
    queryFn: () =>
      Promise.all(
        tokens.map(async (token, i) => {
          if (token.address) {
            const { result } = await fetcher<JsonRpcPayload, JsonRpcResult>(
              "post",
              `${url}/${token.chain_id}`,
              {
                id: i,
                jsonrpc: "2.0",
                method: "eth_call",
                params: [
                  {
                    to: token.address,
                    data: `0x70a08231000000000000000000000000${address?.slice(2)}`,
                  },
                  "latest",
                ],
              }
            )

            if (result === "0x" || result === undefined) {
              return {
                balance: "",
                value: "",
              }
            }

            const balance = formatFloat(ethers.formatUnits(result, token.decimals), 4)
            const value = fiatData
              ? ((fiatData[token.coingecko_id]?.usd || 0) * parseFloat(balance) || 0).toFixed(2)
              : ""

            return {
              id: token.id,
              balance,
              value,
            }
          }

          const { result } = await fetcher<JsonRpcPayload, JsonRpcResult>(
            "post",
            `${url}/${token.chain_id}`,
            {
              id: 0,
              jsonrpc: "2.0",
              method: "eth_getBalance",
              params: [address, "latest"],
            }
          )

          if (result === "0x" || result === undefined) {
            return {
              balance: "",
              value: "",
            }
          }

          const balance = formatFloat(ethers.formatEther(result), 4)
          const value = fiatData
            ? ((fiatData[token.coingecko_id]?.usd || 0) * parseFloat(balance) || 0).toFixed(2)
            : ""

          return {
            id: token.id,
            balance,
            value,
          }
        })
      ),
    queryKey: ["wallet-balence", wallet?.id],
    enabled: !!wallet?.accounts?.[0].address && !!fiatData,
    refetchOnWindowFocus: true,
    staleTime: STALE_TIME,
  })

  const total = data?.reduce((acc, curr) => {
    return acc + parseFloat(curr.value || "0")
  }, 0)

  return {
    data: {
      tokens: data,
      total: total ? String(total) : "0",
    },
    isLoading,
    error,
  }
}
