import "./ReviewTransactionModal.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { ethers } from "ethers"
import { useState } from "react"
import { FiExternalLink } from "react-icons/fi"
import { Link, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Divider } from "./components/Divider"
import { Modal, ModalFooter } from "./components/Modal"
import { Text } from "./components/Text"
import { useTokens } from "./hooks/useTokens"
import { useTokenFiatBalances } from "./hooks/useWalletBalance"
import config, { Chain } from "./lib/config"
import { fetcher, retry } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { parseTxDatav2, setTitle } from "./lib/utils"
import { IReviewableTransaction, IWalletRolePerm, signingMethods } from "./types"
import { FaUserLock } from "react-icons/fa"
import { Addr } from "./components/Addr"
import { Tag } from "./components/Tag"

export interface ReviewTransactionModalProps {
  isOpen: boolean
  onRequestClose: (a: boolean) => void
}

const getTitle = (tx: IReviewableTransaction, parsedTx: any) => {
  if (!tx) return "Review Transaction"
  if (signingMethods.includes(tx?.method)) {
    return "Sign Transaction"
  }
  if (parsedTx?.data?.length > 0 || parsedTx?.data?.length > 0) {
    if (parsedTx?.to?.length === 0 || parsedTx?.to?.length === 0) {
      return "Deploy Contract"
    }
  }
  return "Review Transaction"
}

export function ReviewTransactionModal() {
  const params = useParams<{ workspaceId: string; roleId: string }>()
  const [canContinue, setContinue] = useState<boolean>(true)

  const {
    data = [],
    error,
    isLoading,
    refetch,
  } = useQuery<IReviewableTransaction[], AxiosError>({
    queryKey: [params.workspaceId, "preview-transactions"],
    queryFn: async (): Promise<IReviewableTransaction[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/transactions/preview`),
    retry,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 3000,
    onSuccess: (data: IReviewableTransaction[]) => {
      if (data.length > 0) {
        useStore.setState({ transactModalWallet: null })
        setTitle(`Preview: [${data.length}] - Dashboard`)
        return
      }
      setTitle("Dashboard")
    },
  })

  const {
    mutate: previewMutation,
    isLoading: isLoadingConfirm,
    error: confirmError,
  } = useMutation<any, AxiosError, { id: string; approve: boolean }>({
    mutationFn: async (attrs: any) =>
      fetcher("post", `/workspaces/${params.workspaceId}/transactions/preview`, attrs),
    onSuccess: () => {
      refetch()
      useStore.setState({ reviewTransaction: null })
    },
    onError: console.error,
  })

  const submit = (tx: IReviewableTransaction) => {
    previewMutation({ id: tx.id, approve: true })
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setContinue(true)
  //   }, 2000)
  // }, [data.length])

  const closeModal = (tx: IReviewableTransaction) => {
    // make request to clear the pending reviewable transaction
    previewMutation({ id: tx.id, approve: false })
  }

  const latest = data[0]
  const requiresApproval = latest?.role?.permissions?.find((p: IWalletRolePerm) => p.type === "approval")

  const parsedTx = latest && parseTxDatav2(latest)
  const title = getTitle(latest, parsedTx)

  const isSigningTxn = signingMethods.includes(latest?.method)

  return (
    <Modal
      isOpen={data.length > 0}
      className="review-transaction-modal"
      title={title}
      onRequestClose={() => closeModal(latest)}
      footer={
        <ModalFooter>
          <Button
            round
            onClick={() => {
              closeModal(latest)
            }}
          >
            Cancel
          </Button>

          <Button
            variant="success"
            round
            disabled={!canContinue || isLoading || isLoadingConfirm || !!confirmError}
            onClick={() => submit(latest)}
          >
            {requiresApproval ? "Submit for Approval" : isSigningTxn ? "Sign" : "Submit"}
          </Button>
        </ModalFooter>
      }
    >
      {data.length > 0 ? (
        <>
          <RenderTransaction transaction={latest} parsedTx={parsedTx} />
          {latest?.role ? (
            <>
              <Divider />
              <div className="row">
                <Text subtitle bold>Role: </Text>
              </div>
              <div className="user-role-wrapper">
                <Link
                  to={`/dashboard/workspaces/${params.workspaceId}/permissions/${latest.role.id}`}
                  target="_blank"
                >
                  <div className="user-role">
                    <FaUserLock />
                    <Text bold>{latest.role.name}</Text>
                    <FiExternalLink />
                  </div>
                </Link>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </Modal>
  )
}

export function RenderTransaction({
  transaction,
  parsedTx,
}: {
  transaction: IReviewableTransaction
  parsedTx: any
}) {
  const { data: tokens = [] } = useTokens()

  const { data: fiatData } = useTokenFiatBalances(tokens)
  const chain = Object.values(config.chains).find((c: Chain) => c.chainId === transaction.tx_data.ChainID)

  const token = tokens.find((t) => t.address.toLowerCase() === transaction.tx_data.To?.toLowerCase())

  const getFiatValue = (transaction: IReviewableTransaction): null | number => {
    const tkn = token || tokens.find((t) => t.symbol === chain?.nickname)
    if (!tkn || !fiatData) return null
    const fiatPrice = fiatData[tkn?.coingecko_id]
    if (!fiatPrice) return null
    const quantity = ethers.formatEther(String(parsedTx?.amount || transaction.tx_data.Value))
    return Number(quantity) * fiatPrice.usd
  }

  const isSigningTxn = signingMethods.includes(transaction.method)

  return (
    <>
      {!isSigningTxn ? (
        <div className="row">
          <Text subtitle>Network: </Text>
          <Text bold>{chain?.name}</Text>
        </div>
      ) : null}

      <div className="row">
        <Text subtitle>Wallet: </Text>
        <Link to={`wallets/${transaction.wallet.id}`} target="_blank" className="wallet">
          <Text bold>{transaction.wallet.name}</Text>
          <Tag>
            <Addr>{transaction.wallet.accounts[0].address}</Addr>
          </Tag>
        </Link>
      </div>

      {!isSigningTxn ? (
        <div className="row">
          <Text subtitle>To: </Text>
          {parsedTx?.to || transaction?.tx_data?.To ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${chain?.blockExplorer}/address/${parsedTx?.to || transaction?.tx_data?.To}`}
            >
              <Text bold>
                {parsedTx?.to || transaction?.tx_data?.To} <FiExternalLink size={16} />
              </Text>
            </a>
          ) : (
            "(none)"
          )}
        </div>
      ) : null}

      {parsedTx?.to ? (
        // the transaction.to field is the token contract address when the transaction is a token transfer

        <div className="row">
          <div className="row">
            <Text subtitle>Sending Token: </Text>
            <Text bold>{token?.name || ""}</Text>
          </div>
          <Text subtitle>Contract Address: </Text>
          {transaction.tx_data?.To ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${chain?.blockExplorer}/address/${transaction.tx_data?.To}`}
            >
              <Text bold>
                {transaction?.tx_data?.To} <FiExternalLink size={16} />
              </Text>
            </a>
          ) : (
            "(none)"
          )}
        </div>
      ) : null}

      <div className="row">
        <Text subtitle>Value: </Text>
        <Text bold>
          {parsedTx?.amount !== undefined || transaction.tx_data.Value !== null ? (
            <>
              {ethers.formatEther(String(parsedTx?.amount || transaction.tx_data.Value)).toString()} ($
              {getFiatValue(transaction) || "0.00"})
            </>
          ) : (
            "(none)"
          )}
        </Text>
      </div>

      {parsedTx?.data ? (
        <>
          <div className="row">
            <Text subtitle>{isSigningTxn ? "You are signing" : "Data"}: </Text>
          </div>
          <div className="row data">
            <pre>{parsedTx?.data}</pre>
          </div>
        </>
      ) : null}

      {isSigningTxn ? null : (
        <>
          <Divider>More Details</Divider>
          <div className="details">
            <div className="column">
              <div className="row center"></div>

              <div className="row">
                <Text>Method: </Text>
                <Text bold>{transaction.method}</Text>
              </div>
              <div className="row">
                <Text>Gas: </Text>
                {transaction.revert_error ? (
                  <>
                    <div className="row">
                      <Text variant="error" bold>
                        Transaction reverted when attempting to calculate gas
                      </Text>
                    </div>
                    <div className="row">
                      <Text bold>This transaction will probably fail</Text>
                    </div>
                  </>
                ) : (
                  <Text>{transaction.tx_data.Gas ? String(transaction.tx_data.Gas) : "(none)"}</Text>
                )}
              </div>
              <div className="row">
                <Text>MaxPriorityFeePerGas: </Text>
                <Text>
                  {transaction.tx_data.GasTipCap
                    ? ethers.formatUnits(String(transaction.tx_data.GasTipCap), "gwei").toString()
                    : "(none)"}{" "}
                  gwei
                </Text>
              </div>
            </div>
            <div className="column">
              <div className="row">
                <Text>MaxFeePerGas: </Text>
                <Text>
                  {transaction.tx_data.GasFeeCap
                    ? ethers.formatUnits(String(transaction.tx_data.GasFeeCap), "gwei").toString()
                    : "(none)"}{" "}
                  gwei
                </Text>
              </div>
              <div className="row">
                <Text>Nonce: </Text>
                <Text>{transaction.tx_data.Nonce ? String(transaction.tx_data.Nonce) : "(none)"}</Text>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
