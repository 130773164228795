import "./Team.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { BsChevronExpand } from "react-icons/bs"
import { MdContentCopy } from "react-icons/md"
import { useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import { Button } from "./components/Button"
import { Modal, ModalFooter } from "./components/Modal"
import { SelectOpt, selectStyles } from "./components/Select"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import { fetcher } from "./lib/fetcher"
import { currentWorkspace, isAdmin, isOwner } from "./lib/roles"
import { Store, useStore } from "./lib/store"
import { AdminRole, IInvite, IUser, OwnerRole, PlanFree, PlanTeam, UserRole, WorkspaceRole } from "./types"
import { UpgradePlan, UpgradePlanButton } from "./components/UpgradePlan"
import { useAuth } from "./hooks/useAuth"

export interface RoleOption {
  label: string
  value: string
}
export const roleOptions = [
  {
    label: "User",
    value: UserRole,
    details: [
      {
        label: "Wallets",
        value: "Can View",
      },
      {
        label: "API Keys",
        value: "Can View",
      },
      {
        label: "Team",
        value: "Can View",
      },
      {
        label: "Alerts",
        value: "Can View",
      },
      {
        label: "Permissions",
        value: "Can View",
      },
    ],
  },
  {
    label: "Admin",
    value: AdminRole,
    details: [
      {
        label: "Wallets",
        value: "Can Manage",
      },
      {
        label: "Team",
        value: "Can Manage all except owners",
      },
      {
        label: "API Keys",
        value: "Can Manage",
      },
      {
        label: "Alerts",
        value: "Can Manage",
      },
      {
        label: "Permissions",
        value: "Can Manage",
      },
    ],
  },
  {
    label: "Owner",
    value: OwnerRole,
    details: [
      {
        label: "Wallets",
        value: "Can Manage",
      },
      {
        label: "Team",
        value: "Can Manage",
      },
      {
        label: "API Keys",
        value: "Can Manage",
      },
      {
        label: "Workspace",
        value: "Can Manage",
      },
      {
        label: "Alerts",
        value: "Can Manage",
      },
      {
        label: "Permissions",
        value: "Can Manage",
      },
    ],
  },
]

export function InviteTeamModal() {
  const params = useParams()
  const setInviteTeamModal = (v: boolean) => useStore.setState({ inviteModalActive: v })
  const inviteTeamModal = useStore((s: Store) => s.inviteModalActive)
  const [email, setEmail] = useState<string>()
  const [inviteURL, setInviteURL] = useState<string>()
  const [inviteRole, setInviteRole] = useState<WorkspaceRole>(UserRole)
  const [urlCopied, setURLCopied] = useState<boolean>(false)
  const { workspace } = useAuth()

  useEffect(() => {
    return closeModal()
  }, [])

  const { data: users = [] } = useQuery({
    queryFn: async (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
    queryKey: [params.workspaceId, "users"],
  })

  const { refetch: refetchInvites } = useQuery({
    queryFn: async (): Promise<IInvite[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/admin/invites`),
    queryKey: [params.workspaceId, "invites"],
    enabled: false,
  })

  const {
    mutate: inviteMemberMutation,
    isLoading: isLoadingInvite,
    error: inviteMemberError,
  } = useMutation<IInvite, AxiosError, { email: string; role: string }>({
    mutationFn: (attrs: any) => fetcher("post", `/workspaces/${params.workspaceId}/admin/invites`, attrs),
    networkMode: "always",
    onSuccess: (data: IInvite) => {
      refetchInvites()
      setInviteURL(`${window.location.origin}/invite/redeem?inviteId=${data.id}`)
    },
  })

  const inviteMember = () => {
    if (!email) return
    inviteMemberMutation({ email, role: inviteRole })
  }

  const closeModal = () => {
    setEmail(undefined)
    setInviteRole(UserRole)
    setInviteURL(undefined)
    setInviteTeamModal(false)
  }

  const copyURL = () => {
    if (!inviteURL) return
    setURLCopied(true)
    navigator.clipboard.writeText(inviteURL)
    setTimeout(() => {
      setURLCopied(false)
    }, 2000)
  }

  const adminDisabled =
    users.filter((v) => v.role?.role === AdminRole).length >= (workspace?.billing_plan?.admins || 0)
  const userDisabled = users.length >= (workspace?.billing_plan?.users || 0)

  const canInvite =
    (inviteRole === AdminRole && !adminDisabled) ||
    ((inviteRole === UserRole || inviteRole === OwnerRole) && !userDisabled)

  return (
    <Modal
      isOpen={inviteTeamModal}
      onRequestClose={closeModal}
      title="Invite Member"
      footer={
        <ModalFooter>
          {inviteURL ? (
            <>
              <div />
              <Button onClick={closeModal} variant="success">
                Done
              </Button>
            </>
          ) : (
            <>
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                onClick={inviteMember}
                disabled={isLoadingInvite || !email || !canInvite}
                variant="success"
              >
                Invite
              </Button>
            </>
          )}
        </ModalFooter>
      }
    >
      {inviteURL ? (
        <>
          <div className="row">
            <Text>An invite has been sent to your teammate's email</Text>
          </div>
          <div className="row">
            <Text>You can also send this link directly for them to accept:</Text>
          </div>
          <div className="row url-input">
            <TextInput key={inviteURL} defaultValue={inviteURL} readOnly />
            <Button onClick={copyURL}>
              <MdContentCopy size={22} />
            </Button>
            {urlCopied ? "Copied!" : null}
          </div>

          <div className="row">
            <Text>This link expires in 24 hours</Text>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <Text subtitle>Email</Text>
            <TextInput
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              placeholder="user@example.com"
            />
          </div>
          <div className="row">
            <Text subtitle>Role</Text>
            <Select
              className="react-select"
              options={roleOptions.filter((v) => {
                if (isOwner(workspace?.role)) return true
                if (isAdmin(workspace?.role) && v.value !== OwnerRole) return true
                return false
              })}
              value={roleOptions.find((v) => v.value === inviteRole)}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <div className="drop-icon">
                    <BsChevronExpand />
                  </div>
                ),
              }}
              onChange={(e: SingleValue<SelectOpt>) => setInviteRole(e?.value as WorkspaceRole)}
              styles={selectStyles}
            />
          </div>
          <div className="role-details">
            {roleOptions
              .find((v) => v.value === inviteRole)
              ?.details.map((v: RoleOption) => (
                <div key={v.label} className="row">
                  <Text>{v.label}:</Text> <Text>{v.value}</Text>
                </div>
              ))}
          </div>
          {(inviteRole === AdminRole || inviteRole === OwnerRole) && (
            <div className="role-warning">
              <Text variant="error" bold>
                Warning: This role gives full access to the entire workspace.
              </Text>
            </div>
          )}
          {inviteRole === AdminRole && adminDisabled && (
            <UpgradePlan title="Admin roles are available on the Enterprise Plan" />
          )}
          {(inviteRole === UserRole || inviteRole === OwnerRole) && userDisabled && (
            <UpgradePlan title="Invite more Team Members by upgrading your plan" />
          )}
          <div className="row">
            {inviteMemberError ? (
              <Text warning>
                <>
                  {inviteMemberError.response?.data
                    ? inviteMemberError.response?.data
                    : inviteMemberError.message}
                </>
              </Text>
            ) : null}
          </div>
        </>
      )}
    </Modal>
  )
}
