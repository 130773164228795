import "./WorkspaceTokens.sass"
import { debounce } from "lodash"
import { useMutation, useQuery } from "@tanstack/react-query"
import { IoAdd } from "react-icons/io5"
import Select from "react-select"
import { useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Header } from "./components/Header"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { IToken } from "./types"
import { BsShieldFillCheck } from "react-icons/bs"
import { Tag } from "./components/Tag"
import { useAuth } from "./hooks/useAuth"
import { useTokens } from "./hooks/useTokens"
import config from "./lib/config"
import { Token } from "./components/Token"
import { TextInput } from "./components/TextInput"
import { useCallback, useEffect, useState } from "react"
import axios, { AxiosError } from "axios"
import { ethers } from "ethers"
import { networkOptions } from "./components/NetworkSwitcher"
import { Spinner } from "./components/Spinner"
import { formatAddress } from "./lib/utils"
import * as coingecko from "./coingecko"

const tokenTypeOptions = [
  {
    label: "ERC20",
    value: "ERC20",
  },
  {
    label: "ERC721",
    value: "ERC721",
  },
]

interface CoinGeckoResult {
  id: string
  symbol: string
  name: string
  platforms?: {
    ethereum?: string
    "polygon-pos"?: string
  }
  decimals?: number
  logo_url: string
}

interface CoinGeckoSearchResult {
  coins: {
    id: string
    large: string
    name: string
    symbol: string
  }[]
}

export function WorkspaceTokens() {
  const params = useParams<{ workspaceId: string }>()
  const auth = useAuth()

  const [loading, setLoading] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState("")

  const [result, setResult] = useState<CoinGeckoResult | null>(null)
  const [search, setSearch] = useState("")
  const [name, setName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [address, setAddress] = useState("")
  const [decimals, setDecimals] = useState(18)
  const [tokenChain, setTokenChain] = useState("ethereum")
  // const [tokenType, setTokenType] = useState<"ERC20" | "ERC721">("ERC20")

  const reset = () => {
    setSearch("")
    setName("")
    setSymbol("")
    setDecimals(0)
    setResult(null)
  }

  const { data: tokens = [], refetch } = useTokens()

  const { isLoading: isLoadingCreate, mutate: createTokenMutation } = useMutation<
    IToken,
    AxiosError,
    Partial<IToken>
  >({
    mutationFn: (attrs: Partial<IToken>) =>
      fetcher("post", `/workspaces/${params.workspaceId}/tokens/`, attrs),
    onSuccess: (data) => {
      reset()
      refetch()
    },
    onError: (e) => {
      setError(e.response?.data?.error || "Error creating token")
      refetch()
      console.error(e)
    },
  })

  const { mutate: deleteTokenMutation } = useMutation<IToken, AxiosError, string>({
    mutationFn: (id: string) => fetcher("delete", `/workspaces/${params.workspaceId}/tokens/${id}`),
    onSuccess: (data) => {
      refetch()
    },
    onError: (e) => {
      setError(e.response?.data?.error || "Error deleting token")
      refetch()
      console.error(e)
    },
  })

  const getResultData = async (result: CoinGeckoResult) => {
    return axios
      .get(
        `https://api.coingecko.com/api/v3/coins/${result.id}?tickers=false&market_data=false&community_data=false&developer_data=false&sparkline=false`
      )
      .then((res) => {
        if (!res.data.detail_platforms?.ethereum) {
          setError("Token not on Ethereum")
          setLoading(false)
          setLoadingSearch(false)
          return
        }
        setName(res.data.name)
        setSymbol(res.data.symbol.toUpperCase())
        setDecimals(res.data.detail_platforms.ethereum.decimal_place)
        setAddress(formatAddress(res.data.detail_platforms.ethereum.contract_address))
        setTokenChain("ethereum")

        setResult({
          ...result,
          logo_url: res.data.image.small,
          decimals: res.data.detail_platforms?.ethereum?.decimal_place || "",
        })
        setLoading(false)
        setLoadingSearch(false)
      })
      .catch((e) => {
        console.error(e)
        setError("Error fetching token")
        setLoading(false)
        setLoadingSearch(false)
        setResult(null)
      })
  }

  const add = () => {
    try {
      const token: Partial<IToken> = {
        address: ethers.getAddress((address || search).toLowerCase()),
        name,
        symbol,
        decimals,
        chain_id: config.chains[tokenChain].chainId,
      }
      if (result?.symbol) {
        token.logo_url = result.logo_url
        token.coingecko_id = result.id
      }
      createTokenMutation(token)
    } catch (e) {
      setError("Invalid address")
      return
    }
  }

  const debouncedSearch = useCallback(
    debounce(
      (search: string) => {
        try {
          const addr = ethers.getAddress(search)
          setSearch(addr)
          setAddress(addr)
          setError("")
        } catch (e) {
          axios
            .get<AxiosError, { data: CoinGeckoSearchResult }>(
              `https://api.coingecko.com/api/v3/search?query=${search}`
            )
            .then(({ data }) => {
              if (data.coins.length === 0) {
                setLoadingSearch(false)
                setLoading(false)
                return setResult(null)
              }
              return getResultData({
                id: data.coins[0].id,
                name: data.coins[0].name,
                symbol: data.coins[0].symbol,
                logo_url: data.coins[0].large,
              })
            })
            .catch((e) => {
              setError("Invalid address")
              setLoadingSearch(false)
              setLoading(false)
              setResult(null)
            })
          return
        }
        const result = coingecko.list.find((t: CoinGeckoResult) =>
          t.platforms?.ethereum ? t.platforms.ethereum.toLowerCase() === search.toLowerCase() : false
        )
        if (!result) {
          setLoading(false)
          setLoadingSearch(false)
          return setResult(null)
        }
        setLoading(true)
        getResultData(result)
      },
      500,
      { leading: false }
    ),
    []
  )

  useEffect(() => {
    if (!search) {
      setError("")
      return setResult(null)
    }
    setLoadingSearch(true)
    debouncedSearch(search)
  }, [search])

  const canContinue =
    (!loadingSearch && !loading && search && result) || (name && symbol && decimals && search)

  return (
    <div className="page workspace-tokens">
      <div className="pane">
        <Header>
          <Text subtitle>Manage tokens for all wallets</Text>
        </Header>
        <div className="tokens-wrapper">
          {auth.isOwnerOrAdmin ? (
            <div className="search-box">
              <div className="row spaced">
                <Text>Search for a token to add</Text>
                <Text size="small">Powered by Coingecko</Text>
              </div>
              <div className="row">
                <TextInput
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Token Name or ERC20 Contract Address (0x...)"
                />
                {error ? <Text variant="error">{error}</Text> : null}
              </div>

              <div className="controls">
                <div>
                  {loadingSearch || loading ? <Spinner /> : null}
                  {result ? (
                    <div className="row">
                      <Token token={result} />
                    </div>
                  ) : null}

                  {search ? (
                    <div className="row">
                      {!result ? <Text>Not found. Continue to add unlisted token.</Text> : null}

                      <div className="row">
                        <div className="columns">
                          <div className="column">
                            <Text>Name</Text>
                            <TextInput
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Token Name"
                            />
                          </div>

                          <div className="column">
                            <Text>Address</Text>
                            <TextInput
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="ERC20 Contract Address (0x...)"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="columns">
                            <div className="column">
                              <Text>Decimals</Text>
                              <TextInput
                                value={decimals}
                                onChange={(e) => setDecimals(parseInt(e.target.value || "0"))}
                                placeholder="Decimals (18, 6, etc)"
                              />
                            </div>

                            <div className="column">
                              <Text>Symbol</Text>
                              <TextInput
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                                placeholder="Token Symbol"
                              />
                            </div>
                            <div className="column">
                              <Text>Network</Text>
                              <Select
                                className="token-chain"
                                options={networkOptions}
                                menuPortalTarget={document.body}
                                onChange={(e) => setTokenChain(e?.value || "ethereum")}
                                value={networkOptions.find((o) => o.value === tokenChain)}
                              />
                            </div>
                            {/*<div className="column">
                            <Text>Token Type</Text>
                            <Select
                              className="token-chain"
                              options={tokenTypeOptions}
                              onChange={(e) => setTokenType(e?.value || "ERC20")}
                              value={tokenTypeOptions.find((o) => o.value === tokenType)}
                            />
                          </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <Button disabled={!canContinue} variant="success" round onClick={() => add()}>
                  Add <IoAdd />
                </Button>
              </div>
            </div>
          ) : null}
          {/*<div className="row select">
            <Select
              value={search}
              options={options}
              isSearchable
              onChange={(value) => setSearch({ value })}
              placeholder="Search tokens (ETH...)"
            />
          </div>*/}
          {tokens ? (
            <div className="tokens">
              {tokens.map((token: IToken) => {
                return (
                  <div key={token.id} className="token-row">
                    <Token showNetwork showAddress token={token} />
                    {token.official ? (
                      <Tag>
                        Default
                        <BsShieldFillCheck size={14} style={{ marginLeft: 8 }} />
                      </Tag>
                    ) : (
                      <Button
                        onClick={() => deleteTokenMutation(token.id)}
                        className="remove"
                        ghost
                        size="small"
                        variant="error"
                      >
                        <Text uppercase>remove</Text>
                      </Button>
                    )}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
