import "./MFA.sass"
import { useEffect, useState } from "react"
import { BsArrowRight } from "react-icons/bs"
import { setTitle } from "./lib/utils"
import { Topbar } from "./components/Topbar"
import { Button } from "./components/Button"
import clsx from "clsx"
import { FaKey } from "react-icons/fa"
import { IoFingerPrint } from "react-icons/io5"
import { useMutation } from "@tanstack/react-query"
import { fetcher } from "./lib/fetcher"
import { Text } from "./components/Text"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useAuth } from "./hooks/useAuth"
import { TextInput } from "./components/TextInput"
import { AxiosError } from "axios"
import { useStore } from "./lib/store"

// https://github.com/NHAS/wag/blob/93c403c52f575c94aaf049a3fa30b1d551fa3481/internal/webserver/resources/static/js/webauthn.js#L19
function bufferDecode(value: string) {
  return Uint8Array.from(atob(value.replace(/_/g, "/").replace(/-/g, "+")), (c) => c.charCodeAt(0))
}
// https://github.com/NHAS/wag/blob/93c403c52f575c94aaf049a3fa30b1d551fa3481/internal/webserver/resources/static/js/webauthn.js#L25
// ArrayBuffer to URLBase64
function bufferEncode(value: ArrayBufferLike) {
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")
}

function Login() {
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedAuthType, setSelectedAuthType] = useState<"roaming" | "platform">("roaming")
  const [error, setError] = useState<any>(null)
  const navigate = useNavigate()

  const [fatal, setFatal] = useState<boolean>(false)

  const urlParams = new URLSearchParams(window.location.search)
  const isAdding = !!urlParams.get("add")
  const isRemoving = !!urlParams.get("remove")

  useEffect(() => {
    if (!window.PublicKeyCredential) {
      setFatal(true)
    }
  }, [])

  const { mutate: finishLoginMutation } = useMutation({
    networkMode: "always",
    mutationFn: async (data: any): Promise<any> => fetcher("post", "/mfa/login/finish", data),
    onSuccess: () => {
      if (isAdding) {
        return navigate("/mfa/register?add=true")
      }
      if (isRemoving) {
        return navigate("/mfa/remove")
      }
      window.location.href = "/"
    },
    onError: (error: any) => {
      setLoading(false)
      if (error?.response?.data) {
        if (error.response.data.includes("does not own the")) {
          setError("Invalid Security Key provided. Please use the one you previously registered.")
          return
        }
      }
      setError(error)
    },
  })

  const { mutate: beginLoginMutation } = useMutation({
    networkMode: "always",
    mutationFn: async (): Promise<{ publicKey: any }> =>
      fetcher("post", "/mfa/login/begin", { authenticator: selectedAuthType }),
    onSuccess: ({ publicKey }) => {
      publicKey.challenge = bufferDecode(publicKey.challenge)

      publicKey.allowCredentials = publicKey.allowCredentials.map((cred: any) => {
        return {
          ...cred,
          id: bufferDecode(cred.id),
        }
      })

      navigator.credentials
        .get({ publicKey })
        .then((assertion: any) => {
          finishLoginMutation({
            id: assertion.id,
            rawId: bufferEncode(assertion.rawId),
            type: assertion.type,
            response: {
              authenticatorData: bufferEncode(assertion.response.authenticatorData),
              clientDataJSON: bufferEncode(assertion.response.clientDataJSON),
              signature: bufferEncode(assertion.response.signature),
              // userHandle: bufferEncode(assertion.response.userHandle),
            },
          })
        })
        .catch((error: any) => {
          setLoading(false)
          if (error.message.includes("timed out or was not allowed")) {
            return
          }
          if (error.message.includes("is not a valid domain")) {
            setError("This Security Key is not registered with this account, or a Browser Extension is interfering with your Security Keys.")
            return
          }
          setError(error)
        })
    },
    onError: (error: any) => {
      setError(error)
      setLoading(false)
    },
  })

  const submit = () => {
    setLoading(true)
    beginLoginMutation()
  }

  return (
    <div className="pane">
      <div className="mfa-box">
        <Text size="xlarge">
          {isAdding || isRemoving
            ? "Confirm your existing Security Key to continue"
            : "Login with your Security Key"}
        </Text>
        {fatal ? (
          <div className="square-checks content-box row">
            <Text variant="error">Error: Your browser does not support WebAuthn.</Text>
          </div>
        ) : null}
        <div className="square-checks content-box row">
          <Button
            className={clsx("square-check", { active: selectedAuthType === "roaming" })}
            disabled={fatal}
            onClick={() => {
              setSelectedAuthType("roaming")
            }}
          >
            <FaKey size={24} /> Security Key (Yubikey, Phone, etc.)
          </Button>

          <Button
            className={clsx("square-check", { active: selectedAuthType === "platform" })}
            disabled={fatal}
            onClick={() => {
              setSelectedAuthType("platform")
            }}
          >
            <IoFingerPrint size={24} /> Device Biometrics
          </Button>
        </div>

        {error ? (
          <div className="square-checks content-box row">
            <Text variant="error">{String(error.message || error.name || error)}</Text>
          </div>
        ) : null}
        <div className="buttons">
          <Button variant="success" round onClick={submit} disabled={loading || fatal}>
            Login <BsArrowRight size={24} />
          </Button>
        </div>
      </div>
    </div>
  )
}
function Remove() {
  const navigate = useNavigate()
  const auth = useAuth()

  const [deviceId, setDeviceId] = useState<string | null>("")

  useEffect(() => {
    const id = window.localStorage.getItem("mfa_device_id")
    if (!id) return navigate("/dashboard")
    setDeviceId(id)
  }, [])

  const {
    mutate: deleteKey,
    error,
    isLoading,
  } = useMutation({
    mutationFn: async (_: unknown): Promise<any> => fetcher("delete", `/mfa/devices/${deviceId}`),
    onSuccess: () => {
      if (auth.authUser) {
        if (auth.authUser.workspaces.length > 0) {
          return navigate(`/dashboard/workspaces/${auth.authUser.workspaces[0].id}/profile`, {
            replace: true,
          })
        }
        return navigate("/")
      }
    },
  })

  return (
    <div className="pane">
      <div className="mfa-box">
        <Text size="xlarge">Confirm Deletion of your Security Key</Text>

        <div className="buttons">
          <Button variant="error" round onClick={deleteKey} disabled={isLoading}>
            Confirm
          </Button>
        </div>
        {error ? <Text variant="error">{String(error.message || error.name || error)}</Text> : null}
      </div>
    </div>
  )
}

function Register() {
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedAuthType, setSelectedAuthType] = useState<"roaming" | "platform">("roaming")
  const [error, setError] = useState<any>(null)
  const [name, setName] = useState<string>("")
  const navigate = useNavigate()
  const auth = useAuth()

  const [fatal, setFatal] = useState<boolean>(false)

  useEffect(() => {
    if (!window.PublicKeyCredential) {
      setFatal(true)
    }
  }, [])

  const { mutate: finishRegistrationMutation } = useMutation({
    mutationFn: async (data: any): Promise<any> => fetcher("post", `/mfa/register/finish?name=${name}`, data),
    onSuccess: (data) => {
      if (auth.authUser) {
        if (auth.authUser.workspaces.length > 0) {
          return navigate(`/dashboard/workspaces/${auth.authUser.workspaces[0].id}`, { replace: true })
        }
        return navigate("/")
      }
    },
    onError: (error: any) => {
      setError(error)
      setLoading(false)
    },
  })

  const { mutate: beginRegistrationMutation } = useMutation({
    mutationFn: async (): Promise<{ publicKey: any }> =>
      fetcher("post", "/mfa/register/begin", { authenticator: selectedAuthType }),
    onSuccess: ({ publicKey }) => {
      if (publicKey.excludeCredentials) {
        publicKey.excludeCredentials = publicKey.excludeCredentials.map((credential: any) => {
          credential.id = bufferDecode(credential.id)
          return credential
        })
      }
      publicKey.challenge = bufferDecode(publicKey.challenge)
      publicKey.user.id = bufferDecode(publicKey.user.id)
      navigator.credentials
        .create({ publicKey })
        .then((credential: any) => {
          const data = {
            id: credential.id,
            rawId: bufferEncode(credential.rawId),
            type: credential.type,
            response: {
              attestationObject: bufferEncode(credential.response.attestationObject),
              clientDataJSON: bufferEncode(credential.response.clientDataJSON),
            },
          }
          finishRegistrationMutation(data)
        })
        .catch((error: any) => {
          setLoading(false)
          if (error.message.includes("timed out or was not allowed")) {
            return
          }
          if (error.message.includes("attempted to register an authenticator that contains")) {
            setError("This authenticator is already registered.")
            return
          }
          setError(error)
        })
    },
    onError: (error: any) => {
      setError(error)
      setLoading(false)
    },
  })

  const submit = () => {
    setLoading(true)
    beginRegistrationMutation()
  }

  return (
    <div className="page">
      <div className="pane">
        <div className="mfa-box">
          <Text size="xlarge" subtitle>
            Setup MFA
          </Text>
          {fatal ? (
            <div className="square-checks content-box row">
              <Text variant="error">Error: Your browser does not support WebAuthn.</Text>
            </div>
          ) : null}
          <div className="square-checks content-box row">
            <Button
              className={clsx("square-check", { active: selectedAuthType === "roaming" })}
              disabled={fatal}
              onClick={() => {
                setSelectedAuthType("roaming")
              }}
            >
              <FaKey size={24} /> Security Key (Yubikey, Trezor FIDO2, etc.)
            </Button>

            <Button
              className={clsx("square-check", { active: selectedAuthType === "platform" })}
              disabled={fatal}
              onClick={() => {
                setSelectedAuthType("platform")
              }}
            >
              <IoFingerPrint size={24} /> Device Biometrics
            </Button>
          </div>

          {error ? (
            <div className="square-checks content-box row">
              <Text variant="error">{String(error.message || error.name || error)}</Text>
            </div>
          ) : null}
          <div className="row">
            <Text subtitle>Add a nickname for this key or device</Text>
            <TextInput
              type="text"
              placeholder="My Security Key 1"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="buttons">
            <Button variant="success" round onClick={submit} disabled={loading || fatal || name.length < 2}>
              Setup <BsArrowRight size={24} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function MFA() {
  setTitle("MFA")

  return (
    <div className="mfa">
      <Topbar disableNav />

      <div className="page">
        <Routes>
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="remove" element={<Remove />} />
        </Routes>
      </div>
    </div>
  )
}
