import { ethers } from "ethers"

export interface Chain {
  name: string
  nickname: string
  symbol: string
  chainId: number
  blockExplorer: string
  isMainnet: boolean
  logo?: string
  // provider is a read-only call provider
  provider?: ethers.JsonRpcProvider
}

export interface Config {
  env: string
  apiEndpoint: string
  rpcEndpoint: string
  chains: { [key: string]: Chain }
  defaultChain: "ethereum" | "sepolia"
  stripePublicKey: string
  stripePortalUrl: string
}

const apiEndpoint = import.meta.env.VITE_REACT_APP_API_URL || "http://localhost:8080/v1"

const setProvider = (base: Chain): Chain => {
  const network = ethers.Network.from(base.chainId)
  return {
    ...base,
    provider: new ethers.JsonRpcProvider(`${apiEndpoint}/rpc/${base.chainId}`, network, {
      staticNetwork: network,
    }),
  }
}

const config: Config = {
  env: import.meta.env.MODE,
  apiEndpoint,
  rpcEndpoint: import.meta.env.VITE_REACT_APP_RPC_URL || "http://localhost:5020/v1/eth/rpc",
  chains: {
    // mainnets
    ethereum: setProvider({
      name: "Ethereum",
      nickname: "ETH",
      symbol: "ETH",
      chainId: 1,
      blockExplorer: "https://etherscan.io",
      isMainnet: true,
      logo: "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
    }),
    polygon: setProvider({
      name: "Polygon",
      nickname: "MATIC",
      symbol: "MATIC",
      chainId: 137,
      blockExplorer: "https://polygonscan.com",
      isMainnet: true,
      logo: "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png",
    }),
    arbitrum: setProvider({
      name: "Arbitrum",
      nickname: "ARB",
      symbol: "ARB",
      chainId: 42161,
      blockExplorer: "https://arbiscan.io",
      isMainnet: true,
      logo: "https://assets.coingecko.com/asset_platforms/images/33/large/arbitrum-one.png",
    }),
    base: setProvider({
      name: "Base",
      nickname: "BASE",
      symbol: "BASE ETH",
      chainId: 8453,
      blockExplorer: "https://basescan.org",
      isMainnet: true,
      logo: "https://assets.coingecko.com/asset_platforms/images/131/large/base.jpeg",
    }),

    // testnets
    /* goerli: setProvider({
      name: "Görli Testnet [deprecated]",
      nickname: "Görli",
      symbol: "G",
      chainId: 5,
      blockExplorer: "https://goerli.etherscan.io",
      isMainnet: false,
    }), */
    sepolia: setProvider({
      name: "Sepolia Testnet",
      nickname: "Sepolia",
      symbol: "SepoliaETH",
      chainId: 11155111,
      blockExplorer: "https://sepolia.etherscan.io",
      isMainnet: false,
    }),
    base_sepolia: setProvider({
      name: "Base Sepolia Testnet",
      nickname: "BASE Sepolia Testnet",
      symbol: "BASE TESTNET",
      chainId: 84532,
      blockExplorer: "https://sepolia.basescan.org/",
      isMainnet: true,
    }),
    /* holesky: setProvider({
      name: "Holesky Testnet",
      nickname: "Holesky",
      symbol: "HoleskyETH",
      chainId: 17000,
      blockExplorer: "https://holesky.etherscan.io",
      isMainnet: false,
    }), */
    polygon_mumbai: setProvider({
      name: "Polygon Mumbai Testnet [deprecated]",
      nickname: "MATIC",
      symbol: "MATIC",
      chainId: 80001,
      blockExplorer: "https://mumbai.polygonscan.com",
      isMainnet: false,
    }),
    polygon_amoy: setProvider({
      name: "Polygon Amoy Testnet",
      nickname: "MATIC",
      symbol: "MATIC",
      chainId: 80002,
      blockExplorer: "https://www.oklink.com/amoy",
      isMainnet: false,
    }),
  },
  defaultChain: "ethereum",
  stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  stripePortalUrl: import.meta.env.VITE_STRIPE_PORTAL_URL,
}
export default config
