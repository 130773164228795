import { Params } from "react-router-dom"
import { AdminRole, IRole, IUser, IWorkspace, OwnerRole, UserRole } from "../types"

export const isOwner = (r?: IRole) => r?.role === OwnerRole
export const isAdmin = (r?: IRole) => r?.role === AdminRole
export const isUser = (r?: IRole) => r?.role === UserRole
export const isOwnerOrAdmin = (r?: IRole) => isOwner(r) || isAdmin(r)

export const currentWorkspace = (u?: IUser, params?: Readonly<Params<string>>): IWorkspace | undefined => {
  return u?.workspaces.find((v: IWorkspace) => v.id === params?.workspaceId)
}
