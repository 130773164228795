import "./CountdownSpinner.sass"
import { useEffect, useState } from "react"

const TICKS = 114
export interface CountdownSpinnerProps {
  countdown: number
  expires: Date
}

export function CountdownSpinner(props: CountdownSpinnerProps) {
  const [countdown, setCountdown] = useState<number>(0)

  const remaining = Math.floor((props.expires.getTime() - Date.now()) / 1000)

  useEffect(() => {
    const interval: ReturnType<typeof setInterval> = setInterval(() => {
      if (countdown === props.countdown) {
        return clearInterval(interval)
      }
      setCountdown((countdown) => countdown + 1)
    }, 1000)
    return () => clearInterval(interval)
  })

  const multiplier = TICKS / props.countdown
  const offset = TICKS - multiplier * remaining

  return (
    <div className="countdown-spinner">
      <div className="outline">
        <div className="number">{remaining < 0 ? 0 : remaining}</div>
        <svg>
          <circle r="18" cx="20" cy="20" strokeDasharray={TICKS} strokeDashoffset={offset} />
        </svg>
      </div>
    </div>
  )
}
