import "./AssignRoleModal.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import clsx from "clsx"
import { useState } from "react"
import { FaKey, FaUserAlt } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import { shortAddr } from "./components/Addr"
import { Button } from "./components/Button"
import { Empty } from "./components/Empty"
import { Modal, ModalFooter } from "./components/Modal"
import { SelectOpt } from "./components/Select"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { IApiKey, IUser, IWallet, IWalletRole } from "./types"

type assignRoleMutationAttrs = {
  user_id?: string
  wallet_id?: string
  api_key_id?: string
}

interface AssignRoleModalProps {
  roleId?: string
}

export function AssignRoleModal(props: AssignRoleModalProps) {
  const params = useParams<{ workspaceId: string; roleId: string }>()

  const [selectedSubjectType, setSelectedSubjectType] = useState<"user" | "api-key">("user")
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>()
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey | undefined>()
  const [selectedWallet, setSelectedWallet] = useState<IWallet | undefined>()
  const [error, setError] = useState<Error | undefined>()

  const setRoleModal = (v: boolean) => useStore.setState({ assignRoleModalActive: v })
  const assignRoleModalActive = useStore((s: Store) => s.assignRoleModalActive)

  const { data, isLoading, refetch } = useQuery({
    queryFn: async (): Promise<IWalletRole> =>
      fetcher("get", `/workspaces/${params.workspaceId}/roles/${props.roleId}`),
    queryKey: [params.workspaceId, "roles", props.roleId],
  })

  const {
    data: wallets,
    isLoading: isLoadingWallets,
    refetch: refetchWallets,
  } = useQuery<IWallet[], AxiosError>({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
  })

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: async (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
    queryKey: [params.workspaceId, "users"],
  })

  const { data: apiKeys, refetch: refetchApikeys } = useQuery({
    queryFn: async (): Promise<IApiKey[]> => fetcher("get", `/workspaces/${params.workspaceId}/api_keys/`),
    queryKey: [params.workspaceId, "wallets", "api_keys"],
  })

  const { mutate: assignRoleMutation } = useMutation<IWalletRole, AxiosError, assignRoleMutationAttrs>({
    mutationFn: (attrs: assignRoleMutationAttrs) =>
      fetcher("post", `/workspaces/${params.workspaceId}/roles/${props.roleId}/assign`, attrs),

    onSuccess: () => {
      closeModal()
      refetchAll()
    },
    onError: (err) => {
      if (String(err.response?.data).includes("already assigned")) {
        if (selectedUser !== undefined) {
          setError(
            new Error(`${selectedUser.name} is already assigned to the ${selectedWallet?.name} wallet`)
          )
          return
        }
        if (selectedApiKey !== undefined) {
          setError(
            new Error(
              `${selectedApiKey.name} is already assigned to the ${selectedApiKey.wallet?.name} wallet`
            )
          )
        }
      }
    },
  })

  const apiKeyWallet =
    (selectedApiKey?.wallet_id && wallets?.find((v) => v.id === selectedApiKey.wallet_id)) || null

  if (isLoading) return <Spinner />
  if (!data) return <Empty>Not Found</Empty>

  const isApiKeyRole = !data.permissions.find((p) => p.type === "approval")

  const refetchAll = () => {
    refetch()
    refetchWallets()
    refetchUsers()
    refetchApikeys()
  }

  const saveRole = () => {
    assignRoleMutation({
      user_id: selectedUser?.id,
      wallet_id: selectedWallet?.id,
      api_key_id: selectedApiKey?.id,
    })
  }

  const closeModal = () => {
    setSelectedSubjectType("user")
    setSelectedUser(undefined)
    setSelectedApiKey(undefined)
    setSelectedWallet(undefined)
    setError(undefined)
    setRoleModal(false)
  }

  return (
    <Modal
      isOpen={assignRoleModalActive}
      onRequestClose={closeModal}
      className="assign-role-modal"
      title="Assign Role"
      footer={
        <ModalFooter>
          <>
            <Button
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>

            <Button
              variant="success"
              disabled={
                (!selectedUser && !selectedApiKey) ||
                (selectedUser && !selectedWallet) ||
                isLoading ||
                isLoadingWallets ||
                isLoadingUsers
              }
              onClick={() => {
                saveRole()
              }}
            >
              Save
            </Button>
          </>
        </ModalFooter>
      }
    >
      <div className="row">
        <Text subtitle>Role: </Text>
        <Text bold>{data.name}</Text>
      </div>

      {isApiKeyRole ? (
        <>
          <div className="row">
            <Text subtitle>Assign to:</Text>
          </div>
          <div className="square-checks content-box row">
            <Button
              className={clsx("square-check", { active: selectedSubjectType === "user" })}
              onClick={() => {
                setSelectedSubjectType("user")
                setSelectedApiKey(undefined)
                setError(undefined)
              }}
            >
              <FaUserAlt /> User
            </Button>
            <Button
              className={clsx("square-check", { active: selectedSubjectType === "api-key" })}
              onClick={() => {
                setSelectedSubjectType("api-key")
                setSelectedApiKey(undefined)
                setSelectedUser(undefined)
                setSelectedWallet(undefined)
                setError(undefined)
              }}
            >
              <FaKey /> Api Key
            </Button>
          </div>
        </>
      ) : (
        <>
          <Text>Roles with an Approval permission can only be assigned to users.</Text>
          <div className="row">
            <Text subtitle>Assign to:</Text>
          </div>
        </>
      )}

      <div className="content-box row select">
        {selectedSubjectType === "user" && (
          <Select
            className="react-select"
            placeholder="Select User"
            onChange={(v: SingleValue<SelectOpt>) => {
              setSelectedUser(users?.find((u: IUser) => u.id === v?.value))
            }}
            options={users?.map((v: IUser) => {
              return {
                value: v.id,
                label: `${v.name} (${v.email}) [${v.role?.role}]`,
              }
            })}
          />
        )}

        {selectedSubjectType === "api-key" && (
          <Select
            className="react-select"
            placeholder="Select Api Key"
            onChange={(v: SingleValue<SelectOpt>) => {
              setSelectedApiKey(apiKeys?.find((u: IApiKey) => u.id === v?.value))
            }}
            options={apiKeys?.map((v: IApiKey) => {
              return {
                value: v.id,
                label: `${v.name} (${v.prefix}) Wallet: [${v.wallet?.name}]`,
              }
            })}
          />
        )}
      </div>

      {selectedSubjectType === "user" && (
        <>
          <div className="row">
            <Text subtitle>Assign To Wallet:</Text>
          </div>

          <div className="content-box row">
            <Select
              className="react-select"
              placeholder="Select Wallet"
              onChange={(v: SingleValue<SelectOpt>) => {
                setSelectedWallet(wallets?.find((u: IWallet) => u.id === v?.value))
              }}
              options={wallets?.map((v: IWallet) => {
                return {
                  value: v.id,
                  label: `${v.name} (${shortAddr(v.accounts?.[0].address)})`,
                }
              })}
            />
          </div>
        </>
      )}

      {selectedSubjectType === "api-key" && selectedApiKey && (
        <div className="content-box column">
          <div className="row">
            <Text>
              The selected API Key is assigned to the{" "}
              <Link
                target="_blank"
                to={`/dashboard/workspaces/${params.workspaceId}/wallets/${selectedApiKey.wallet?.id}`}
              >
                <Text bold>{selectedApiKey.wallet?.name}</Text>
              </Link>{" "}
              wallet.
            </Text>
          </div>
          <Text>Create a new API Key if you want to control a different wallet</Text>
        </div>
      )}

      {selectedSubjectType === "user" && selectedUser && selectedWallet && (
        <div className="content-box">
          <div className="row">
            This gives the selected <Text bold>user full use of this role</Text> on the{" "}
            <Text bold>{selectedWallet?.name}</Text> wallet.
          </div>
        </div>
      )}
      {selectedSubjectType === "api-key" && selectedApiKey && selectedWallet && (
        <div className="content-box">
          <div className="row">
            This gives the selected <Text bold>API key full use of this role</Text> on the{" "}
            <Text bold>{selectedWallet?.name}</Text> wallet.
          </div>
        </div>
      )}
      {error && <div className="content-box error-message">{error.message}</div>}
    </Modal>
  )
}
