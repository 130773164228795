import axios, { AxiosError, AxiosResponse } from "axios"
import config from "./config"

export const apiClient = axios.create({
  baseURL: config.apiEndpoint,
  headers: {
    "content-type": "application/json",
  },
  withCredentials: true,
})

type Method = "get" | "post" | "put" | "patch" | "delete"
export async function fetcher<T, R>(method: Method, path: string, body?: T): Promise<R> {
  return apiClient[method]<T>(path, body).then((r: AxiosResponse) => r.data)
}

// export const retry = (count: number, error: AxiosError) => {
export const retry = (count: any, error: any) => {
  if (parseInt(String(error.code)) >= 500 && count < 3) return true
  return false
}

export const parseAxiosError = (e: AxiosError) => {
  if (e.response?.data) {
    return e.response.data
  }
  return e.message
}
