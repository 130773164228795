import "./Invite.sass"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "./components/Button"
import { Text } from "./components/Text"
import { Topbar } from "./components/Topbar"
import { fetcher } from "./lib/fetcher"
import { Spinner } from "./components/Spinner"

export function Invite() {
  const [loading, setLoading] = useState<boolean>(true)
  const [inviteId, setInviteId] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const inviteId = window.localStorage.getItem("inviteId") || urlParams.get("inviteId")
    setLoading(false)
    if (!inviteId) {
      return
    }
    setInviteId(inviteId)
  }, [])

  const {
    mutate: acceptInviteMutation,
    isLoading: isLoadingRedeem,
    error: acceptInviteError,
  } = useMutation<any, AxiosError, { inviteId: string }>({
    mutationFn: (attrs: any) => fetcher("post", "/workspaces/invites/accept", attrs),
    onSuccess: (data) => {
      // TODO: refresh authUser instead of refresh
      window.localStorage.removeItem("inviteId")
      window.location.href = `/dashboard/workspaces/${data.id}`
    },
    onError: () => {
      setLoading(false)
      setInviteId(undefined)
      window.localStorage.removeItem("inviteId")
    },
  })

  const acceptInvite = () => {
    setLoading(true)
    if (!inviteId) return
    acceptInviteMutation({ inviteId })
  }
  const rejectInvite = () => {
    window.localStorage.removeItem("inviteId")
    navigate("/dashboard")
  }
  return (
    <div className="invite">
      <Topbar disableNav />

      <div className="page">
        <div className="pane">
          <div className="content">
            <Text subtitle>Accept Invite</Text>

            {loading ? <Spinner /> : null}

            {!loading && inviteId ? (
              <div className="buttons">
                <Button className="button login" onClick={rejectInvite} disabled={isLoadingRedeem}>
                  Reject
                </Button>
                <Button
                  className="button login"
                  onClick={acceptInvite}
                  variant="success"
                  disabled={isLoadingRedeem}
                >
                  Accept
                </Button>
              </div>
            ) : (
              <div>{acceptInviteError ? <Text>Invite is invalid or expired</Text> : null}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
