import { Variant } from "../types"
import "./Text.sass"
import clsx from "clsx"

interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  warning?: boolean
  uppercase?: boolean
  bold?: boolean
  medium?: boolean
  subtitle?: boolean
  variant?: Variant
  overflow?: boolean
  monospace?: boolean
  size?: "small" | "large" | "xlarge"
}

export function Text(props: TextProps) {
  const {
    className,
    children,
    warning,
    uppercase,
    bold,
    medium,
    subtitle,
    variant,
    overflow,
    monospace,
    size,
    title,
    ...rest
  } = props
  return (
    <span
      className={clsx("text", className, variant, size, {
        warning,
        uppercase,
        bold,
        medium,
        subtitle,
        overflow,
        monospace,
      })}
      title={title ? title : typeof children === "string" ? children : undefined}
      {...rest}
    >
      {children}
    </span>
  )
}
