import { useMutation, useQuery } from "@tanstack/react-query"
import { IWalletAlert, IWalletAlertItem, IWalletAlertItemDestination, Variant } from "./types"
import { fetcher } from "./lib/fetcher"
import { Link, useNavigate, useParams } from "react-router-dom"
import { queryClient } from "./lib/store"
import { Spinner } from "./components/Spinner"
import { Empty } from "./components/Empty"
import { Text } from "./components/Text"
import { Divider } from "./components/Divider"
import "./Alerts.sass"
import { Button } from "./components/Button"
import { Header } from "./components/Header"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { alertItemComparators } from "./components/AlertEditor"
import { IoIosTrash } from "react-icons/io"
import { useAuth } from "./hooks/useAuth"
import config from "./lib/config"
import { Tag } from "./components/Tag"

export const getVariant = (status: string): Variant | undefined => {
  switch (status) {
    case "ALARM":
      return "error"
    case "ACTIVE":
      return "success"
  }
  return
}

export function Alert() {
  const params = useParams<{ workspaceId: string; alertId: string }>()
  const navigate = useNavigate()

  const auth = useAuth()

  const { data, isLoading } = useQuery({
    queryFn: (): Promise<IWalletAlert> =>
      fetcher("get", `/workspaces/${params.workspaceId}/wallets/alerts/${params.alertId}`),
    queryKey: [params.workspaceId, "wallets", "alerts", params.alertId],
    enabled: !!params.alertId,
  })

  const {
    mutate: removeAlertMutation,
    isLoading: IsLoadingRemoveAlert,
    error: removeAlertError,
  } = useMutation({
    mutationFn: (attrs: IWalletAlert): Promise<null> =>
      fetcher("delete", `/workspaces/${params.workspaceId}/wallets/alerts/${attrs.id}`),
    onSuccess: () => {
      queryClient.invalidateQueries([params.workspaceId, "wallets", "alerts"])
      navigate("..")
    },
    onError: (e) => {
      console.error(e)
    },
  })

  const removeAlert = (v: IWalletAlert) => {
    if (window.confirm("Are you sure you want to delete this alert?")) {
      removeAlertMutation(v)
    }
  }

  if (isLoading) return <Spinner />
  if (!data) return <Empty>Not Found</Empty>

  const alertChain = Object.keys(config.chains)
    .map((k) => config.chains[k])
    .find((v) => v.chainId === data.chain_id)

  return (
    <div className="pane alert">
      <Header>
        <div className="header-name">
          <Text bold>{data.name}</Text>
          {data.status === "ALARM" ? <Tag variant="error">ALARM</Tag> : null}
        </div>
      </Header>

      <div className="row details">
        <div className="column">
          <Text>Network</Text>
          <Text bold>{alertChain?.name}</Text>
        </div>
        <div className="column">
          <Text>Status</Text>

          <Text bold variant={getVariant(data.status)}>
            {data.status}
          </Text>
        </div>
        <div className="column">
          <Text>Wallet</Text>
          <Link to={`/dashboard/workspaces/${params.workspaceId}/wallets/${data.wallet.id}`}>
            <Text bold>{data.wallet.name}</Text>
          </Link>
        </div>
      </div>
      <div className="alert-view shadow">
        <Divider>Triggers</Divider>

        <div className="triggers">
          {data.items?.map((v: IWalletAlertItem) => {
            return (
              <div key={v.id} className="trigger">
                <div className="column">
                  Type: <Text bold>{v.type}</Text>
                </div>
                <div className="column">
                  Trigger:{" "}
                  <Text bold>{alertItemComparators.find((a) => a.value === v.comparator)?.label}</Text>
                </div>
                <div className="value">
                  Value: <Text bold>{v.value}</Text>
                </div>
              </div>
            )
          })}
        </div>

        {data.destinations?.length > 0 ? (
          <>
            <Divider>Alert Recipients</Divider>
            {data.destinations.map((v: IWalletAlertItemDestination) => (
              <div key={v.email} className="row spaced">
                <Text bold>Email</Text>
                <Text bold>{v.email}</Text>
              </div>
            ))}
          </>
        ) : (
          <div className="alert-view">
            <div className="info">
              <AiOutlineInfoCircle /> <Text>This alert does not have any notification recipients</Text>
            </div>
          </div>
        )}
      </div>
      {auth.isOwnerOrAdmin ? (
        <div className="alert-view">
          <div className="row spaced">
            <Text uppercase>Manage</Text>
          </div>
          <div className="row remove">
            <Button variant="error" ghost onClick={() => removeAlert(data)}>
              Delete Alert
              <IoIosTrash size={24} />
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
