import "./Onboarding.sass"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { Button } from "./components/Button"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import { Topbar } from "./components/Topbar"
import { fetcher } from "./lib/fetcher"
import { IWorkspace } from "./types"
import { Store, useStore } from "./lib/store"

function Setup() {
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const navigate = useNavigate()
  const store: Store = useStore()

  useEffect(() => {
    if (store.authUser && !store.authUser?.workspaces.length) {
      return navigate("/onboarding/waitlist", { replace: true })
    }
  }, [store.authUser])

  const {
    mutate: createWorkspaceMutation,
    isLoading: isLoadingWorkspace,
    error: createWorkspaceError,
  } = useMutation<IWorkspace, AxiosError, { name: string }>({
    mutationFn: (attrs: any) => fetcher("post", "/workspaces/", attrs),
    onSuccess: (data) => {
      // TODO: refresh authUser instead of refresh
      window.location.href = `/dashboard/workspaces/${data.id}`
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
    },
  })

  const createWorkspace = () => {
    setLoading(true)
    createWorkspaceMutation({ name })
  }
  const cancel = () => {
    navigate("/dashboard")
  }

  return (
    <div className="page">
      <div className="pane">
        <div className="content">
          <Text subtitle>Create Workspace</Text>

          <div>
            <TextInput
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              placeholder="Workspace Name"
            />
          </div>
          <Button
            className="button login"
            onClick={createWorkspace}
            variant={name ? "success" : undefined}
            disabled={!name || loading || isLoadingWorkspace}
          >
            Create
          </Button>
        </div>
        <Text variant="error">{createWorkspaceError?.message}</Text>
      </div>
    </div>
  )
}

export function Onboarding() {
  return (
    <div className="onboarding">
      <Topbar disableNav />

      <Routes>
        <Route
          path="waitlist"
          element={
            <div className="page">
              <div className="pane">
                <div className="content">
                  <Text size="xlarge">Welcome to SYBL</Text>
                  <br />
                  <Text>SYBL is currently in closed beta.</Text>
                  <br />
                  <Text>We will let you know when we open access,</Text>
                  <Text>or you can be invited by an existing beta user.</Text>
                </div>
              </div>
            </div>
          }
        />

        <Route path="setup" element={<Setup />} />
      </Routes>
    </div>
  )
}
