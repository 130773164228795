import "./TransactModal.sass"
import * as ethers from "ethers"
import { useState } from "react"
import { FiExternalLink } from "react-icons/fi"
import { IoAddOutline } from "react-icons/io5"
import { Link, useParams } from "react-router-dom"
import Select, { components, SingleValue } from "react-select"
import { Button } from "./components/Button"
import { Empty } from "./components/Empty"
import { Modal, ModalFooter } from "./components/Modal"
import { isValidEthRecipient } from "./components/RoleEditorV2"
import { ImagePriceOption } from "./components/Select"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import { ERC20Contract } from "./contracts/erc20"
import { useTokens } from "./hooks/useTokens"
import { useTokenFiatBalances, useWalletBalance } from "./hooks/useWalletBalance"
import config from "./lib/config"
import { Store, useStore } from "./lib/store"
import { formatFloat } from "./lib/utils"
import { IToken, errors } from "./types"
import { useAuth } from "./hooks/useAuth"
import { Card } from "./components/Card"

interface ITokenPriced extends IToken {
  price: {
    balance: string
    value: string
  }
}

export interface TransactModalProps {
  isOpen: boolean
  onRequestClose: (a: boolean) => void
}

export function TransactModal() {
  const params = useParams<{ workspaceId: string }>()
  const transactModalWallet = useStore((s: Store) => s.transactModalWallet)
  const auth = useAuth()

  const [to, setTo] = useState<string>("")
  const [value, setValue] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const [result, setResult] = useState<ethers.TransactionResponse>()
  const [selectedToken, setSelectedToken] = useState<ITokenPriced | undefined>()

  const chain = Object.values(config.chains).find((c) => c.chainId === selectedToken?.chain_id)

  const { data: tokens = [] } = useTokens()

  const { data: fiatData } = useTokenFiatBalances(tokens)

  const { data: walletValueData } = useWalletBalance(tokens, fiatData, transactModalWallet)

  const tokenOptions = tokens.map((t) => {
    const { value, balance } = walletValueData.tokens?.find((v) => v.id === t.id) || {
      value: "0",
      balance: "0",
    }

    const shortBalance = balance ? formatFloat(balance, 6) : ""

    return {
      ...t,
      value: t.symbol,
      label: t.name,
      price: { balance: shortBalance, value },
    }
  })

  const userRole = transactModalWallet?.roles?.find((r) =>
    r.users?.find((u) => u.user.id === auth.authUser?.id)
  )

  // const defaultTokenOption = tokenOptions.find((t) => t.value === "ETH")

  const onTokenChange = (e: SingleValue<unknown>) => {
    setSelectedToken(e as ITokenPriced)
  }

  const submit = async () => {
    if (!selectedToken) return
    // TODO: check if the user has access to the wallet before even showing the modal
    // the role may allow the user to submit a transaction to be approved by the admin
    if (!transactModalWallet?.accounts.length) return
    setLoading(true)
    const url = `${config.apiEndpoint}/workspaces/${params.workspaceId}/wallets/${transactModalWallet?.id}/rpc/${selectedToken.chain_id}?kind=preview`
    const provider = new ethers.JsonRpcProvider(url)
    const signer = await provider.getSigner()
    const cpy = transactModalWallet

    let preview

    try {
      // ERC20 token
      if (selectedToken.address) {
        const Contract = ERC20Contract(selectedToken.address, provider)
        const data = Contract.interface.encodeFunctionData("transfer", [
          to,
          ethers.parseUnits(value, selectedToken.decimals),
        ])
        preview = await signer.populateTransaction({
          from: transactModalWallet.accounts[0].address,
          chainId: selectedToken.chain_id,
          value: 0n, // erc20 transfer fn encodes the value
          data,
          to: selectedToken.address,
        })
      } else {
        preview = await signer.populateTransaction({
          from: transactModalWallet.accounts[0].address,
          chainId: selectedToken.chain_id,
          value: ethers.parseEther(value),
          to,
        })
      }
    } catch (e: unknown) {
      setLoading(false)
      const error = e as ethers.EthersError

      if (error.code === "UNCONFIGURED_NAME") {
        return setError(new Error("Invalid ENS address - the name is not configured"))
      }

      return setError(e as Error)
    }

    // hack as the sendTransaction has no estimated resolve time
    signer
      .sendTransaction(preview)
      .then((res) => {
        useStore.setState({ transactModalWallet: cpy })
        // this blocks until the request times out or the transaction is previewed and approved.
        setLoading(false)
        setResult(res)
      })
      .catch((error: ethers.EthersError) => {
        setLoading(false)
        useStore.setState({ transactModalWallet: cpy })
        const msg = String(error.message)
        if (error?.error?.message === "Unauthorized") {
          return setError(new Error("Transaction not authorized by current Role"))
        }
        if (msg.includes("Transaction Rejected")) {
          return closeModal()
        }
        if (msg.includes("insufficient funds")) {
          return setError(new Error("Insufficient funds"))
        }
        if (msg.includes(errors.ADMIN_DENIED)) {
          return setError(new Error("Transaction Denied by Workspace Admin"))
        }
        if (msg.includes(errors.ADMIN_TIMEDOUT)) {
          return setError(new Error("Transaction timed out waiting for review"))
        }
        if (msg.includes("unauthorized")) {
          return setError(new Error("Your current role is not authorized to perform this action"))
        }
        setError(error)
      })
      .catch((e) => console.log({ e }))
  }

  const closeModal = () => {
    useStore.setState({ transactModalWallet: null })
    setValue("")
    setTo("")
    setLoading(false)
    setError(undefined)
    setSelectedToken(undefined)
    setResult(undefined)
  }

  return (
    <Modal
      isOpen={transactModalWallet !== null}
      className="transact-modal"
      onRequestClose={closeModal}
      title={result ? "Transaction Submitted" : "Send"}
      footer={
        <ModalFooter>
          {result ? (
            <>
              <div />
              <Button variant="success" round onClick={closeModal}>
                Done
              </Button>
            </>
          ) : (
            <>
              <Button
                round
                onClick={() => {
                  closeModal()
                }}
              >
                Cancel
              </Button>

              <Button
                variant="success"
                round
                disabled={
                  !isValidEthRecipient(to) ||
                  loading ||
                  value === "" ||
                  parseFloat(value) <= 0 ||
                  !selectedToken ||
                  !userRole
                }
                onClick={submit}
              >
                Continue
              </Button>
            </>
          )}
        </ModalFooter>
      }
    >
      {loading ? (
        <Empty>
          <Spinner />
        </Empty>
      ) : transactModalWallet !== null ? (
        result ? (
          <>
            <div className="row">
              <Text subtitle>To: </Text>
              <Text bold>
                {result.to ? (
                  <a target="_blank" rel="noreferrer" href={`${chain?.blockExplorer}/address/${result.to}`}>
                    {result.to} <FiExternalLink size={16} />
                  </a>
                ) : (
                  "(none)"
                )}
              </Text>
            </div>
            <div className="row">
              <Text subtitle>Value:</Text>
              {chain?.symbol} {ethers.formatEther(String(result.value)).toString()}
            </div>
            <div className="row">
              <Text subtitle>Hash:</Text>
              <pre>{result.hash}</pre>
            </div>
            <div className="row">
              <a href={`${chain?.blockExplorer}/tx/${result.hash}`} target="_blank" rel="noreferrer">
                <Button variant="success" round>
                  View on Block Explorer <FiExternalLink size={16} />
                </Button>
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <Text subtitle>Network:</Text>
              <Text bold>{chain?.name}</Text>
            </div>

            {transactModalWallet.roles?.length === 0 ? (
              <div className="row">
                <Text bold>Warning:</Text>
                <Text>This wallet does not have any roles assigned</Text>
                <Text>Please assign a role before continuing</Text>
                <div className="row">
                  <Empty>
                    {auth.isOwnerOrAdmin ? (
                      <Button
                        uppercase
                        round
                        variant="success"
                        onClick={() =>
                          useStore.setState({ transactModalWallet: null, assignWalletModalActive: true })
                        }
                      >
                        Assign Wallet <IoAddOutline size={20} />
                      </Button>
                    ) : null}
                  </Empty>
                </div>
              </div>
            ) : (
              <>
                <div className="row select">
                  <Text subtitle>Token:</Text>
                  <Select
                    options={tokenOptions}
                    isSearchable
                    getOptionValue={(option) => option.id}
                    components={{
                      ValueContainer: (props) => {
                        const [value] = props.getValue()
                        return (
                          <components.ValueContainer {...props}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {value?.logo_url && (
                                <img src={value?.logo_url} alt="" style={{ marginRight: 8, height: 30 }} />
                              )}
                              {props.children}

                              {value?.price && (
                                <span style={{ marginLeft: "auto" }}>
                                  {value.price.balance} (${value.price.value})
                                </span>
                              )}
                            </div>
                          </components.ValueContainer>
                        )
                      },
                      Option: (props) => {
                        return <ImagePriceOption {...props} />
                      },
                    }}
                    value={selectedToken}
                    onChange={onTokenChange}
                  />
                </div>

                <div className="row">
                  <Text subtitle>Sending From:</Text>
                  <Text>
                    <Link to={`wallets/${transactModalWallet.id}`} target="_blank">
                      {transactModalWallet.name} ({transactModalWallet.accounts[0].address})
                    </Link>
                  </Text>
                </div>

                <div className="row value">
                  <Text subtitle>To:</Text>
                  <div className="value-input">
                    <TextInput
                      type="string"
                      placeholder="Address or ENS (0x123..., test.eth)"
                      autoFocus
                      onChange={(e) => setTo(e.target.value)}
                      value={to}
                    />
                  </div>
                </div>

                <div className="row value">
                  <Text subtitle>Value:</Text>
                  <div className="value-input">
                    <TextInput
                      type="number"
                      placeholder="Number (0.0)"
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                    />
                    {selectedToken?.symbol}
                  </div>
                </div>
              </>
            )}

            {userRole ? (
              <div className="row value">
                <Text subtitle>Your user role: </Text>
                <Link
                  to={`/dashboard/workspaces/${params.workspaceId}/permissions/${userRole.id}`}
                  target="_blank"
                >
                  <Text bold>{userRole.name}</Text>
                  <FiExternalLink />
                </Link>
              </div>
            ) : (
              <Card className="row" plain>
                <Text subtitle variant="error">
                  Warning:
                </Text>
                <Text bold>You are not assigned to any roles on this wallet</Text>
                {auth.isOwnerOrAdmin ? (
                  <>
                    <Text>Assign your current user to a role to continue:</Text>
                    <div>
                      <Button
                        round
                        onClick={() =>
                          useStore.setState({ transactModalWallet: null, assignWalletModalActive: true })
                        }
                      >
                        Assign Wallet
                      </Button>
                    </div>
                  </>
                ) : (
                  <Text>Ask a workspace admin to assign your user to a role</Text>
                )}
              </Card>
            )}

            {error ? (
              <div className="row value">
                <Text variant="error">{error?.message}</Text>
              </div>
            ) : null}
          </>
        )
      ) : null}
    </Modal>
  )
}
