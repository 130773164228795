import { useQuery } from "@tanstack/react-query"
import { sortByDate } from "../lib/utils"
import { IAuth, IWorkspace } from "../types"
import { IconOption, SelectOpt, selectStyles } from "./Select"
import "./SideBar.sass"
import { AxiosError } from "axios"
import { AiOutlineBell, AiOutlineLock } from "react-icons/ai"
import { BiCodeAlt } from "react-icons/bi"
import { BsChevronExpand, BsCircle, BsListNested } from "react-icons/bs"
import { FiCreditCard, FiSettings, FiUsers } from "react-icons/fi"
import {  IoAddCircleOutline } from "react-icons/io5"
import { MdOutlineAccountBalanceWallet, MdOutlineAccountCircle } from "react-icons/md"
import { matchPath, matchRoutes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import Select, { components, SingleValue } from "react-select"
import { fetcher } from "../lib/fetcher"
import { useAuth } from "../hooks/useAuth"

const customSelectStyles = {
  ...selectStyles,

  control: (base: any) => ({
    ...base,
    border: "none",
  }),
}

interface Link {
  key: string
  route: string
  title: string
  icon?: () => JSX.Element
  disabled?: (auth?: IAuth) => boolean
  alert?: boolean
}

const topLinks: Link[] = [
  {
    key: "",
    route: "/dashboard/workspaces/:workspaceId",
    title: "Accounts",
    icon: () => <MdOutlineAccountBalanceWallet />,
  },
  {
    key: "/team",
    route: "/dashboard/workspaces/:workspaceId/team",
    title: "Team",
    icon: () => <FiUsers />,
  },
  {
    key: "/permissions",
    route: "/dashboard/workspaces/:workspaceId/permissions/*",
    title: "Permissions",
    icon: () => <AiOutlineLock />,
  },
  {
    key: "/alerts",
    route: "/dashboard/workspaces/:workspaceId/alerts/*",
    title: "Alerts",
    icon: () => <AiOutlineBell />,
  },
  {
    key: "/tokens",
    route: "/dashboard/workspaces/:workspaceId/tokens",
    title: "Workspace Tokens",
    icon: () => <BsListNested />,
  },
  {
    key: "/developer",
    route: "/dashboard/workspaces/:workspaceId/developer",
    title: "Developer Settings",
    icon: () => <BiCodeAlt />,
  },
  {
    key: "/profile",
    route: "/dashboard/workspaces/:workspaceId/profile",
    title: "Profile",
    icon: () => <MdOutlineAccountCircle />,
    disabled: () => true, // hidden from sidebar but still accessible
  },
  // {
  //   key: "/controls",
  //   route: "/dashboard/workspaces/:workspaceId/controls",
  //   title: "Access Controls",
  //   icon: () => <AiOutlineControl />,
  // },
]
const bottomLinks: Link[] = [
  // {
  //   key: "/support",
  //   route: "/dashboard/workspaces/:workspaceId/support",
  //   title: "Customer Support",
  //   icon: () => <BiSupport />,
  // },
  {
    key: "/settings",
    route: "/dashboard/workspaces/:workspaceId/settings",
    title: "Settings",
    icon: () => <FiSettings />,
    disabled: (auth) => !auth?.isOwnerOrAdmin,
  },
  {
    key: "/billing",
    route: "/dashboard/workspaces/:workspaceId/billing",
    title: "Billing",
    icon: () => <FiCreditCard />,
  },
]

// used for matching routes to sidebar links
const links: Link[] = [
  ...topLinks,
  ...bottomLinks,
  {
    key: "/accounts/:id",
    route: "/dashboard/workspaces/:workspaceId/wallets/:id",
    title: "Account",
  },
]

export const getRouteTitle = (base: string): string | undefined => {
  const match = links.find((v) => {
    return matchPath(v.route, base)
  })
  return match?.title || ""
}

export function SideBar() {
  const auth = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const navigationOptions: SelectOpt[] = sortByDate<IWorkspace>(auth.authUser?.workspaces)
    .map((v: IWorkspace) => ({
      value: v.id,
      label: v.name,
    }))
    .concat([
      {
        value: "create",
        label: "Create Workspace",
      },
    ])

  const { data: statusData } = useQuery<{ commit: string }, AxiosError>({
    queryKey: ["status"],
    queryFn: () => fetcher("get", "/status/"),
  })

  const onWorkspaceChange = (e: SingleValue<SelectOpt>) => {
    if (e?.value === "create") {
      navigate("/onboarding/setup")
      return
    }
    navigate(`/dashboard/workspaces/${e?.value || ""}`)
  }

  const selected = navigationOptions.find((v: SelectOpt) => {
    return v.value === (params.workspaceId || "create")
  })

  const baseRoute = `/dashboard/workspaces/${params.workspaceId}`
  return (
    <div className="sidebar">
      <div className="navigation">
        <Select
          key={navigationOptions.length}
          className="react-select"
          options={navigationOptions}
          components={{
            IndicatorSeparator: () => null,
            ValueContainer: (props) => {
              return (
                <components.ValueContainer {...props}>
                  <img src="/favicon.png" alt="sybl" />
                  {props.children}
                </components.ValueContainer>
              )
            },
            Option: (props) => {
              const icon =
                props.data.value === "create"
                  ? () => <IoAddCircleOutline size={24} />
                  : () => <BsCircle size={12} />
              return <IconOption {...props} icon={icon} />
            },
            DropdownIndicator: () => (
              <div className="drop-icon">
                <BsChevronExpand />
              </div>
            ),
          }}
          value={selected}
          defaultValue={selected}
          onChange={onWorkspaceChange}
          styles={customSelectStyles}
        />

        <div className="links">
          {topLinks.map((v) => {
            if (v.disabled?.(auth)) return
            const active =
              (v.route === "/dashboard/workspaces/:workspaceId" && location.pathname.includes("/wallets/")) ||
              matchRoutes([{ path: v.route }], location.pathname)
            return (
              <NavLink key={v.key} end to={baseRoute + v.key} className={active ? "active" : ""}>
                {v.icon?.()} {v.title}
              </NavLink>
            )
          })}
        </div>

        <div className="links base">
          {bottomLinks.map((v) => {
            if (v.disabled?.(auth)) return
            return (
              <NavLink key={v.key} end to={baseRoute + v.key}>
                {v.icon?.()} {v.title}
              </NavLink>
            )
          })}

          <div className="status">{String(statusData?.commit).slice(0, 8)}</div>
        </div>
      </div>
    </div>
  )
}
