import "./CreateWalletModal.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Modal, ModalFooter } from "./components/Modal"
import { Text } from "./components/Text"
import { TextInput } from "./components/TextInput"
import { fetcher, parseAxiosError } from "./lib/fetcher"
import { queryClient, Store, useStore } from "./lib/store"
import { IWallet, PlanFree } from "./types"
import { useAuth } from "./hooks/useAuth"
import { UpgradePlan } from "./components/UpgradePlan"

export function CreateWalletModal() {
  const params = useParams<{ workspaceId: string }>()
  const navigate = useNavigate()
  const { workspace } = useAuth()

  const [walletName, setWalletName] = useState<string>("")
  const [importPk, setImportPk] = useState<string>("")
  const [importChecked, setImportChecked] = useState<boolean>(false)
  const [createTab, setCreateTab] = useState<"create" | "import">("create")

  const walletModalActive = useStore((s: Store) => s.walletModalActive)
  const setNewWalletModal = (v: boolean) => useStore.setState({ walletModalActive: v })

  useEffect(() => {
    return closeModal()
  }, [])

  const { data: wallets = [] } = useQuery({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
  })

  const {
    mutate: createWalletMutation,
    isLoading: isLoadingWallet,
    error: createWalletError,
  } = useMutation<IWallet, AxiosError, { name: string; workspace_id: string; import_pk?: string }>({
    mutationFn: (attrs: any) =>
      fetcher<{ name: string; workspace_id: string }, IWallet>(
        "post",
        `/workspaces/${params.workspaceId}/wallets/`,
        attrs
      ),
    onSuccess: (data: IWallet) => {
      setWalletName("")
      setImportPk("")
      queryClient.setQueryData([params.workspaceId, "wallets"], (prev: IWallet[] | undefined = []) => [
        ...prev,
        data,
      ])
      closeModal()
      navigate(`wallets/${data.id}`)
    },
  })

  const createWallet = () => {
    if (!params.workspaceId) return
    createWalletMutation({ name: walletName, workspace_id: params.workspaceId, import_pk: importPk })
  }

  const closeModal = () => {
    setNewWalletModal(false)
    setWalletName("")
    setImportPk("")
    setCreateTab("create")
  }
  const setTab = (tab: "create" | "import") => {
    setWalletName("")
    setImportPk("")
    setCreateTab(tab)
    setImportChecked(false)
  }

  return (
    <Modal
      isOpen={walletModalActive}
      onRequestClose={() => closeModal()}
      title="Create Account"
      className="create-wallet-modal"
      footer={
        <ModalFooter>
          <Button
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={createWallet}
            disabled={
              createTab === "create"
                ? isLoadingWallet || !walletName
                : isLoadingWallet ||
                  !walletName ||
                  importPk === "" ||
                  ![64, 66].includes(importPk.length) ||
                  !importChecked
            }
            variant="success"
          >
            {createTab === "create" ? "Create Account" : "Import Account"}
          </Button>
        </ModalFooter>
      }
    >
      {workspace?.billing_plan.name === PlanFree && wallets.length >= workspace.billing_plan.wallets ? (
        <>
          <UpgradePlan title="Your workspace reached the maximum number of Accounts" />
        </>
      ) : (
        <>
          {workspace?.feature_flags?.import_accounts ? (
            <div className="tabs">
              <Button
                plain
                className={createTab === "create" ? "active" : undefined}
                onClick={() => setTab("create")}
              >
                Create Account
              </Button>
              <Button
                plain
                className={createTab === "import" ? "active" : undefined}
                onClick={() => setTab("import")}
              >
                Import Account
              </Button>
            </div>
          ) : null}

          <div className="row">
            <Text subtitle>Account Name</Text>
          </div>
          <div className="row">
            <TextInput
              autoFocus
              value={walletName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWalletName(e.target.value)}
              placeholder="Main, NFT, Project A, etc."
            />
          </div>

          {createTab === "import" && (
            <div className="row">
              <Text subtitle>External Account Private Key</Text>
              <TextInput
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImportPk(e.target.value)}
                placeholder="Private Key (0x1234....9999 or 1234....9999)"
              />
              <div className="row">
                <Text bold>
                  Warning: Importing an account does not provide the same security guarantees as accounts
                  created by SYBL.
                </Text>
                <div className="row">
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={String(importChecked)}
                      onChange={(e) => setImportChecked(e.target.checked)}
                    />{" "}
                    <Text bold>
                      I understand the risks and assume full responsibility for the imported account.
                    </Text>
                  </label>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Text variant="error">{createWalletError ? String(parseAxiosError(createWalletError)) : null}</Text>
    </Modal>
  )
}
