import { usePendingTransactions } from "./PendingTransactions"
import "./ReviewTransactionModal.sass"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { CountdownSpinner } from "./components/CountdownSpinner"
import { Divider } from "./components/Divider"
import { Modal, ModalFooter } from "./components/Modal"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { Store, useStore } from "./lib/store"
import { IReviewableTransaction } from "./types"
import { RenderTransaction } from "./ReviewTransactionModal"

export interface ReviewTransactionModalProps {
  isOpen: boolean
  onRequestClose: (a: boolean) => void
}

export function AdminReviewTransactionModal() {
  const params = useParams<{ workspaceId: string; roleId: string }>()
  const reviewTransaction = useStore((s: Store) => s.reviewTransaction)

  const closeModal = () => {
    useStore.setState({ reviewTransaction: null })
  }

  const { refetch, data } = usePendingTransactions(params)
  if (reviewTransaction && !data.find((v) => v.id === reviewTransaction?.id)) {
    closeModal()
  }

  const {
    mutate: reviewTransactionMutation,
    isLoading: isLoadingConfirm,
    error: confirmError,
  } = useMutation<any, AxiosError, { id: string; approve: boolean }>({
    mutationFn: async (attrs: any) =>
      fetcher("post", `/workspaces/${params.workspaceId}/transactions/review`, attrs),
    onSuccess: () => {
      refetch()
      closeModal()
    },
    onError: console.error,
  })

  const confirmPendingTransaction = (v: IReviewableTransaction, approve: boolean) => {
    if (approve && v.method === "eth_sign") {
      if (
        !window.confirm(
          "WARNING! This is a raw eth_sign transaction. We cannot decode the actual value or display the changes. Are you absolutely sure you trust the origin and want to approve this?"
        )
      ) {
        return reviewTransactionMutation({
          id: v.id,
          approve: false,
        })
      }
    }
    reviewTransactionMutation({
      id: v.id,
      approve,
    })
  }

  const latest = reviewTransaction

  return (
    <Modal
      isOpen={!!latest}
      className="review-transaction-modal"
      title="Review Transaction"
      onRequestClose={() => closeModal()}
      footer={
        <ModalFooter>
          {latest && (
            <>
              <Button
                round
                variant="error"
                onClick={() => {
                  confirmPendingTransaction(latest, false)
                }}
              >
                Deny
              </Button>

              <Button variant="success" round onClick={() => confirmPendingTransaction(latest, true)}>
                Approve
              </Button>
            </>
          )}
        </ModalFooter>
      }
    >
      {latest ? (
        <>
          <RenderTransaction transaction={latest} />

          <Divider />
          <div className="row">
            <Text>Submitted By:</Text>
          </div>
          {latest.user ? (
            <div className="row">
              <Text bold>
                {latest.user.name} ({latest.user.email})
              </Text>
            </div>
          ) : (
            <div className="row">
              <div className="row">API Key:</div>
              <Text bold>{latest.api_key?.name}</Text>
            </div>
          )}
          <div className="row">
            <Text>Expires:</Text>
            <div className="countdown">
              <CountdownSpinner countdown={60} expires={new Date(latest.expires)} />
            </div>
          </div>
        </>
      ) : null}
    </Modal>
  )
}
