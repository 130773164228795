import "./Team.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { BsChevronExpand } from "react-icons/bs"
import { IoAddOutline } from "react-icons/io5"
import { useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import { Button } from "./components/Button"
import { Header } from "./components/Header"
import { Modal, ModalFooter } from "./components/Modal"
import { SelectOpt, selectStyles } from "./components/Select"
import { Text } from "./components/Text"
import { fetcher, retry } from "./lib/fetcher"
import { isOwner } from "./lib/roles"
import { Store, useStore } from "./lib/store"
import { AdminRole, IInvite, IRole, IUser, OwnerRole, UserRole, WorkspaceRole } from "./types"
import { Divider } from "./components/Divider"
import { RoleOption, roleOptions } from "./InviteTeamModal"
import { Tag } from "./components/Tag"
import { useAuth } from "./hooks/useAuth"
import { Timeago } from "./lib/timeago"

const SORT_ORDER = {
  [OwnerRole]: 1,
  [AdminRole]: 2,
  [UserRole]: 3,
}

export function Team() {
  const params = useParams()
  const auth = useAuth()
  const setInviteTeamModal = (v: boolean) => useStore.setState({ inviteModalActive: v })
  const inviteTeamModal = useStore((s: Store) => s.inviteModalActive)
  const [manageUser, setManageUser] = useState<IUser | null>(null)
  const [userRole, setUserRole] = useState<WorkspaceRole | undefined>(undefined)

  useEffect(() => {
    return () => {
      setManageUser(null)
    }
  }, [])

  const {
    data = [],
    isLoading,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: async (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
    queryKey: [params.workspaceId, "users"],
    retry,
  })

  const { data: invites = [], refetch: refetchInvites } = useQuery({
    queryFn: async (): Promise<IInvite[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/admin/invites`),
    queryKey: [params.workspaceId, "invites"],
  })

  const refetchAll = () => {
    refetchUsers()
    refetchInvites()
  }

  const {
    mutate: removeUserMutation,
    isLoading: isLoadingRemoveUser,
    error: removeUserError,
  } = useMutation<IUser, AxiosError, IUser>({
    mutationFn: (attrs: IUser) =>
      fetcher("delete", `/workspaces/${params.workspaceId}/admin/users/${attrs.id}`),
    onSuccess: () => {
      refetchAll()
      closeModal()
    },
    onError: (e) => {
      refetchAll()
      console.error(e)
    },
  })

  const {
    mutate: modifyMemberMutation,
    isLoading: isLoadingModify,
    error: modifyMemberError,
  } = useMutation<IRole, AxiosError, { id: string; role: string }>({
    mutationFn: (attrs: { id: string; role: string }) =>
      fetcher("patch", `/workspaces/${params.workspaceId}/admin/users/${attrs.id}`, attrs),
    onSuccess: () => {
      refetchAll()
      closeModal()
    },
  })

  const { mutate: deleteInviteUserMutation } = useMutation<IInvite, AxiosError, IInvite>({
    mutationFn: (attrs: { id: string }) =>
      fetcher("delete", `/workspaces/${params.workspaceId}/admin/invites/${attrs.id}`),
    onSuccess: refetchAll,
    onError: refetchAll,
  })

  const deleteInvite = (invite: IInvite) => {
    deleteInviteUserMutation(invite)
  }

  const removeUser = (v: IUser) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      removeUserMutation(v)
    }
  }

  const closeModal = () => {
    setManageUser(null)
    setUserRole(undefined)
  }

  const saveUser = () => {
    if (!userRole || !manageUser) return
    modifyMemberMutation({
      id: manageUser.id,
      role: userRole,
    })
  }

  return (
    <div className="page team">
      <div className="pane">
        <Header>
          <div className="title" />
          {auth.isOwnerOrAdmin && (
            <Button variant="success" round onClick={() => setInviteTeamModal(!inviteTeamModal)}>
              Invite Team <IoAddOutline size={20} />
            </Button>
          )}
        </Header>
        <div className="rows">
          <div className="row">
            <Text subtitle>Name</Text>
            <Text subtitle>Role</Text>
            <Text subtitle>Email</Text>
            <Text subtitle>MFA Enabled</Text>
            {auth.isOwnerOrAdmin ? <Text subtitle>Manage</Text> : <div />}
          </div>
          {data
            .sort((a, b) => SORT_ORDER[a.role?.role || "user"] - SORT_ORDER[b.role?.role || "user"])
            .map((v: IUser) => {
              const targetIsOwner = isOwner(v.role)
              const canManage =
                auth.authUser?.id !== v.id && (targetIsOwner ? auth.isOwner : auth.isOwnerOrAdmin)
              return (
                <div key={v.id} className="row">
                  <div className="name">{v.name}</div>
                  <div className="role">{v.role?.role}</div>
                  <div>
                    <Tag>{v.email}</Tag>
                  </div>
                  <div className="mfa">
                    {v.has_mfa ? <Text variant="success">Yes</Text> : <Text variant="error">No</Text>}
                  </div>
                  {canManage ? (
                    <div>
                      <Button
                        className="manage"
                        uppercase
                        onClick={() => {
                          setManageUser(v)
                          setUserRole(v.role?.role)
                        }}
                      >
                        Manage
                      </Button>
                    </div>
                  ) : (
                    <div className="button-placeholder" />
                  )}{" "}
                </div>
              )
            })}
        </div>

        {invites.length ? (
          <>
            <Header className="invites-header">
              <Text subtitle>Pending Invites</Text>
            </Header>
            <div className="rows">
              <div className="row">
                <Text subtitle>Email</Text>
                <Text subtitle>Role</Text>
                <Text subtitle>Created</Text>
                <Text subtitle>Expired</Text>
                {auth.isOwnerOrAdmin ? <Text subtitle>Manage</Text> : <div />}
              </div>
              {invites.map((v: IInvite) => {
                return (
                  <div key={v.id} className="row">
                    <div className="name">{v.email}</div>
                    <div className="role">{v.role.role}</div>
                    <div className="created">{Timeago(new Date(v.created_at))}</div>
                    <div className="expires">
                      {String(new Date(v.expires_at).getTime() < new Date().getTime())}
                    </div>
                    {auth.isOwnerOrAdmin ? (
                      <div>
                        <Button
                          uppercase
                          onClick={() => {
                            deleteInvite(v)
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>

      <Modal
        isOpen={!!manageUser}
        onRequestClose={closeModal}
        title="Manage User"
        footer={
          <ModalFooter>
            <>
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                onClick={saveUser}
                disabled={manageUser?.role?.role === userRole || isLoadingModify}
                variant="success"
              >
                Save
              </Button>
            </>
          </ModalFooter>
        }
      >
        {manageUser && manageUser.id !== auth.authUser?.id ? (
          <>
            <Text subtitle>Modify Role</Text>
            <Select
              className="react-select"
              options={roleOptions.filter((v) => {
                if (auth.isOwner) return true
                if (auth.isAdmin && v.value !== OwnerRole) return true
                return false
              })}
              value={roleOptions.find((v) => v.value === userRole)}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <div className="drop-icon">
                    <BsChevronExpand />
                  </div>
                ),
              }}
              onChange={(e: SingleValue<SelectOpt>) => {
                setUserRole(e?.value as WorkspaceRole)
              }}
              styles={selectStyles}
            />
            <div className="role-details">
              {roleOptions
                .find((v) => v.value === userRole)
                ?.details.map((v: RoleOption) => (
                  <div key={v.label} className="row">
                    <Text>{v.label}:</Text> <Text>{v.value}</Text>
                  </div>
                ))}
            </div>
            {(userRole === AdminRole || userRole === OwnerRole) && (
              <div className="role-warning">
                <Text variant="error" bold>
                  Warning: This role gives full access to the entire workspace.
                </Text>
              </div>
            )}

            <Divider>Danger Zone</Divider>
            <Button
              onClick={() => removeUser(manageUser)}
              variant="error"
              ghost
              disabled={isLoadingRemoveUser}
            >
              Remove from Workspace
            </Button>

            {modifyMemberError ? <Text variant="error">{modifyMemberError.message}</Text> : null}
            {removeUserError ? <Text variant="error">{removeUserError.message}</Text> : null}
          </>
        ) : null}
      </Modal>
    </div>
  )
}
