import "./Topbar.sass"
import { useEffect, useRef, useState } from "react"
import { BiDotsVerticalRounded } from "react-icons/bi"
import { Link, useLocation } from "react-router-dom"
import config from "../lib/config"
import { Store, useStore } from "../lib/store"
import { Button } from "./Button"
import { NetworkSwitcher } from "./NetworkSwitcher"
import { getRouteTitle } from "./SideBar"
import { Text } from "./Text"
import { useAuth } from "../hooks/useAuth"

interface TopbarProps {
  disableNav?: boolean
}

export function Topbar(props: TopbarProps) {
  const [open, toggle] = useState<boolean>(false)
  const menu = useRef<HTMLDivElement>(null)
  const base = useLocation().pathname
  const { authUser, workspace } = useAuth()

  useEffect(() => {
    const handle = (e: DocumentEventMap["mousedown"]) => {
      if (menu.current && e.target) {
        if (menu.current.contains(e.target as Node)) return
        toggle(false)
      }
    }
    document.addEventListener("mousedown", handle)
    return () => {
      document.removeEventListener("mousedown", handle)
    }
  }, [])

  const logout = () => {
    window.location.href = config.apiEndpoint + "/auth/logout"
  }

  return (
    <div className="topbar">
      <Text size="xlarge" className="title" medium>
        {getRouteTitle(base)}
      </Text>
      {!props.disableNav && <NetworkSwitcher />}
      {authUser && (
        <div className="menu-wrapper" ref={menu} onClick={() => toggle(!open)}>
          <Button className="account">
            <div className="avatar">{authUser?.name?.charAt(0)}</div>
            <Text overflow>{authUser.email}</Text>
            <BiDotsVerticalRounded size={24} color="#708090" />
          </Button>
          {open && (
            <div className="menu">
              <Link to="/dashboard">Dashboard</Link>
              <Link to={`/dashboard/workspaces/${workspace?.id}/profile`}>Account Settings</Link>
              <div onClick={logout}>Logout</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
