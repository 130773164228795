import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { fetcher } from "../lib/fetcher"
import { IToken } from "../types"

export const useTokens = () => {
  const params = useParams<{ workspaceId: string }>()
  return useQuery({
    queryFn: (): Promise<IToken[]> => fetcher("get", `/workspaces/${params.workspaceId}/tokens/`),
    queryKey: [params.workspaceId, "tokens"],
  })
}
