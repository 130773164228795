import "./RoleEditor.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { ethers } from "ethers"
import { useState } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import { IoAddOutline } from "react-icons/io5"
import { useNavigate, useParams } from "react-router-dom"
import Select, { SingleValue } from "react-select"
import CreatableSelect from "react-select/creatable"
import { v4 as uuidV4 } from "uuid"
import { fetcher } from "../lib/fetcher"
import { IUser, IWallet, IWalletRole } from "../types"
import { Button } from "./Button"
import { Divider } from "./Divider"
import { selectStyles } from "./Select"
import { Text } from "./Text"
import { TextInput } from "./TextInput"
import { Tag } from "./Tag"
import { useTokens } from "../hooks/useTokens"
import config from "../lib/config"

const customSelectStyles = {
  ...selectStyles,

  control: (base: any) => ({
    ...base,
    border: "1px solid #C5C4C4",
    padding: 4,
  }),
}

const inputKeys = ["methods", "addresses", "tokens", "bytecode", "approvers", "values"]

export const VALUE_OPTIONS = [
  { value: "$lt", label: "Less Than" },
  { value: "$lte", label: "Less Than or Equal" },
  { value: "*", label: "Any" },
]

interface Permission {
  id?: string
  type: string
  methods?: string[]
  addresses?: string[]
  tokens?: string[]
  bytecode?: string[]
  approvers?: string[]
  values?: string[]
}

interface RoleEditorProps {
  edit?: IWalletRole
}

interface InputTextArray {
  type: "methods" | "addresses" | "tokens" | "bytecode" | "approvers" | "values"
  label: string
  placeholder: string
  value: string[]
  options?: string[]
}

interface PermissionOption {
  id?: string
  type: "" | "method" | "transfer" | "contract:function" | "contract:deploy" | "approval"
  label?: string
  required?: boolean
  valid?: boolean
  inputs: InputTextArray[]
}

const methodOption: PermissionOption = {
  type: "method",
  label: "Method",
  required: true,
  inputs: [
    {
      type: "methods",
      label: "Method Names",
      placeholder: "eth_sendTransaction, eth_signTransaction, etc",
      options: [
        "eth_sendTransaction",
        "eth_signTransaction",
        "personal_sign",
        "eth_signTypedData",
        "eth_signTypedData_v1",
        "eth_signTypedData_v3",
        "eth_signTypedData_v4",
      ],
      value: ["eth_sendTransaction", "eth_signTransaction"],
    },
  ],
}

export const roleTypeOptions: PermissionOption[] = [
  {
    type: "transfer",
    label: "Allow Transfers",
    inputs: [
      {
        type: "addresses",
        label: "To (address)",
        placeholder: "0x1234, sybl.eth (Select, paste, or type to add)",
        value: [],
      },
      {
        type: "tokens",
        label: "Tokens",
        placeholder: "ETH, MATIC, etc (Select, paste, or type to add)",
        value: [],
      },
      {
        type: "values",
        label: "Value",
        placeholder: "",
        value: ["*"],
      },
    ],
  },
  {
    type: "contract:function",
    label: "Allow Contract Function writes",
    inputs: [
      {
        type: "addresses",
        label: "Contract address",
        placeholder: "0x1234, sybl.eth, (select, paste, or type to add)",
        value: [],
      },
      {
        type: "methods",
        label: "Function names",
        placeholder: "mint(address), transfer(address, uint256), (select, paste, or type to add)",
        value: [],
      },
    ],
  },
  {
    type: "contract:deploy",
    label: "Allow Contract Deploys",
    inputs: [
      {
        type: "bytecode",
        label: "Bytecode",
        value: ["*"],
        placeholder: "0x608060...",
      },
    ],
  },
  {
    type: "approval",
    label: "Require Approval",
    inputs: [
      {
        type: "approvers",
        label: "Workspace Admin/Owner",
        value: [],
        placeholder: "Workspace Admin/Owner",
      },
    ],
  },
]

const defaultPermission = (): PermissionOption => ({
  id: uuidV4(),
  type: "",
  inputs: [],
  valid: false,
})

const methodPermission = (): PermissionOption => ({
  ...methodOption,
  id: uuidV4(),
  valid: true,
})

// TODO: make this more specific
export const isEns = (value: string) => {
  return value.length > 4 && value.includes(".eth") && !value.includes(" ")
}

export const isValidEthRecipient = (a: string): boolean => {
  return a.length === 42 || isEns(a)
}

const checkValid = (p: PermissionOption): boolean => {
  if (p.type === "transfer") {
    if (p.inputs[0]?.value.length === 0) return false
    if (p.inputs[1]?.value.length === 0) return false
    if (p.inputs[2]?.value.length === 0) return false
    if (p.inputs[2]?.value[0] !== "*" && p.inputs[2]?.value[1] === "") return false
    return true
  }
  if (p.type === "contract:function") {
    if (p.inputs[0]?.value.length === 0) return false
    if (p.inputs[1]?.value.length === 0) return false
    if (p.inputs[1]?.value[0] === "") return false
    return true
  }
  if (p.type === "approval") {
    if (p.inputs[0]?.value.length === 0) return false
    return true
  }
  if (p.type === "contract:deploy") {
    return true
  }
  if (p.type === "method") {
    if (p.inputs[0]?.value.length === 0) return false
    return true
  }
  return false
}

function MethodPermission({
  permission,
  updatePermission,
}: {
  permission: PermissionOption
  updatePermission: (p: PermissionOption) => void
}) {
  const methodOptions = [
    ...(methodOption.inputs[0].options?.map((v) => ({ value: v, label: v })) || []),
  ].concat({ value: "*", label: "Any" })

  return (
    <div className="permission">
      <div className="row">
        <Text subtitle>{permission.type}</Text>
      </div>
      <div className="row">
        <Select
          className="react-select"
          placeholder={permission.inputs[0]?.placeholder}
          defaultValue={permission.inputs[0]?.value?.map((v) => ({ value: v, label: v === "*" ? "Any" : v }))}
          options={methodOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: [{ ...permission.inputs[0], value: e.map((v) => v.value) }],
            })
          }}
        />
        <div className="row"></div>
        <Text>Leave as eth_sendTransaction if unsure</Text>
      </div>
    </div>
  )
}

function TransferPermission({
  addresses = [],
  tokens = [],
  base,
  permission,
  updatePermission,
}: {
  addresses: { label: string; value: string }[]
  tokens: { label: string; value: string }[]
  base?: PermissionOption
  permission: PermissionOption
  updatePermission: (p: PermissionOption) => void
}) {
  const addressOptions = [
    ...addresses,
    ...(permission.inputs[0]?.options?.map((v) => ({
      value: v,
      label: addresses.find((a) => a.value === v)?.label || v,
    })) || []),
  ].concat({ value: "*", label: "Any" })

  const tokenOptions = [
    ...tokens,
    ...(permission.inputs[1]?.options?.map((v) => ({
      value: v,
      label: tokens.find((a) => a.value === v)?.label || v,
    })) || []),
    { value: "*", label: "Any" },
  ]

  const addressValues = permission.inputs.length
    ? permission.inputs
        .find((v) => v.type === "addresses")
        ?.value?.map((v) => ({
          value: v,
          label: addressOptions.find((a) => a.value === v)?.label || v,
        }))
    : base?.inputs[0]?.value?.map((v) => ({ value: v, label: v }))

  const tokenValues = permission.inputs.length
    ? permission.inputs
        .find((v) => v.type === "tokens")
        ?.value?.map((v) => ({
          value: v,
          label: tokenOptions.find((a) => a.value === v)?.label || v,
        }))
    : base?.inputs[1]?.value?.map((v) => ({ value: v, label: v }))

  const valueValues = permission.inputs.length
    ? permission.inputs
        .find((v) => v.type === "values")
        ?.value?.map((v) => ({
          value: v,
          label: VALUE_OPTIONS.find((a) => a.value === v)?.label || v,
        }))
    : base?.inputs[2]?.value?.map((v) => ({ value: v, label: v }))

  return (
    <div className="permission">
      <div className="row">
        <div className="row">
          <Text subtitle>To (recipient)</Text>
        </div>

        <CreatableSelect
          className="react-select"
          placeholder={base?.inputs[0]?.placeholder}
          defaultValue={addressValues}
          options={addressOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "addresses") {
                  return { ...input, value: e.map((v) => v.value) }
                }
                return input
              }),
            })
          }}
        />
      </div>

      <div className="row">
        <div className="row">
          <Text subtitle>Allowed Tokens</Text>
        </div>

        <CreatableSelect
          className="react-select"
          placeholder={base?.inputs[1]?.placeholder}
          defaultValue={tokenValues}
          options={tokenOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "tokens") {
                  return { ...input, value: e.map((v) => v.value) }
                }
                return input
              }),
            })
          }}
        />
      </div>

      <div className="row">
        <div className="row">
          <Text subtitle>Value</Text>
        </div>

        <Select
          className="react-select"
          placeholder={base?.inputs[2]?.placeholder}
          defaultValue={valueValues}
          options={VALUE_OPTIONS}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          onChange={(e) => {
            if (!e) return
            const value = e.value === "*" ? [e.value] : [e.value, ...[permission.inputs[2]?.value[1]]]
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "values") {
                  return { ...input, value }
                }
                return input
              }),
            })
          }}
        />
        {permission.inputs[2]?.value?.[0] !== "*" ? (
          <>
            <div className="row"></div>
            <Text>Value in token units (.1 is .1 ETH or 100,000,000 gwei)</Text>
            <TextInput
              placeholder="Value (100, 10, 5, etc)"
              defaultValue={
                permission.inputs[2]?.value?.[1]
                  ? ethers.formatEther(permission.inputs[2]?.value?.[1]).toString()
                  : permission.inputs[2]?.value?.[1]
              }
              onChange={() => {}}
              onBlur={(e) =>
                updatePermission({
                  ...permission,
                  inputs: permission.inputs.map((input) => {
                    if (input.type === "values") {
                      return {
                        ...input,
                        value: [
                          permission.inputs[2]?.value[0],
                          e.target.value ? ethers.parseUnits(e.target.value, "ether").toString() : "",
                        ],
                      }
                    }
                    return input
                  }),
                })
              }
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

function ContractFunctionPermission({
  base,
  permission,
  updatePermission,
}: {
  base?: PermissionOption
  permission: PermissionOption
  updatePermission: (p: PermissionOption) => void
}) {
  const addressOptions = [
    ...(permission.inputs[0]?.options?.map((v) => ({ value: v, label: v })) || []),
  ].concat({ value: "*", label: "Any" })

  const functionOptions = [
    ...(permission.inputs[1]?.options?.map((v) => ({
      value: v,
      label: v,
    })) || []),
    { value: "*", label: "Any" },
  ]

  const addressValues = permission.inputs.length
    ? permission.inputs
        .find((v) => v.type === "addresses")
        ?.value?.map((v) => ({
          value: v,
          label: addressOptions.find((a) => a.value === v)?.label || v,
        }))
    : base?.inputs[0]?.value?.map((v) => ({ value: v, label: v }))

  const functionValues = permission.inputs.length
    ? permission.inputs
        .find((v) => v.type === "methods")
        ?.value?.map((v) => ({
          value: v,
          label: functionOptions.find((a) => a.value === v)?.label || v,
        }))
    : base?.inputs[1]?.value?.map((v) => ({ value: v, label: v }))

  return (
    <div className="permission">
      <div className="row">
        <div className="row">
          <Text subtitle>Allowed Contract Addresses</Text>
        </div>

        <CreatableSelect
          className="react-select"
          placeholder={base?.inputs[0]?.placeholder}
          defaultValue={addressValues}
          options={addressOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "addresses") {
                  return { ...input, value: e.map((v) => v.value) }
                }
                return input
              }),
            })
          }}
        />
      </div>

      <div className="row">
        <div className="row">
          <Text subtitle>Allowed Function Names</Text>
        </div>

        <CreatableSelect
          className="react-select"
          placeholder={base?.inputs[1]?.placeholder}
          defaultValue={functionValues}
          options={functionOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "methods") {
                  return { ...input, value: e.map((v) => v.value) }
                }
                return input
              }),
            })
          }}
        />
      </div>
    </div>
  )
}

function ContractDeployPermission({
  base,
  permission,
  updatePermission,
}: {
  base?: PermissionOption
  permission: PermissionOption
  updatePermission: (p: PermissionOption) => void
}) {
  return (
    <div className="permission">
      <div className="row">
        <div className="row">
          <Text subtitle>Allow Contract Deploys</Text>
        </div>
      </div>
    </div>
  )
}

function ApprovalPermission({
  admins,
  base,
  permission,
  updatePermission,
}: {
  admins: IUser[]
  base?: PermissionOption
  permission: PermissionOption
  updatePermission: (p: PermissionOption) => void
}) {
  const userOptions = [
    ...(admins.map((v) => ({ value: v.id, label: `${v.name} (${v.email})` })) || []),
  ].concat({ value: "workspace-admins", label: "Any Workspace Owner" })

  const userValues = (
    permission.inputs.length ? permission.inputs[0]?.value || [] : base?.inputs[0]?.value || []
  ).map((v) => ({
    value: v,
    label: userOptions.find((a) => a.value === v)?.label || v,
  }))

  return (
    <div className="permission">
      <div className="row">
        <div className="row">
          <Text subtitle>Require Approval before executing</Text>
        </div>

        <CreatableSelect
          key={userOptions.length}
          className="react-select"
          placeholder={base?.inputs[0]?.placeholder}
          defaultValue={userValues}
          options={userOptions}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          isMulti
          onChange={(e) => {
            if (!e) return
            updatePermission({
              ...permission,
              inputs: permission.inputs.map((input) => {
                if (input.type === "approvers") {
                  return { ...input, value: e.map((v) => v.value) }
                }
                return input
              }),
            })
          }}
        />
      </div>
    </div>
  )
}

export function RoleEditorV2(props: RoleEditorProps) {
  const params = useParams<{ workspaceId: string; roleId?: string }>()
  const navigate = useNavigate()
  const [roleName, setRoleName] = useState<string>(props.edit?.name || "")

  const [permissions, setPermissions] = useState<PermissionOption[]>(
    props.edit
      ? props.edit.permissions.map((v) => ({
          id: v.id,
          type: v.type as PermissionOption["type"],
          value: v.value,
          valid: true,
          inputs: Object.keys(v).reduce((acc, k: string) => {
            if (!inputKeys.includes(k)) return acc
            if (!v[k]) return acc
            const b = roleTypeOptions.find((r) => r.type === v.type)
            const item: InputTextArray = {
              type: k as InputTextArray["type"],
              label: b?.inputs.find((i) => i.type === k)?.label || "",
              placeholder: b?.inputs.find((i) => i.type === k)?.placeholder || "",
              options: b?.inputs.find((i) => i.type === k)?.options || [],
              value: v[k] as string[],
            }
            acc.push(item)
            return acc
          }, []),
        }))
      : [methodPermission(), defaultPermission()]
  )

  const [activePermission, setActivePermission] = useState<PermissionOption>(defaultPermission())

  const updatePermission = (perm: PermissionOption) => {
    const newPermissions = permissions.map((p) => {
      if (p.id === perm.id) {
        return { ...p, ...perm, valid: checkValid({ ...p, ...perm }) }
      }
      return p
    })
    setPermissions(newPermissions)
  }

  const deletePermission = (perm: PermissionOption) => {
    setPermissions(permissions.filter((p) => p.id !== perm.id))
  }

  const addPermission = () => {
    setPermissions([...permissions, defaultPermission()])
  }

  const {
    mutate: createRole,
    error: createRoleError,
    isLoading,
  } = useMutation<
    { id: string; permissions: Permission[]; name: string },
    AxiosError,
    { name: string; permissions: Permission[] }
  >({
    mutationFn: (attrs: any) => fetcher("post", `workspaces/${params.workspaceId}/roles/`, attrs),
    onSuccess: (data) => {
      refetch()
      navigate(`/dashboard/workspaces/${params.workspaceId}/permissions/${data.id}`)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const { mutate: updateRole, isLoading: isLoadingUpdate } = useMutation<
    { id: string; permissions: Permission[]; name: string },
    AxiosError,
    { name: string; permissions: Permission[] }
  >({
    mutationFn: (attrs: { name: string; permissions: Permission[] }) =>
      fetcher("patch", `workspaces/${params.workspaceId}/roles/${params.roleId}`, attrs),
    onSuccess: (data) => {
      refetch()
      navigate(`/dashboard/workspaces/${params.workspaceId}/permissions/${data.id}`)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const save = () => {
    const perms = permissions.map((p: PermissionOption) => {
      const perm = p.inputs.reduce(
        (acc, v) => {
          acc[v.type] = v.value
          return acc
        },
        { type: p.type }
      )

      return perm
    })

    if (props.edit) {
      return updateRole({ permissions: perms, name: roleName })
    }
    createRole({ permissions: perms, name: roleName })
  }

  const {
    data: admins = [],
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: async (): Promise<IUser[]> =>
      fetcher("get", `/workspaces/${params.workspaceId}/users/?role=owner,admin`),
    queryKey: [params.workspaceId, "users", "owner,admin"],
  })

  const { data: tokenData = [], refetch } = useTokens()
  const tokens = tokenData.map((t) => {
    const chain =
      Object.keys(config.chains).find((c: string) => config.chains[c].chainId === t.chain_id) || ""
    return {
      label: `${t.symbol} (${t.name}) - ${config.chains[chain]?.name}`,
      value: `${t.chain_id}:${t.address}`,
    }
  })

  const {
    data: wallets = [],
    isLoading: isLoadingWallets,
    refetch: refetchWallets,
  } = useQuery<IWallet[], AxiosError>({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
  })

  const addresses = wallets.map((w: IWallet) => ({
    label: `${w.name} (${w.accounts[0].address})`,
    value: w.accounts[0].address,
  }))

  const isValid =
    roleName?.length > 2 &&
    permissions.every((p) => p.valid) &&
    permissions.filter((v) => v.type !== "method").length > 0

  return (
    <div className="multi-editor">
      <div className="row">
        <Text subtitle>Role Name</Text>
      </div>
      <div className="row">
        <TextInput
          placeholder="Role Name (nft-role, deployment-role)"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          disabled={props.edit?.official}
        />
      </div>

      <Divider>Rules</Divider>

      <div className="items">
        {permissions.map((p) => {
          const onRoleTypeChange = (e: SingleValue<PermissionOption>) => {
            const base = roleTypeOptions.find((r) => r.type === e?.type)
            if (!e) return
            updatePermission({ ...p, ...base, type: e.type })
          }
          const roleType = roleTypeOptions.find((r) => r.type === p.type)

          return (
            <div
              key={p.id}
              className="item"
              style={{
                zIndex: activePermission.id === p.id ? 1 : 0,
              }}
            >
              <div className="title">
                <Text subtitle>Rule</Text>
                {p.required ? (
                  <Tag>
                    <Text uppercase>Required</Text>
                  </Tag>
                ) : (
                  <Button
                    plain
                    className="circle-button small"
                    variant="error"
                    ghost
                    size="small"
                    onClick={() => deletePermission(p)}
                    disabled={props.edit?.official}
                  >
                    <AiOutlineDelete size={20} />
                  </Button>
                )}
              </div>

              {p.type === "method" ? (
                <MethodPermission permission={p} updatePermission={updatePermission} />
              ) : (
                <div className="row">
                  <Select
                    className="react-select"
                    placeholder="Rule Type"
                    defaultValue={roleType}
                    options={roleTypeOptions.map((r) => ({ ...r, value: r.type }))}
                    styles={customSelectStyles}
                    menuPortalTarget={document.body}
                    onMenuOpen={() => setActivePermission(p)}
                    onChange={onRoleTypeChange}
                    isSearchable={false}
                    isDisabled={props.edit?.official}
                  />
                </div>
              )}
              {p.type === "transfer" && (
                <TransferPermission
                  addresses={addresses}
                  tokens={tokens}
                  base={roleType}
                  permission={p}
                  updatePermission={updatePermission}
                />
              )}

              {p.type === "contract:function" && (
                <ContractFunctionPermission
                  base={roleType}
                  permission={p}
                  updatePermission={updatePermission}
                />
              )}

              {p.type === "contract:deploy" && (
                <ContractDeployPermission
                  base={roleType}
                  permission={p}
                  updatePermission={updatePermission}
                />
              )}

              {p.type === "approval" && (
                <ApprovalPermission
                  base={roleType}
                  permission={p}
                  admins={admins}
                  updatePermission={updatePermission}
                />
              )}
            </div>
          )
        })}
        <div>
          <Button
            title="Add additional Rule"
            className="circle-button"
            round
            variant="success"
            onClick={addPermission}
            disabled={props.edit?.official}
          >
            <IoAddOutline size={20} />
          </Button>
        </div>
      </div>

      <div className="editor-footer">
        <div>{props.edit?.official ? "Default roles can't be edited" : null}</div>
        {/* <Text variant="error">{createRoleError ? String(parseAxiosError(createRoleError)) : null}</Text> */}
        <Button
          variant="success"
          disabled={!isValid || isLoading || props.edit?.official}
          round
          onClick={() => save()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
