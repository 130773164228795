import { QueryClient } from "@tanstack/react-query"
import { SessionTypes } from "@walletconnect/types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { IReviewableTransaction, IUser, IWallet } from "../types"
import config from "./config"

export interface Store {
  authUser?: IUser
  chain: string
  walletConnectPeers: SessionTypes.Struct[]
  walletConnectModalActive: boolean
  apiKeyModalActive: boolean
  walletModalActive: boolean
  inviteModalActive: boolean
  assignRoleModalActive: boolean
  assignWalletModalActive: boolean
  transactModalWallet: IWallet | null
  reviewTransaction: IReviewableTransaction | null
}
export interface Actions {
  reset: () => void
}
const initialState: Store = {
  authUser: undefined,
  chain: config.defaultChain,
  walletConnectPeers: [],
  walletConnectModalActive: false,
  apiKeyModalActive: false,
  walletModalActive: false,
  inviteModalActive: false,
  assignRoleModalActive: false,
  assignWalletModalActive: false,
  transactModalWallet: null,
  reviewTransaction: null,
}
export const useStore = create<Store & Actions>()(
  devtools((set) => ({
    ...initialState,
    reset: () => set(initialState),
  }))
)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
