import { AdminReviewTransactionModal } from "./AdminReviewTransactionModal"
import "./Dashboard.sass"
import BaseModal from "react-modal"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import { SideBar } from "./components/SideBar"
import { Topbar } from "./components/Topbar"
import { CreateWalletModal } from "./CreateWalletModal"
import { Developer } from "./Developer"
import { Store, useStore } from "./lib/store"
import { setTitle } from "./lib/utils"
import { PendingTransactions } from "./PendingTransactions"
import { Permissions } from "./Permissions"
import { ReviewTransactionModal } from "./ReviewTransactionModal"
import { Team } from "./Team"
import { TransactModal } from "./TransactModal"
import { Wallet } from "./Wallet"
import { WalletConnectModal } from "./WalletConnectModal"
import { Wallets } from "./Wallets"
import { Alerts } from "./Alerts"
import { InviteTeamModal } from "./InviteTeamModal"
import { useAuth } from "./hooks/useAuth"
import { Billing } from "./Billing"
import { Profile } from "./Profile"
import { WorkspaceTokens } from "./WorkspaceTokens"
import { WorkspaceSettings } from "./WorkspaceSettings"

BaseModal.setAppElement("#root")

export interface DashboardProps {}

export function Dashboard(_props: DashboardProps) {
  setTitle("Dashboard")
  const params = useParams<{ workspaceId: string }>()
  const auth = useAuth()
  const location = useLocation()

  const walletConnectModalActive = useStore((s: Store) => s.walletConnectModalActive)

  return (
    <div className="wrapper">
      <SideBar />

      <div className="right-content">
        <Topbar />

        {false && (
          <div className="alpha-warning">
            Warning: SYBL is in alpha. All data will be wiped periodically. <i>ONLY</i> use Goerli TESTNET
            transactions
          </div>
        )}
        <Routes>
          <Route
            index
            element={
              <div className="page dashboard">
                <Wallets />
              </div>
            }
          />

          <Route path="/team" element={<Team />} />
          <Route path="/developer" element={<Developer />} />
          <Route path="/permissions/*" element={<Permissions />} />
          <Route path="/alerts/*" element={<Alerts />} />
          <Route path="/wallets/:id" element={<Wallet />} />
          <Route path="/billing/*" element={<Billing />} />
          <Route path="/tokens" element={<WorkspaceTokens />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<WorkspaceSettings />} />
        </Routes>

        {auth.isOwnerOrAdmin && <PendingTransactions key={`${params.workspaceId}-pending`} />}
      </div>

      <WalletConnectModal
        key={`${params.workspaceId}-wc-modal-2`}
        onRequestClose={() => useStore.setState({ walletConnectModalActive: false })}
        isOpen={walletConnectModalActive}
      />
      <ReviewTransactionModal key={`${params.workspaceId}-rm-modal-${location.pathname}`} />
      {auth.isOwnerOrAdmin && (
        <AdminReviewTransactionModal key={`${params.workspaceId}-admin-rm-modal-${location.pathname}`} />
      )}
      <TransactModal key={`${params.workspaceId}-tx-modal-${location.pathname}`} />
      <CreateWalletModal key={`${params.workspaceId}-create-wallet-modal-${location.pathname}`} />
      <InviteTeamModal key={`${params.workspaceId}-invite-team-modal-${location.pathname}`} />
    </div>
  )
}
