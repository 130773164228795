import { AssignRoleModal } from "./AssignRoleModal"
import "./Role.sass"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { AiOutlineEdit, AiOutlineInfoCircle } from "react-icons/ai"
import { BsDot, BsShieldFillCheck } from "react-icons/bs"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button } from "./components/Button"
import { Divider } from "./components/Divider"
import { Empty } from "./components/Empty"
import { Spinner } from "./components/Spinner"
import { Text } from "./components/Text"
import { fetcher } from "./lib/fetcher"
import { useStore } from "./lib/store"
import {
  IToken,
  IUser,
  IWallet,
  IWalletRole,
  IWalletRoleApiKey,
  IWalletRolePerm,
  IWalletRoleUser,
} from "./types"
import { useAuth } from "./hooks/useAuth"
import { Header } from "./components/Header"
import { ethers, keccak256 } from "ethers"
import { Addr } from "./components/Addr"
import { useTokens } from "./hooks/useTokens"
import config from "./lib/config"
import { VALUE_OPTIONS } from "./components/RoleEditorV2"
import { Tag } from "./components/Tag"

function MethodPermission({ permission }: { permission: IWalletRolePerm }) {
  return (
    <div className="row permission">
      <div className="row">
        <Text medium>Methods</Text>
      </div>
      <div className="values">
        {permission.methods?.map((v) => (
          <div className="value" key={v}>
            <BsDot size={36} color="#50e3c2" />
            <Text medium>
              {v === "*" ? (
                <Tag>
                  <Text subtitle>ANY</Text>
                </Tag>
              ) : (
                v
              )}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}

function TransferPermission({
  permission,
  tokens,
  wallets,
}: {
  permission: IWalletRolePerm
  tokens: IToken[]
  wallets: IWallet[]
}) {
  const renderWalletValue = (v: string) => {
    const wallet = wallets.find((w) => w.accounts[0].address === v)
    if (wallet) {
      return (
        <div className="value">
          <Link to={`/dashboard/workspaces/${wallet.workspace_id}/wallets/${wallet.id}`}>
            <Text medium>{wallet.name}</Text>
            <Tag className="amount">
              <Addr>{v}</Addr>
            </Tag>
          </Link>
        </div>
      )
    }
    return <Addr fullDetails>{v}</Addr>
  }

  const renderTokenValue = (v: string) => {
    const parts = v.split(":")

    // chain token
    if (parts[1] === "") {
      const chain = Object.values(config.chains).find((c) => String(c.chainId) === parts[0])
      return (
        <Text medium>
          {chain?.symbol || ""} - {chain?.name || ""}
        </Text>
      )
    }
    const token = tokens.find((t) => t.address.toLowerCase() === parts[1].toLowerCase())
    if (token) {
      return (
        <Text medium>
          {token.symbol} - {token.name}
        </Text>
      )
    }
    return <Addr fullDetails>{parts[1]}</Addr>
  }

  return (
    <div className="row permission">
      <div className="row">
        <Text medium>Transfer</Text>
      </div>

      <div className="row">
        <Text subtitle>Allowed Addresses:</Text>
      </div>
      <div className="values">
        {permission.addresses?.map((v) => (
          <div key={v} className="value">
            <BsDot size={36} color="#50e3c2" />
            {v === "*" ? (
              <Tag>
                <Text subtitle>ANY</Text>
              </Tag>
            ) : (
              renderWalletValue(v)
            )}
          </div>
        ))}
      </div>

      <div className="row" />
      <div className="row">
        <Text subtitle>Allowed Tokens:</Text>
      </div>
      <div className="values">
        {permission.tokens?.map((v) => (
          <div key={v} className="value">
            <BsDot size={36} color="#50e3c2" />
            <div className="address">
              {v === "*" ? (
                <Tag>
                  <Text subtitle>ANY</Text>
                </Tag>
              ) : (
                renderTokenValue(v)
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="row" />
      <div className="row">
        <Text subtitle>Allowed Values:</Text>
      </div>
      <div className="values">
        <div className="value">
          <BsDot size={36} color="#50e3c2" />
          {permission.values?.[0] === "*" ? (
            <Tag>
              <Text subtitle>ANY</Text>
            </Tag>
          ) : (
            <>
              <Text medium>{VALUE_OPTIONS.find((a) => a.value === permission.values?.[0])?.label}</Text>
              <Text medium className="amount">
                <Tag>{ethers.formatEther(permission.values?.[1])}</Tag>
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

function ContractFunctionPermission({ permission }: { permission: IWalletRolePerm }) {
  return (
    <div className="row permission">
      <div className="row">
        <Text medium>Contract Function</Text>
      </div>

      <div className="row">
        <Text subtitle>Allowed Contract Addresses:</Text>
      </div>
      <div className="values">
        {permission.addresses?.map((v) => (
          <div key={v} className="value">
            <BsDot size={36} color="#50e3c2" />
            <div className="address">
              {v === "*" ? (
                <Tag>
                  <Text subtitle>ANY</Text>
                </Tag>
              ) : (
                <Addr fullDetails>{v}</Addr>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="row" />
      <div className="row">
        <Text subtitle>Allowed Function Methods:</Text>
      </div>
      <div className="values">
        {permission.methods?.map((v) => (
          <div key={v} className="value">
            <BsDot size={36} color="#50e3c2" />
            <div className="address">
              {v === "*" ? (
                <Tag>
                  <Text subtitle>ANY</Text>
                </Tag>
              ) : (
                <Addr fullDetails>{v}</Addr>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
function ContractDeployPermission({ permission }: { permission: IWalletRolePerm }) {
  return (
    <div className="row permission">
      <div className="row">
        <Text medium>Contract Deploys</Text>
      </div>
      <div className="row">
        <div className="values">
          <div className="value">
            <BsDot size={36} color="#50e3c2" />
            <Text medium>Deploys Allowed</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

function ApprovalPermission({ permission, users = [] }: { permission: IWalletRolePerm; users?: IUser[] }) {
  return (
    <div className="row permission">
      <div className="row">
        <Text medium>Approval</Text>
      </div>

      <div className="row">
        <Text subtitle>Approvers:</Text>
      </div>
      <div className="values">
        {permission.approvers?.map((v) => (
          <div key={v} className="value">
            <BsDot size={36} color="#50e3c2" />
            <div className="address">
              {v === "workspace-admins" ? (
                <Text subtitle>Any Workspace Owner</Text>
              ) : (
                <Text medium>{users.find((u) => u.id === v)?.name}</Text>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export function Role() {
  const params = useParams<{ workspaceId: string; roleId: string }>()
  const auth = useAuth()
  const navigate = useNavigate()

  const setRoleModal = (v: boolean) => useStore.setState({ assignRoleModalActive: v })

  const { data, isLoading, refetch } = useQuery({
    queryFn: async (): Promise<IWalletRole> =>
      fetcher("get", `/workspaces/${params.workspaceId}/roles/${params.roleId}`),
    queryKey: [params.workspaceId, "roles", params.roleId],
  })

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: async (): Promise<IUser[]> => fetcher("get", `/workspaces/${params.workspaceId}/users/`),
    queryKey: [params.workspaceId, "users"],
  })

  const { refetch: refetchRoles } = useQuery({
    queryFn: async (): Promise<IWalletRole[]> => fetcher("get", `/workspaces/${params.workspaceId}/roles/`),
    queryKey: [params.workspaceId, "roles"],
    enabled: false,
  })

  const { mutate: removeAssignment } = useMutation<any, AxiosError, any>({
    mutationFn: (attrs: Partial<IWalletRoleApiKey | IWalletRoleUser>) =>
      fetcher("delete", `/workspaces/${params.workspaceId}/roles/${params.roleId}/assignment/${attrs.id}`),
    onSuccess: () => refetchAll(),
  })

  const { mutate: deleteMutation } = useMutation<any, AxiosError>({
    mutationFn: () => fetcher("delete", `/workspaces/${params.workspaceId}/roles/${params.roleId}`),
    onSuccess: () => {
      refetchRoles()
      navigate("..")
    },
  })

  const { data: wallets = [] } = useQuery<IWallet[], AxiosError>({
    queryFn: async (): Promise<IWallet[]> => fetcher("get", `/workspaces/${params.workspaceId}/wallets/`),
    queryKey: [params.workspaceId, "wallets"],
  })

  const { data: tokens = [] } = useTokens()

  const removeUserAssignment = (v: IWalletRoleUser) => {
    if (window.confirm("Are you sure you want to remove this user from this role?")) {
      removeAssignment({ id: v.id })
    }
  }

  const removeApiKeyAssignment = (v: IWalletRoleApiKey) => {
    if (window.confirm("Are you sure you want to remove this API Key from this role?")) {
      removeAssignment({ id: v.id })
    }
  }

  const promptDelete = () => {
    if (window.confirm("Are you sure you want to Delete this role? This can not be undone")) {
      deleteMutation()
    }
  }

  const refetchAll = () => {
    refetch()
    refetchUsers()
  }

  if (isLoading || isLoadingUsers) return <Spinner />
  if (!data) return <Empty>Not Found</Empty>

  // const renderValue = (v: IWalletRolePerm) => {
  //   if (v.value === "*") return "ANY"
  //   if (v.type === "approval") {
  //     const u = users?.find((u) => u.id === v.value)
  //     if (!u) return v.value
  //     return `${u.name} (${u.email}) [${u.role?.role}]`
  //   }
  //   if (v.label === "functionName") {
  //     return `${v.value} (encoded as: ${ethers.keccak256(ethers.toUtf8Bytes(v.value)).slice(0, 10)})`
  //   }
  //   return v.value
  // }

  const requiresApproval = data.permissions.find((v) => v.type === "approval")

  return (
    <div className="pane role">
      <Header>
        <div />
        {!data.official && auth.isOwnerOrAdmin ? (
          <Button
            uppercase
            round
            variant="success"
            onClick={() =>
              navigate(`/dashboard/workspaces/${params.workspaceId}/permissions/edit/${data.id}`)
            }
          >
            Modify Role <AiOutlineEdit size={20} />
          </Button>
        ) : null}
      </Header>
      <div className="role-view">
        <div className="row spaced">
          <Text medium size="large">
            {data.name || "(Role Name)"}
          </Text>
          {data.official ? (
            <Button plain style={{ cursor: "default" }}>
              <BsShieldFillCheck size={20} /> Default
            </Button>
          ) : null}
        </div>

        <Divider>Rules</Divider>

        <div className="permissions">
          {data.permissions?.map((v: IWalletRolePerm) => {
            if (v.type === "method") return <MethodPermission key={v.id} permission={v} />

            if (v.type === "transfer") {
              return <TransferPermission key={v.id} permission={v} tokens={tokens} wallets={wallets} />
            }
            if (v.type === "contract:function") {
              return <ContractFunctionPermission key={v.id} permission={v} />
            }
            if (v.type === "contract:deploy") {
              return <ContractDeployPermission key={v.id} permission={v} />
            }
            if (v.type === "approval") {
              return <ApprovalPermission key={v.id} permission={v} users={users} />
            }

            return null
          })}
        </div>

        <div>
          <div className="row">
            Dashboard confirmation is {requiresApproval ? "" : <Text medium>NOT</Text>} required for
            transactions submitted by users or API keys assigned to this role.
          </div>
          {requiresApproval ? (
            <div className="row">This role can't be assigned to API Keys as it requires manual approval.</div>
          ) : null}
        </div>
      </div>

      {auth.isOwnerOrAdmin && (
        <div className="role-view">
          <div className="assign-empty">
            <Button round uppercase variant="success" onClick={() => setRoleModal(true)}>
              Assign Role
            </Button>
          </div>
        </div>
      )}

      {!data.users?.length && !data.api_keys?.length && (
        <div className="role-view">
          <div className="info">
            <AiOutlineInfoCircle />{" "}
            <Text>This role is currently not connected to any wallet, user, or API key.</Text>
          </div>
        </div>
      )}

      {data.users?.length > 0 && (
        <div className="role-view">
          <div className="row">
            <Text subtitle>Assigned Users:</Text>
          </div>
          {data.users?.map((v: IWalletRoleUser) => {
            return (
              <div key={v.id} className="assignee">
                <div className="subject">
                  <div className="details">
                    User:{" "}
                    <Text medium>
                      {v.user.name} ({v.user.email})
                    </Text>
                  </div>
                  <div>
                    Wallet:{" "}
                    <Link to={`/dashboard/workspaces/${params.workspaceId}/wallets/${v.wallet.id}`}>
                      <Text medium>{v.wallet.name}</Text>
                    </Link>
                  </div>
                </div>
                {auth.isOwnerOrAdmin && (
                  <Button variant="error" ghost size="small" onClick={() => removeUserAssignment(v)}>
                    Revoke
                  </Button>
                )}
              </div>
            )
          })}
        </div>
      )}

      {data.api_keys?.length > 0 && (
        <div className="role-view">
          <div className="row">
            <Text subtitle>Assigned API Keys:</Text>
          </div>
          {data.api_keys?.map((v: IWalletRoleApiKey) => {
            return (
              <div key={v.id} className="assignee">
                <div className="subject">
                  <div className="details">
                    Key Name:{" "}
                    <Text medium>
                      {v.api_key.name}{" "}
                      <Text monospace subtitle>
                        ({v.api_key.prefix.slice(0, 8)}...)
                      </Text>
                    </Text>
                  </div>
                  <div>
                    Wallet:{" "}
                    <Link to={`/dashboard/workspaces/${params.workspaceId}/wallets/${v.wallet.id}`}>
                      <Text medium>{v.wallet.name}</Text>
                    </Link>
                  </div>
                </div>
                {auth.isOwnerOrAdmin && (
                  <Button variant="error" ghost size="small" onClick={() => removeApiKeyAssignment(v)}>
                    Remove
                  </Button>
                )}
              </div>
            )
          })}
        </div>
      )}

      {auth.isOwnerOrAdmin && (
        <div className="role-view">
          {data.api_keys.length || data.users.length ? (
            <div className="row">
              <div className="info">
                <AiOutlineInfoCircle />{" "}
                <Text>This role must be unassigned from all users and API keys before deleting.</Text>
              </div>
            </div>
          ) : null}

          <div className="assign-empty">
            <Button
              round
              uppercase
              disabled={data.api_keys.length || data.users.length ? true : false}
              variant="error"
              onClick={() => promptDelete()}
            >
              Delete Role
            </Button>
          </div>
        </div>
      )}

      <AssignRoleModal key={`${params.workspaceId}-assign`} roleId={params.roleId} />
    </div>
  )
}
